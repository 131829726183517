import React from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'

import { Link } from 'react-router-dom'
import { useAppContext } from '../../contexts/app'

import CIcon from '@coreui/icons-react'
import { useGetAccountQuery } from '../../api'

const HeaderNavUserDropdown = () => {
  const { logout, userSession } = useAppContext()
  const accountQuery = useGetAccountQuery({
    fetchPolicy: 'no-cache',
  })
  const account = accountQuery.data?.getAccount
  const user = userSession?.user
  const userName = user?.full_name
  const email = user?.email

  return (
    <CDropdown
      inNav
      className="c-header-nav-items ml-2"
    >
      <CDropdownToggle className="c-header-nav-link pr-0 pr-sm-2" caret={false}>
        <div className="c-avatar justify-content-end w-auto">
          <CIcon name="cil-user"/>
        </div>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end">
        {/*<CDropdownItem*/}
        {/*  header*/}
        {/*  tag="div"*/}
        {/*  color="light"*/}
        {/*  className="text-center"*/}
        {/*>*/}
        {/*  <strong>Account</strong>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-bell" className="mfe-2" />*/}
        {/*  Updates*/}
        {/*  <CBadge color="info" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-envelope-open" className="mfe-2" />*/}
        {/*  Messages*/}
        {/*  <CBadge color="success" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-task" className="mfe-2" />*/}
        {/*  Tasks*/}
        {/*  <CBadge color="danger" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-comment-square" className="mfe-2" />*/}
        {/*  Comments*/}
        {/*  <CBadge color="warning" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem*/}
        {/*  header*/}
        {/*  tag="div"*/}
        {/*  color="light"*/}
        {/*  className="text-center"*/}
        {/*>*/}
        {/*  <strong>Settings</strong>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-user" className="mfe-2" />Profile*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-settings" className="mfe-2" />*/}
        {/*  Settings*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-credit-card" className="mfe-2" />*/}
        {/*  Payments*/}
        {/*  <CBadge color="secondary" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-file" className="mfe-2" />*/}
        {/*  Projects*/}
        {/*  <CBadge color="primary" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem divider />*/}
        <CDropdownItem header tag="div">
          <div className="flex items-center">
            <CIcon name="cil-user" className="mfe-2" />
            <div className="inline-block">
              {/* TODO */}
              <div className="text-xs">{ account?.companyNameJp }</div>
              <div className="text-sm"><CDropdownToggle className="p-0" caret={false} tag="div"><Link to={"/profile"}>{ userName }</Link></CDropdownToggle></div>
              <div className="text-xs">{ email }</div>
            </div>
          </div>
        </CDropdownItem>
        <CDropdownItem onClick={() => logout()}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          ログアウト
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default HeaderNavUserDropdown
