import React, { useState } from "react";

import { CForm, CFormGroup, CLabel } from "@coreui/react";
import { useToasts } from "react-toast-notifications";
import LoadingButton from "../../common/LoadingButton";
import AppModal from "../../common/modals/AppModal";
import { useForm } from "react-hook-form";
import { UpdateUserPasswordDto } from "../../../models/user";
import { UpdateUserPasswordSchema } from "../../../formSchemas/manage/user";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../contexts/app";

type Props = {
  updatePasswordModal: any;
};

const UserPasswordChangeModal = ({ updatePasswordModal }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { updateUserPassword } = useAppContext();

  // Eメール更新フォーム
  const updatePasswordForm = useForm<UpdateUserPasswordDto>({
    validationSchema: UpdateUserPasswordSchema,
  });
  const [isLoadingPasswordChange, setIsLoadingPasswordChange] = useState(false);

  return (
    <>
      <AppModal
        title="パスワードの変更"
        {...updatePasswordModal}
        footer={false}
      >
        <CForm
          onSubmit={updatePasswordForm.handleSubmit(
            (values: { password: string; oldPassword: string }) => {
              const { password, oldPassword } = values;
              setIsLoadingPasswordChange(true);
              updateUserPassword(password, oldPassword)
                .then(() => {
                  addToast("パスワードを更新しました", {
                    appearance: "success",
                  });
                  updatePasswordModal.onClose();
                })
                .catch((err) => {
                  let translatedMessage = t(`aws.cognito.error.${err.code}`, {
                    defaultValue:
                      err.message || "パスワードの更新ができませんでした",
                  });
                  if (err.code === "NotAuthorizedException") {
                    translatedMessage = "変更前のパスワードに誤りがあります。";
                  }
                  addToast(translatedMessage, { appearance: "error" });
                })
                .finally(() => {
                  setIsLoadingPasswordChange(false);
                });
            }
          )}
        >
          <CFormGroup>
            <CFormGroup>
              <CLabel>変更前のパスワード</CLabel>
              <input
                ref={updatePasswordForm.register}
                name="oldPassword"
                type="password"
                className="form-control"
              />
            </CFormGroup>
            <CLabel>変更後のパスワード</CLabel>
            <input
              ref={updatePasswordForm.register}
              name="password"
              type="password"
              className="form-control"
            />
          </CFormGroup>
          <CFormGroup className="mt-4">
            <LoadingButton
              type="submit"
              color="primary"
              loading={isLoadingPasswordChange}
            >
              パスワードを変更する
            </LoadingButton>
          </CFormGroup>
        </CForm>
      </AppModal>
    </>
  );
};

export default UserPasswordChangeModal;
