
import React, { useState } from 'react'
import _ from 'lodash'
import { CButton, CButtonClose } from '@coreui/react';
import CIcon from '@coreui/icons-react';

interface Props {
  max?: number
  type: 'text'
  // TODO 型指定
  tags?: any
  onChange: any
}

type TagArrayItem = Array<{key: string, value: string}>


// DynamoDBにMap型で保存している都合、原則コード上での取り扱いはMap型
// UIのみ配列型に変換

const listToMap = (tags: any[]) => {
  const map: any = {};
  tags.forEach(tag => {
    map[tag.key] = tag.value;
  })
  return map;
}

const mapToList = (tags: any) => {
  const values: any[] = [];
  Object.keys(tags).forEach(key => {
    if (tags && tags[key]) {
      values.push({ key, value: tags[key] })
    }
  })
  return values;
}
  

const MultipleTagsInput = (props: Props) => {
  // TODO 型指定
  const defaultValues: any[] = []
  if (props.tags) {
    Object.keys(props.tags).forEach(key => {
      if (props.tags && props.tags[key]) {
        defaultValues.push({ key, value: props.tags[key] })
      }
    })
  }
  
  const [values, setValues] = useState<TagArrayItem>(defaultValues);

  // 最大数でbuttonをdisabledにする
  const isActiveButton = props.max === undefined || values.length < props.max;
  
  return (
    <>
      {values.map((value, inputIndex) => {
        return (
          <div className="flex mb-2" key={inputIndex}>
              <input required placeholder="KEY" type={props.type} className="form-control mr-2" value={value.key} onChange={evt => {
                  const newValue = evt.target.value;
                  const newValues = [...values];
                  newValues[inputIndex].key = newValue;
                  setValues(newValues)
                  props.onChange(listToMap(newValues))
              }}/>
              <input required placeholder="VALUE" type={props.type} className="form-control" value={value.value} onChange={evt => {
                  const newValue = evt.target.value;
                  const newValues = [...values];
                  newValues[inputIndex].value = newValue;
                  setValues(newValues)
                  props.onChange(listToMap(newValues))
              }}/>
            <CButtonClose className="ml-2" type="button" onClick={() => {
              const newValues = values.filter((v, i) => i !== inputIndex)
              setValues(newValues);
              props.onChange(listToMap(newValues))
            }} />
          </div>
        );
      })}
       <div className="mt-1">
        <CButton 
          type="button"
          color="secondary"
          disabled={isActiveButton === false}
          onClick={() => {
            setValues(values.concat({key: '', value: ''}))
            props.onChange(values)
          }}
        >
          <CIcon size={'sm'} name={'cil-plus'} />追加する
        </CButton>
       </div>
    </>
  );
}

export default MultipleTagsInput