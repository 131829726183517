import { useTranslation } from "react-i18next";
import {
  getApi,
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  getSystemDiagramUrls,
  postSystemDiagram,
  deleteSystemDiagram,
  SystemDiagramsRefData,
} from "../repos/api";

import {
  UserDto,
  UserListItemDto,
  CreateUserDto,
  UpdateUserDto,
} from "../models/user";

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

import awsConfiguration from "../awsConfiguration";

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
});

export type UserSession = {
  token: string;
  user: UserDto;
};

export default class AppService {
  async authenticate(email: string, password: string) {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise<UserSession>((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const idToken = result.getIdToken().getJwtToken();
          // const expires = result.getIdToken().getExpiration()
          cognitoUser.getUserData(
            async (err2, userData) => {
              if (err2) {
                reject(err2);
                return;
              }
              const user = await this.getUser(idToken, userData?.Username!);
              resolve({
                token: idToken,
                user: {
                  ...user,
                  id: userData?.Username!,
                  email:
                    userData?.UserAttributes.find((x) => x.Name === "email")!
                      .Value || "",
                },
              });
              // MARK: backendから直接データを取得する
            },
            { bypassCache: true }
          );
        },
        onFailure: (err) => {
          console.error(err);
          reject(err);
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // TODO: 修正
          // APIで作成したユーザの初回仮パスワードでのログイン後に本パスワードを登録させる必要がある
          // 以下の処理では仮パスワードを本パスワードとして設定している
          // console.log(userAttributes, requiredAttributes);
          cognitoUser.completeNewPasswordChallenge(password, {}, this);
        },
      });
    });
  }

  async forgotPassword(email: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise<void>((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          // console.log('CodeDeliveryData from forgotPassword: ' + data);
          resolve();
        },
        onFailure: function (err) {
          console.error(err);
          reject(err);
        },
      });
    });
  }

  async confirmPassword(
    email: string,
    verificationCode: string,
    newPassword: string
  ): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve();
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  }

  updateUserEmail(
    email: string,
    password: string,
    newEmail: string
  ): Promise<void> {
    const Username = email;
    const Password = password;
    const cognitoUser = new CognitoUser({ Username, Pool: userPool });
    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    });

    // updateAtrributesを利用するにはauthenticatedUserでないとエラーになる
    // https://stackoverflow.com/questions/65966104/error-user-is-not-authenticated-amazon-cognito-in-nodejs
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async (res) => {
          cognitoUser.updateAttributes(
            [{ Name: "email", Value: newEmail }],
            (err) => {
              if (err) {
                reject(err.message);
              } else {
                resolve();
              }
            }
          );
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });
  }

  updateUserPassword(
    email: string,
    password: string,
    oldPassword: string
  ): Promise<void> {
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: oldPassword,
    });

    // updateAtrributesを利用するにはauthenticatedUserでないとエラーになる
    // https://stackoverflow.com/questions/65966104/error-user-is-not-authenticated-amazon-cognito-in-nodejs
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async (res) => {
          cognitoUser.changePassword(oldPassword, password, (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          });
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });
  }

  unauthenticate() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
  }

  async loadSession(options?: { needRefresh: boolean }) {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      return null;
    }

    return new Promise<UserSession>((resolve, reject) => {
      cognitoUser.getSession((err: any, result: any) => {
        const resolveSession = (err: any, result: any) => {
          if (result) {
            const idToken = result.getIdToken().getJwtToken();
            cognitoUser.getUserData(
              async (err2, userData) => {
                if (err2) {
                  reject(err2);
                  return;
                }
                const user = await this.getUser(idToken, userData?.Username!);
                resolve({
                  token: idToken,
                  user: {
                    ...user,
                    id: userData?.Username!,
                    email:
                      userData?.UserAttributes.find((x) => x.Name === "email")!
                        .Value || "",
                  },
                });
                // MARK: backendから直接データを取得する
              },
              { bypassCache: true }
            );
          } else {
            reject(err);
          }
        };
        if (options?.needRefresh) {
          const refreshToken = result.getRefreshToken();
          cognitoUser.refreshSession(refreshToken, (err, result) => {
            resolveSession(err, result);
          });
        } else {
          resolveSession(err, result);
        }
      });
    });
  }

  async getUsers(
    token: string,
    params?: { lastEvaluatedKey?: { id: string } }
  ): Promise<{ Items: UserListItemDto[]; LastEvaluatedKey?: { id: string } }> {
    const api = getApi(token, process.env.REACT_APP_ADMIN_API_URL);
    const response = await getUsers(api, params);
    return response.data;
  }

  async createUser(token: string, params: CreateUserDto): Promise<any> {
    const api = getApi(token, process.env.REACT_APP_ADMIN_API_URL);
    const response = await createUser(api, params);
    return response.data;
  }

  async updateUser(
    token: string,
    id: string,
    params: UpdateUserDto
  ): Promise<any> {
    const api = getApi(token, process.env.REACT_APP_ADMIN_API_URL);
    const response = await updateUser(api, id, params);
    return response.data;
  }

  async deleteUser(token: string, id: string): Promise<any> {
    const api = getApi(token, process.env.REACT_APP_ADMIN_API_URL);
    const response = await deleteUser(api, id);
    return response;
  }

  async getUser(token: string, id: string): Promise<UserDto> {
    const api = getApi(token, process.env.REACT_APP_ADMIN_API_URL);
    const response = await getUser(api, id);
    return response.data;
  }

  async getSystemDiagramUrls(
    token: string,
    params: { plantId: number }
  ): Promise<SystemDiagramsRefData> {
    const api = getApi(token, process.env.REACT_APP_API_URL);
    const response = await getSystemDiagramUrls(api, params);
    return response.data;
  }

  async postSystemDiagram(
    token: string,
    params: { form: FormData; plantId: number }
  ): Promise<SystemDiagramsRefData> {
    const api = getApi(token, process.env.REACT_APP_API_URL);
    const response = await postSystemDiagram(api, params);
    return response.data;
  }

  async deleteSystemDiagram(
    token: string,
    params: { plantId: number; diagramId: string }
  ): Promise<SystemDiagramsRefData> {
    const api = getApi(token, process.env.REACT_APP_API_URL);
    const response = await deleteSystemDiagram(api, params);
    return response.data;
  }

  // MARK: getSessionの外で呼び出すとnot authorized errorになるので、loadSessionのcallbackないで実行する
  // async getCurrentUser() {
  //   const cognitoUser = userPool.getCurrentUser()
  //   if(!cognitoUser) {
  //     return null
  //   }
  //   return new Promise<string | null>((resolve, reject) => {
  //     cognitoUser.getUserData(function(err, userData) {
  //       if (err) {
  //         console.error(err)
  //         return null;
  //       }
  //       console.log(userData)
  //       return userData as UserDto;
  //     });
  //   })
  // }
}
