import React, { useMemo } from "react";
import { CChartLine } from "@coreui/react-chartjs";
import dayjs from "dayjs";
import _ from "lodash";
import { CustomMeasurementItemModel, ThresholdModel } from "../../api";
import { getThresholdValue, borderSettings } from "../../utils/threshold";
import { getRoundedValue } from "../../utils/CMIData";

const util = require("@coreui/utils/src");
const { getStyle, hexToRgba } = util;

const brandInfo = getStyle("info") || "#20a8d8";
const brandWarning = getStyle("warning") || "#e6c100";
const brandDanger = getStyle("danger") || "#f86c6b";

// chart.jsのdataset仕様
// https://www.chartjs.org/docs/2.9.4/axes/cartesian/time.html#input-data
// The x-axis data points may additionally be specified via the t or x attribute when using the time scale.
export type SensorChartItem = {
  time: Date;
  value: number | null;
};

/**
 * 1つのグラフに1つのSpotを表示するパターン
 * @param attributes
 * @constructor
 */

// chartjsの仕様 https://www.chartjs.org/docs/2.9.4/axes/cartesian/time.html#time-units
export type ChartJsAxesTimeUnit =
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export type SensorChartTimeUnit =
  | "minute"
  | "hour"
  | "day"
  | "month"

interface Props {
  startTime: number; // X軸の終了時刻（UnixTimestamp)
  endTime: number; // X軸の開始時刻（UnixTimestamp)
  items: SensorChartItem[]; // { time: number, value: number } の配列
  customMeasurementItem: CustomMeasurementItemModel;
  spotId: number;
  threshold?: ThresholdModel; // 閾値設定 ない場合は閾値ラインを表示しない
  dateFormat: string; // x軸に表示する時刻のフォーマット
  xAxesUnit: ChartJsAxesTimeUnit; // x軸ラベルデータを表示する単位
  timeUnit: SensorChartTimeUnit // x軸にデータをプロットする単位
  unit: string;
}

const SensorChart: React.FC<Props> = (props) => {
  logger("start makeGraphBase");
  const { data, labels, maxValue } = useMemo(
    () =>
      getChartParams(
        props.items,
        props.startTime,
        props.endTime,
        props.timeUnit,
        props.customMeasurementItem.round_pos,
      ),
    [props.customMeasurementItem.round_pos, props.timeUnit, props.endTime, props.items, props.startTime]
  );
  logger("end makeGraphBase");

  const defaultDatasets = (() => {
    logger("start defaultDatasets");
    const dataSets = [];
    const mainLine = {
      label: `センサー値${
        props.customMeasurementItem.unit
          ? `(${props.customMeasurementItem.unit})`
          : ""
      }`,
      backgroundColor: hexToRgba(brandInfo, 10),
      borderColor: brandInfo,
      pointHoverBackgroundColor: brandInfo,
      borderWidth: 2,
      lineTension: 0, // デフォルトでベジエ曲線になるのをOFF
      pointRadius: 0.1, // 遅延により値が10分ごとなど飛び飛びになる場合の表現に必要
      data,
    };
    dataSets.push(mainLine);

    addGraphBorders(
      dataSets,
      props.customMeasurementItem.id,
      props.spotId,
      data.length,
      props.threshold
    );
    logger("end defaultDatasets");
    return dataSets;
  })();

  const defaultOptions = useMemo(() => {
    const stepSize = Math.ceil(maxValue / 5);
    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            // https://www.chartjs.org/docs/2.9.4/axes/cartesian/time.html#time-units
            type: "time",
            time: {
              unit: props.xAxesUnit,
              // https://www.chartjs.org/docs/2.9.4/axes/cartesian/time.html#display-formats
              displayFormats: {
                hour: "HH",
                day: "D",
                month: "M",
              },
            },
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: stepSize,
              max: Math.max(stepSize * 6, 1),
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: (tooltipItems: any[], data: any) => {
            if (0 < _.get(tooltipItems, "length")) {
              return dayjs(tooltipItems[0].label).format("M/D HH:mm:ss");
            }
          },
          label: function (tooltipItem: any, data: any) {
            let label = `${tooltipItem.yLabel}`;
            return label;
          },
        },
      },
      hover: {
        mode: "nearest",
        intersect: false,
      },
    };
  }, [maxValue, props.xAxesUnit]);

  return (
    <>
      <CChartLine
        style={{
          height: "300px",
        }}
        datasets={defaultDatasets}
        options={defaultOptions}
        labels={labels}
      />
    </>
  );
};

export default SensorChart;

function getChartParams(
  items: SensorChartItem[],
  startTime: number,
  endTime: number,
  timeUnit: string,
  roundPos?: number | null
) {
  let labels: Date[] = [],
    data: (number | null)[] = [],
    maxValue: number = 0;

  let time = startTime;
  while (time <= endTime) {

    let stepSecond: number;
    let roundedTime: number;
    switch (timeUnit) {
      case 'hour':
        stepSecond = 60 * 60; // 60分
        roundedTime = dayjs.unix(time).tz().startOf('hour').unix()
        break
      case 'day':
        stepSecond = 60 * 60 * 24; // 1日
        roundedTime = dayjs.unix(time).tz().startOf('day').unix()
        break
      case 'month':
        const startOfMonth = dayjs.unix(time).startOf('month').unix()
        const nextStartOfMonth = dayjs.unix(time).add(1, 'month').startOf('month').unix()
        stepSecond = nextStartOfMonth - startOfMonth; // 1ヶ月
        roundedTime = dayjs.unix(time).tz().startOf('month').unix()
        break
      case 'minute':
      default:
        stepSecond = 60; // 1分
        roundedTime = dayjs.unix(time).tz().startOf('minute').unix()
        break
    }
    const nextTime = dayjs.unix(time).add(stepSecond, 'second').unix();

    // stepSecondの単位の時刻で記録する
    const tickItem = items.find((item) => {
      const timestamp = item.time.getTime() / 1000;
      if (roundedTime <= timestamp && timestamp < roundedTime + stepSecond) {
        return true;
      }
      return false;
    });

    // ラベル
    labels.push(new Date(roundedTime * 1000));

    // 値
    let value: number | null;
    if (tickItem) {
      // round_posに応じて丸める
      value = getRoundedValue(tickItem.value, roundPos);
    } else {
      value = null;
    }
    data.push(value);

    // 最大値
    if (value !== null && value >= maxValue) {
      maxValue = value;
    }

    time = nextTime;
  }

  return { data, labels, maxValue };
}

const addGraphBorders = function (
  dataSets: any[],
  customMeasurementItemId: number,
  spotId: number,
  dataLength: number,
  threshold?: ThresholdModel
) {
  if (!threshold) return;
  for (let setting of borderSettings) {
    const value = getThresholdValue(
      threshold,
      customMeasurementItemId,
      spotId,
      setting
    );
    if (!value) continue;
    dataSets.push({
      label: setting.label,
      backgroundColor: "transparent",
      borderColor: setting.color,
      pointHoverBackgroundColor: setting.color,
      borderWidth: 2,
      borderDash: [2, 2],
      data: _.range(dataLength).map((i) => value),
    });
  }
};

export const logger = function (message: string) {
  const log = `${message}: ${dayjs().format(`YYYY-MM-DD HH:mm:ss.SSS`)}`;
};
