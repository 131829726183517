import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: object;
};

export type AccountModel = {
  __typename?: 'AccountModel';
  companyNameJp?: Maybe<Scalars['String']>;
  companyNameEn?: Maybe<Scalars['String']>;
};

export type AlertInput = {
  time: Scalars['Int'];
  alert_status: Scalars['String'];
  deal_status: Scalars['String'];
  improvement?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  response_detail?: Maybe<Scalars['String']>;
  response_person?: Maybe<Scalars['String']>;
  response_time?: Maybe<Scalars['Int']>;
};

export type AlertListModel = {
  __typename?: 'AlertListModel';
  records: Array<AlertModel>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyModel>;
};

export type AlertListOptionsInput = {
  limit?: Maybe<Scalars['Int']>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyInput>;
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
};

export type AlertModel = {
  __typename?: 'AlertModel';
  id: Scalars['String'];
  plant_id: Scalars['Int'];
  time: Scalars['Int'];
  alert_flg: Scalars['Boolean'];
  alert_status: Scalars['String'];
  deal_status: Scalars['String'];
  improvement?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  response_detail?: Maybe<Scalars['String']>;
  response_person?: Maybe<Scalars['String']>;
  response_time?: Maybe<Scalars['Int']>;
  spot_id?: Maybe<Scalars['Int']>;
  custom_measurement_id?: Maybe<Scalars['Int']>;
  normalized_time?: Maybe<Scalars['Int']>;
  custom_measurement_item?: Maybe<CustomMeasurementItemModel>;
};

export type AlertSummary = {
  __typename?: 'AlertSummary';
  time: Scalars['String'];
  primaryAlert?: Maybe<AlertModel>;
};

export type AlertSummaryListItem = {
  __typename?: 'AlertSummaryListItem';
  plant: PlantModel;
  latestPrimaryAlert?: Maybe<AlertModel>;
  alertSummaries: Array<AlertSummary>;
};

export type AlertSummaryListModel = {
  __typename?: 'AlertSummaryListModel';
  records: Array<AlertSummaryListItem>;
};

export type AlertSummaryListOptionsInput = {
  durationHours: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  plantIds?: Maybe<Array<Scalars['Int']>>;
};

export type CompanyDataOptionsModel = {
  __typename?: 'CompanyDataOptionsModel';
  plant_ids: Array<FormDataOptionModel>;
};

export type CompanyInput = {
  name_jp: Scalars['String'];
  plant_ids?: Maybe<Scalars['String']>;
};

export type CompanyListModel = {
  __typename?: 'CompanyListModel';
  records: Array<CompanyModel>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyModel>;
};

export type CompanyListOptionsInput = {
  limit?: Maybe<Scalars['Int']>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyInput>;
  company_id?: Maybe<Scalars['Int']>;
};

export type CompanyModel = {
  __typename?: 'CompanyModel';
  id: Scalars['Int'];
  name_jp: Scalars['String'];
  plant_ids?: Maybe<Scalars['String']>;
  plants?: Maybe<Array<PlantModel>>;
};

export type CustomMeasurementItemCreateInput = {
  plant_id: Scalars['String'];
  name_en: Scalars['String'];
  name_jp: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  round_pos?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type CustomMeasurementItemDataOptionsModel = {
  __typename?: 'CustomMeasurementItemDataOptionsModel';
  plant_id: Array<FormDataOptionModel>;
};

export type CustomMeasurementItemListModel = {
  __typename?: 'CustomMeasurementItemListModel';
  records: Array<CustomMeasurementItemModel>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyModel>;
};

export type CustomMeasurementItemListOptionsInput = {
  limit?: Maybe<Scalars['Int']>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyInput>;
  plant_id?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
};

export type CustomMeasurementItemModel = {
  __typename?: 'CustomMeasurementItemModel';
  id: Scalars['Int'];
  plant_id?: Maybe<Scalars['Int']>;
  name_en?: Maybe<Scalars['String']>;
  name_jp?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  round_pos?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type CustomMeasurementItemUpdateInput = {
  plant_id: Scalars['String'];
  name_en: Scalars['String'];
  name_jp: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  round_pos?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type CustomSensorValueItemModel = {
  __typename?: 'CustomSensorValueItemModel';
  client_id: Scalars['Int'];
  custom_measurement_id: Scalars['Int'];
  device_id: Scalars['Int'];
  request_id: Scalars['String'];
  sensor_id: Scalars['Int'];
  sensor_type: Scalars['Int'];
  spot_id: Scalars['Int'];
  time: Scalars['Int'];
  value: Scalars['Float'];
};

export type DashboardListModel = {
  __typename?: 'DashboardListModel';
  records: Array<DashboardModel>;
};

export type DashboardModel = {
  __typename?: 'DashboardModel';
  plantId: Scalars['Int'];
  plantNameJp: Scalars['String'];
  plantNameEn: Scalars['String'];
  durationHours?: Maybe<Scalars['Int']>;
  spots: Array<SpotModel>;
  customMeasurementItemIds?: Maybe<Array<Scalars['Int']>>;
  customMeasurementItems: Array<CustomMeasurementItemModel>;
  sensorLogs: Array<SensorLogModel>;
  companyIds?: Maybe<Array<Scalars['Int']>>;
};

export type FormDataOptionModel = {
  __typename?: 'FormDataOptionModel';
  value: Scalars['String'];
  label: Scalars['String'];
  restrictions?: Maybe<FormDataOptionRestrinctionModel>;
};

export type FormDataOptionNumberModel = {
  __typename?: 'FormDataOptionNumberModel';
  value: Scalars['Float'];
  label: Scalars['String'];
  restrictions?: Maybe<FormDataOptionRestrinctionNumberModel>;
};

export type FormDataOptionRestrinctionModel = {
  __typename?: 'FormDataOptionRestrinctionModel';
  plant_ids?: Maybe<Array<Scalars['String']>>;
};

export type FormDataOptionRestrinctionNumberModel = {
  __typename?: 'FormDataOptionRestrinctionNumberModel';
  plant_ids?: Maybe<Array<Scalars['Float']>>;
};


export type LastEvaluatedKeyInput = {
  id: Scalars['Int'];
};

export type LastEvaluatedKeyModel = {
  __typename?: 'LastEvaluatedKeyModel';
  id: Scalars['String'];
};

export type MinMaxInput = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type MinMaxModel = {
  __typename?: 'MinMaxModel';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** スポットを作成する */
  createSpot: SpotModel;
  /** idのスポットを更新 */
  updateSpot: SpotModel;
  /** idのスポットを削除 */
  deleteSpot: Scalars['String'];
  /** センサーを作成する */
  createCustomMeasurementItem: CustomMeasurementItemModel;
  /** idのセンサーを更新 */
  updateCustomMeasurementItem: CustomMeasurementItemModel;
  /** idのセンサーを削除 */
  deleteCustomMeasurementItem: Scalars['String'];
  /** plantIdの閾値設定を更新 */
  updateThreshold: ThresholdModel;
  /** 組織を作成する */
  createCompany: CompanyModel;
  /** idの組織を更新 */
  updateCompany: CompanyModel;
  /** idの組織を削除 */
  deleteCompany: Scalars['String'];
  /** プラントを作成する */
  createPlant: PlantModel;
  /** idのプラントを更新 */
  updatePlant: PlantModel;
  /** idのプラントを削除 */
  deletePlant: Scalars['String'];
  /** プラントの担当者・通史先メールアドレス送信する */
  sendMailToAssigner: Scalars['String'];
  /** アラートを作成する */
  createAlert: AlertModel;
  /** 指定のアラートを更新 */
  updateAlert: AlertModel;
  /** 指定のアラートを削除 */
  deleteAlert: Scalars['String'];
};


export type MutationCreateSpotArgs = {
  input: SpotInput;
};


export type MutationUpdateSpotArgs = {
  input: SpotInput;
  id: Scalars['ID'];
};


export type MutationDeleteSpotArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCustomMeasurementItemArgs = {
  input: CustomMeasurementItemCreateInput;
};


export type MutationUpdateCustomMeasurementItemArgs = {
  input: CustomMeasurementItemUpdateInput;
  id: Scalars['ID'];
};


export type MutationDeleteCustomMeasurementItemArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateThresholdArgs = {
  input: ThresholdInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
  id: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePlantArgs = {
  input: PlantInput;
};


export type MutationUpdatePlantArgs = {
  input: PlantInput;
  id: Scalars['ID'];
};


export type MutationDeletePlantArgs = {
  id: Scalars['ID'];
};


export type MutationSendMailToAssignerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAlertArgs = {
  input: AlertInput;
  plant_id: Scalars['ID'];
};


export type MutationUpdateAlertArgs = {
  input: UpdateAlertInput;
  id: Scalars['ID'];
};


export type MutationDeleteAlertArgs = {
  id: Scalars['ID'];
};

export type PlantDataOptionsModel = {
  __typename?: 'PlantDataOptionsModel';
  custom_measurement_item_ids: Array<FormDataOptionModel>;
};

export type PlantInput = {
  name_jp: Scalars['String'];
  emails?: Maybe<Array<Scalars['String']>>;
  custom_measurement_item_ids?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type PlantListModel = {
  __typename?: 'PlantListModel';
  records: Array<PlantModel>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyModel>;
};

export type PlantListOptionsInput = {
  limit?: Maybe<Scalars['Int']>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyInput>;
  includes?: Maybe<Array<Scalars['String']>>;
  company_id?: Maybe<Scalars['Int']>;
};

export type PlantManagerModel = {
  __typename?: 'PlantManagerModel';
  id: Scalars['String'];
  email: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  plant_ids?: Maybe<Scalars['String']>;
  client_ids?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type PlantModel = {
  __typename?: 'PlantModel';
  id: Scalars['Int'];
  name_jp: Scalars['String'];
  emails?: Maybe<Array<Scalars['String']>>;
  company_ids?: Maybe<Array<Scalars['Int']>>;
  custom_measurement_item_ids?: Maybe<Scalars['String']>;
  managers?: Maybe<Array<PlantManagerModel>>;
  companies?: Maybe<Array<CompanyModel>>;
  tags?: Maybe<Scalars['JSON']>;
};

export type Query = {
  __typename?: 'Query';
  /** スポット一覧 */
  getSpotList: SpotListModel;
  /** センサー一覧 */
  getCustomMeasurementItemList: CustomMeasurementItemListModel;
  /** センサーが選択し得る情報を取得 */
  getCustomMeasurementItemDataOptions: CustomMeasurementItemDataOptionsModel;
  /** dashboardを取得 */
  getDashboard: DashboardModel;
  /** 指定時刻のdashboardを取得 */
  getDashboardByTime: DashboardModel;
  /** dashboardの一覧を取得 */
  getDashboardList: DashboardListModel;
  /** plantIdの閾値設定を取得 */
  getThreshold: ThresholdModel;
  /** 閾値設定一覧 */
  getThresholdList: ThresholdListModel;
  /** 指定された plantId,spotId,durationHours に合致するサマリデータを取得 */
  getSensorSummaryList: SensorSummaryListModel;
  /** センサータイプ一覧 */
  getSensorTypeList: SensorTypeListModel;
  /** 認証情報を元にaccount情報を取得 */
  getAccount: AccountModel;
  /** Userが選択し得るプロフィール情報を取得 */
  getUserDataOptions: UserDataOptionsModel;
  /** 組織一覧 */
  getCompanyList: CompanyListModel;
  /** プラント一覧 */
  getPlantList: PlantListModel;
  /** Plantが選択し得る情報を取得 */
  getPlantDataOptions: PlantDataOptionsModel;
  /** 指定のクライアントユーザー・時間・ステータスにおけるアラート情報を取得 */
  getAlertSummaryList: AlertSummaryListModel;
  /** アラート一覧 */
  getAlertList: AlertListModel;
  /** Companyが選択し得る組織情報を取得 */
  getCompanyDataOptions: CompanyDataOptionsModel;
  /** 指定された plantId,spotId,durationHours に合致するサマリデータを取得 */
  getSensorValueList: SensorValueResponseListModel;
  /** 指定された plantId,spotId,durationHours に合致するS3データを取得 */
  getSensorValueListFromStorage: SensorValueListFromStorageResponseModel;
  /** Spotが選択し得るプラント情報を取得 */
  getSpotDataOptions: SpotDataOptionsModel;
};


export type QueryGetSpotListArgs = {
  options?: Maybe<SpotListOptionsInput>;
};


export type QueryGetCustomMeasurementItemListArgs = {
  options?: Maybe<CustomMeasurementItemListOptionsInput>;
};


export type QueryGetDashboardArgs = {
  durationHours: Scalars['Int'];
  useCache: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type QueryGetDashboardByTimeArgs = {
  time: Scalars['String'];
  id: Scalars['ID'];
};


export type QueryGetDashboardListArgs = {
  durationHours: Scalars['Int'];
  useCache: Scalars['Boolean'];
};


export type QueryGetThresholdArgs = {
  plantId: Scalars['ID'];
};


export type QueryGetSensorSummaryListArgs = {
  timestamp?: Maybe<Scalars['Int']>;
  interval: Scalars['String'];
  durationHours: Scalars['Int'];
  spotId: Scalars['ID'];
  plantId: Scalars['ID'];
};


export type QueryGetSensorTypeListArgs = {
  options?: Maybe<SensorTypeListOptionsInput>;
};


export type QueryGetUserDataOptionsArgs = {
  company_id?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyListArgs = {
  options?: Maybe<CompanyListOptionsInput>;
};


export type QueryGetPlantListArgs = {
  options?: Maybe<PlantListOptionsInput>;
};


export type QueryGetPlantDataOptionsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetAlertSummaryListArgs = {
  options?: Maybe<AlertSummaryListOptionsInput>;
};


export type QueryGetAlertListArgs = {
  options?: Maybe<AlertListOptionsInput>;
  plant_id?: Maybe<Scalars['ID']>;
};


export type QueryGetSensorValueListArgs = {
  options?: Maybe<SensorValueListOptions>;
};


export type QueryGetSensorValueListFromStorageArgs = {
  options?: Maybe<SensorValueListFromStorageOptions>;
};

export type SensorLogModel = {
  __typename?: 'SensorLogModel';
  /** time: yyyy-MM-dd HH:mm */
  time: Scalars['String'];
  sensors: Array<SensorLogSensorModel>;
};

export type SensorLogSensorModel = {
  __typename?: 'SensorLogSensorModel';
  customMeasurementItemId: Scalars['Int'];
  spots: Array<SensorLogSensorSpotModel>;
};

export type SensorLogSensorSpotModel = {
  __typename?: 'SensorLogSensorSpotModel';
  spotId: Scalars['Int'];
  value: Scalars['Float'];
};

export type SensorSummaryListModel = {
  __typename?: 'SensorSummaryListModel';
  plantId: Scalars['Int'];
  plant: PlantModel;
  records?: Maybe<Array<SensorSummaryModel>>;
};

export type SensorSummaryModel = {
  __typename?: 'SensorSummaryModel';
  spotId: Scalars['Int'];
  sensorType?: Maybe<Scalars['Int']>;
  customMeasurementId: Scalars['Int'];
  time: Scalars['Int'];
  cnt?: Maybe<Scalars['Int']>;
  sum?: Maybe<Scalars['Float']>;
  avg?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  fst?: Maybe<Scalars['Float']>;
  fstTime?: Maybe<Scalars['Float']>;
  lst?: Maybe<Scalars['Float']>;
  lstTime?: Maybe<Scalars['Float']>;
};

export type SensorTypeListModel = {
  __typename?: 'SensorTypeListModel';
  records?: Maybe<Array<SensorTypeModel>>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyModel>;
};

export type SensorTypeListOptionsInput = {
  plant_id?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyInput>;
};

export type SensorTypeModel = {
  __typename?: 'SensorTypeModel';
  id: Scalars['Int'];
  nameJp: Scalars['String'];
  nameEn: Scalars['String'];
  roundPos: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
};

export type SensorValueListFromStorageOptions = {
  plantId: Scalars['Int'];
  timestamp: Scalars['Int'];
  page: Scalars['Int'];
};

export type SensorValueListFromStorageResponseModel = {
  __typename?: 'SensorValueListFromStorageResponseModel';
  records: Array<SensorValueResponseListItem>;
  hasNextPage: Scalars['Boolean'];
};

export type SensorValueListOptions = {
  plantId: Scalars['Int'];
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
  spotId?: Maybe<Scalars['Int']>;
  isAll?: Maybe<Scalars['Boolean']>;
};

export type SensorValueModel = {
  __typename?: 'SensorValueModel';
  time: Scalars['Int'];
  spot_id: Scalars['Int'];
  request_id: Scalars['String'];
  value: Scalars['Float'];
  custom_measurement_id: Scalars['Int'];
};

export type SensorValueResponseListItem = {
  __typename?: 'SensorValueResponseListItem';
  plant_id: Scalars['Int'];
  time: Scalars['Int'];
  spot_id: Scalars['Int'];
  request_id: Scalars['Int'];
  value: Scalars['Float'];
  custom_measurement_id: Scalars['Int'];
};

export type SensorValueResponseListModel = {
  __typename?: 'SensorValueResponseListModel';
  records: Array<SensorValueResponseListItem>;
};

export type SpotDataOptionsModel = {
  __typename?: 'SpotDataOptionsModel';
  plant_ids: Array<FormDataOptionNumberModel>;
};

export type SpotInput = {
  plant_id?: Maybe<Scalars['Int']>;
  name_jp: Scalars['String'];
  name_en?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type SpotListModel = {
  __typename?: 'SpotListModel';
  records: Array<SpotModel>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyModel>;
};

export type SpotListOptionsInput = {
  plant_id?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  lastEvaluatedKey?: Maybe<LastEvaluatedKeyInput>;
};

export type SpotModel = {
  __typename?: 'SpotModel';
  id: Scalars['Int'];
  plant_id?: Maybe<Scalars['Int']>;
  name_jp: Scalars['String'];
  name_en?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type ThresholdCustomMeasurementItemInput = {
  customMeasurementItemId: Scalars['Int'];
  thresholdSpots: Array<ThresholdSpotInput>;
};

export type ThresholdCustomMeasurementItemModel = {
  __typename?: 'ThresholdCustomMeasurementItemModel';
  customMeasurementItemId: Scalars['Int'];
  thresholdSpots: Array<ThresholdSpotModel>;
};

export type ThresholdInput = {
  plantId: Scalars['Int'];
  thresholdCustomMeasurementItems: Array<ThresholdCustomMeasurementItemInput>;
};

export type ThresholdListModel = {
  __typename?: 'ThresholdListModel';
  records: Array<ThresholdModel>;
};

export type ThresholdModel = {
  __typename?: 'ThresholdModel';
  plantId: Scalars['Int'];
  plantNameJp?: Maybe<Scalars['String']>;
  plantNameEn?: Maybe<Scalars['String']>;
  thresholdCustomMeasurementItems: Array<ThresholdCustomMeasurementItemModel>;
  spots?: Maybe<Array<SpotModel>>;
  customMeasurementItems?: Maybe<Array<CustomMeasurementItemModel>>;
  companyIds?: Maybe<Array<Scalars['Int']>>;
};

export type ThresholdSpotInput = {
  spotId: Scalars['Int'];
  notice?: Maybe<MinMaxInput>;
  warning?: Maybe<MinMaxInput>;
  stop?: Maybe<MinMaxInput>;
};

export type ThresholdSpotModel = {
  __typename?: 'ThresholdSpotModel';
  spotId: Scalars['Int'];
  notice?: Maybe<MinMaxModel>;
  warning?: Maybe<MinMaxModel>;
  stop?: Maybe<MinMaxModel>;
};

export type UpdateAlertInput = {
  alert_status: Scalars['String'];
  deal_status: Scalars['String'];
  improvement?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  response_detail?: Maybe<Scalars['String']>;
  response_person?: Maybe<Scalars['String']>;
  response_time?: Maybe<Scalars['Int']>;
};

export type UserDataOptionsModel = {
  __typename?: 'UserDataOptionsModel';
  company_id: Array<FormDataOptionModel>;
  plant_ids: Array<FormDataOptionModel>;
};

export type AccountDetailFragment = (
  { __typename?: 'AccountModel' }
  & Pick<AccountModel, 'companyNameJp' | 'companyNameEn'>
);

export type AlertDetailFragment = (
  { __typename?: 'AlertModel' }
  & Pick<AlertModel, 'id' | 'plant_id' | 'time' | 'alert_flg' | 'alert_status' | 'deal_status' | 'improvement' | 'reason' | 'response_detail' | 'response_person' | 'response_time' | 'normalized_time' | 'custom_measurement_id'>
  & { custom_measurement_item?: Maybe<(
    { __typename?: 'CustomMeasurementItemModel' }
    & CustomMeasurementItemDetailFragment
  )> }
);

export type AlertSummaryDetailFragment = (
  { __typename?: 'AlertSummary' }
  & Pick<AlertSummary, 'time'>
  & { primaryAlert?: Maybe<(
    { __typename?: 'AlertModel' }
    & AlertDetailFragment
  )> }
);

export type CompanyDetailFragment = (
  { __typename?: 'CompanyModel' }
  & Pick<CompanyModel, 'id' | 'name_jp' | 'plant_ids'>
  & { plants?: Maybe<Array<(
    { __typename?: 'PlantModel' }
    & PlantDetailFragment
  )>> }
);

export type CustomMeasurementItemDetailFragment = (
  { __typename?: 'CustomMeasurementItemModel' }
  & Pick<CustomMeasurementItemModel, 'id' | 'plant_id' | 'name_en' | 'name_jp' | 'unit' | 'round_pos' | 'tags'>
);

export type DashboardDetailFragment = (
  { __typename?: 'DashboardModel' }
  & Pick<DashboardModel, 'plantId' | 'plantNameJp' | 'plantNameEn' | 'durationHours'>
  & { spots: Array<(
    { __typename?: 'SpotModel' }
    & SpotDetailFragment
  )>, customMeasurementItems: Array<(
    { __typename?: 'CustomMeasurementItemModel' }
    & CustomMeasurementItemDetailFragment
  )>, sensorLogs: Array<(
    { __typename?: 'SensorLogModel' }
    & SensorLogDetailFragment
  )> }
);

export type SensorLogDetailFragment = (
  { __typename?: 'SensorLogModel' }
  & Pick<SensorLogModel, 'time'>
  & { sensors: Array<(
    { __typename?: 'SensorLogSensorModel' }
    & SensorLogSensorDetailFragment
  )> }
);

export type SensorLogSensorDetailFragment = (
  { __typename?: 'SensorLogSensorModel' }
  & Pick<SensorLogSensorModel, 'customMeasurementItemId'>
  & { spots: Array<(
    { __typename?: 'SensorLogSensorSpotModel' }
    & SensorLogSensorSpotDetailFragment
  )> }
);

export type SensorLogSensorSpotDetailFragment = (
  { __typename?: 'SensorLogSensorSpotModel' }
  & Pick<SensorLogSensorSpotModel, 'spotId' | 'value'>
);

export type FormDataOptionRestrinctionDetailFragment = (
  { __typename?: 'FormDataOptionRestrinctionModel' }
  & Pick<FormDataOptionRestrinctionModel, 'plant_ids'>
);

export type FormDataOptionDetailFragment = (
  { __typename?: 'FormDataOptionModel' }
  & Pick<FormDataOptionModel, 'value' | 'label'>
  & { restrictions?: Maybe<(
    { __typename?: 'FormDataOptionRestrinctionModel' }
    & FormDataOptionRestrinctionDetailFragment
  )> }
);

export type FormDataOptionRestrinctionNumberDetailFragment = (
  { __typename?: 'FormDataOptionRestrinctionNumberModel' }
  & Pick<FormDataOptionRestrinctionNumberModel, 'plant_ids'>
);

export type FormDataOptionNumberDetailFragment = (
  { __typename?: 'FormDataOptionNumberModel' }
  & Pick<FormDataOptionNumberModel, 'value' | 'label'>
  & { restrictions?: Maybe<(
    { __typename?: 'FormDataOptionRestrinctionNumberModel' }
    & FormDataOptionRestrinctionNumberDetailFragment
  )> }
);

export type UserDataOptionsDetailFragment = (
  { __typename?: 'UserDataOptionsModel' }
  & { company_id: Array<(
    { __typename?: 'FormDataOptionModel' }
    & FormDataOptionDetailFragment
  )>, plant_ids: Array<(
    { __typename?: 'FormDataOptionModel' }
    & FormDataOptionDetailFragment
  )> }
);

export type CompanyDataOptionsDetailFragment = (
  { __typename?: 'CompanyDataOptionsModel' }
  & { plant_ids: Array<(
    { __typename?: 'FormDataOptionModel' }
    & FormDataOptionDetailFragment
  )> }
);

export type PlantDataOptionsDetailFragment = (
  { __typename?: 'PlantDataOptionsModel' }
  & { custom_measurement_item_ids: Array<(
    { __typename?: 'FormDataOptionModel' }
    & FormDataOptionDetailFragment
  )> }
);

export type CustomMeasurementItemDataOptionsDetailFragment = (
  { __typename?: 'CustomMeasurementItemDataOptionsModel' }
  & { plant_id: Array<(
    { __typename?: 'FormDataOptionModel' }
    & FormDataOptionDetailFragment
  )> }
);

export type SpotDataOptionsDetailFragment = (
  { __typename?: 'SpotDataOptionsModel' }
  & { plant_ids: Array<(
    { __typename?: 'FormDataOptionNumberModel' }
    & FormDataOptionNumberDetailFragment
  )> }
);

export type PlantManagerDetailFragment = (
  { __typename?: 'PlantManagerModel' }
  & Pick<PlantManagerModel, 'id' | 'email' | 'role' | 'company_id' | 'plant_ids' | 'client_ids' | 'full_name'>
);

export type PlantDetailFragment = (
  { __typename?: 'PlantModel' }
  & Pick<PlantModel, 'id' | 'name_jp' | 'emails' | 'custom_measurement_item_ids' | 'tags'>
  & { companies?: Maybe<Array<(
    { __typename?: 'CompanyModel' }
    & Pick<CompanyModel, 'id' | 'name_jp'>
  )>>, managers?: Maybe<Array<(
    { __typename?: 'PlantManagerModel' }
    & PlantManagerDetailFragment
  )>> }
);

export type SensorSummaryDetailFragment = (
  { __typename?: 'SensorSummaryModel' }
  & Pick<SensorSummaryModel, 'spotId' | 'sensorType' | 'customMeasurementId' | 'time' | 'cnt' | 'sum' | 'avg' | 'max' | 'min' | 'fst' | 'fstTime' | 'lst' | 'lstTime'>
);

export type SensorTypeDetailFragment = (
  { __typename?: 'SensorTypeModel' }
  & Pick<SensorTypeModel, 'id' | 'nameJp' | 'nameEn' | 'roundPos' | 'unit'>
);

export type SpotDetailFragment = (
  { __typename?: 'SpotModel' }
  & Pick<SpotModel, 'id' | 'plant_id' | 'name_jp' | 'name_en' | 'tags'>
);

export type ThresholdDetailFragment = (
  { __typename?: 'ThresholdModel' }
  & Pick<ThresholdModel, 'plantId' | 'plantNameJp' | 'plantNameEn'>
  & { spots?: Maybe<Array<(
    { __typename?: 'SpotModel' }
    & SpotDetailFragment
  )>>, customMeasurementItems?: Maybe<Array<(
    { __typename?: 'CustomMeasurementItemModel' }
    & CustomMeasurementItemDetailFragment
  )>>, thresholdCustomMeasurementItems: Array<(
    { __typename?: 'ThresholdCustomMeasurementItemModel' }
    & ThresholdCustomMeasurementItemDetailFragment
  )> }
);

export type ThresholdCustomMeasurementItemDetailFragment = (
  { __typename?: 'ThresholdCustomMeasurementItemModel' }
  & Pick<ThresholdCustomMeasurementItemModel, 'customMeasurementItemId'>
  & { thresholdSpots: Array<(
    { __typename?: 'ThresholdSpotModel' }
    & ThresholdSpotDetailFragment
  )> }
);

export type ThresholdSpotDetailFragment = (
  { __typename?: 'ThresholdSpotModel' }
  & Pick<ThresholdSpotModel, 'spotId'>
  & { notice?: Maybe<(
    { __typename?: 'MinMaxModel' }
    & MinMaxDetailFragment
  )>, warning?: Maybe<(
    { __typename?: 'MinMaxModel' }
    & MinMaxDetailFragment
  )>, stop?: Maybe<(
    { __typename?: 'MinMaxModel' }
    & MinMaxDetailFragment
  )> }
);

export type MinMaxDetailFragment = (
  { __typename?: 'MinMaxModel' }
  & Pick<MinMaxModel, 'min' | 'max'>
);

export type CreateAlertMutationVariables = Exact<{
  plant_id: Scalars['ID'];
  input: AlertInput;
}>;


export type CreateAlertMutation = (
  { __typename?: 'Mutation' }
  & { createAlert: (
    { __typename?: 'AlertModel' }
    & AlertDetailFragment
  ) }
);

export type UpdateAlertMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateAlertInput;
}>;


export type UpdateAlertMutation = (
  { __typename?: 'Mutation' }
  & { updateAlert: (
    { __typename?: 'AlertModel' }
    & AlertDetailFragment
  ) }
);

export type DeleteAlertMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAlertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAlert'>
);

export type CreateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'CompanyModel' }
    & CompanyDetailFragment
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'CompanyModel' }
    & CompanyDetailFragment
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type CreateCustomMeasurementItemMutationVariables = Exact<{
  input: CustomMeasurementItemCreateInput;
}>;


export type CreateCustomMeasurementItemMutation = (
  { __typename?: 'Mutation' }
  & { createCustomMeasurementItem: (
    { __typename?: 'CustomMeasurementItemModel' }
    & CustomMeasurementItemDetailFragment
  ) }
);

export type UpdateCustomMeasurementItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CustomMeasurementItemUpdateInput;
}>;


export type UpdateCustomMeasurementItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomMeasurementItem: (
    { __typename?: 'CustomMeasurementItemModel' }
    & CustomMeasurementItemDetailFragment
  ) }
);

export type DeleteCustomMeasurementItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCustomMeasurementItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomMeasurementItem'>
);

export type CreatePlantMutationVariables = Exact<{
  input: PlantInput;
}>;


export type CreatePlantMutation = (
  { __typename?: 'Mutation' }
  & { createPlant: (
    { __typename?: 'PlantModel' }
    & PlantDetailFragment
  ) }
);

export type UpdatePlantMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PlantInput;
}>;


export type UpdatePlantMutation = (
  { __typename?: 'Mutation' }
  & { updatePlant: (
    { __typename?: 'PlantModel' }
    & PlantDetailFragment
  ) }
);

export type DeletePlantMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlant'>
);

export type SendMailToAssignerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendMailToAssignerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMailToAssigner'>
);

export type CreateSpotMutationVariables = Exact<{
  input: SpotInput;
}>;


export type CreateSpotMutation = (
  { __typename?: 'Mutation' }
  & { createSpot: (
    { __typename?: 'SpotModel' }
    & SpotDetailFragment
  ) }
);

export type UpdateSpotMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SpotInput;
}>;


export type UpdateSpotMutation = (
  { __typename?: 'Mutation' }
  & { updateSpot: (
    { __typename?: 'SpotModel' }
    & SpotDetailFragment
  ) }
);

export type DeleteSpotMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSpotMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSpot'>
);

export type UpdateThresholdMutationVariables = Exact<{
  input: ThresholdInput;
}>;


export type UpdateThresholdMutation = (
  { __typename?: 'Mutation' }
  & { updateThreshold: (
    { __typename?: 'ThresholdModel' }
    & ThresholdDetailFragment
  ) }
);

export type GetAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { getAccount: (
    { __typename?: 'AccountModel' }
    & AccountDetailFragment
  ) }
);

export type GetAlertListQueryVariables = Exact<{
  plant_id?: Maybe<Scalars['ID']>;
  options?: Maybe<AlertListOptionsInput>;
}>;


export type GetAlertListQuery = (
  { __typename?: 'Query' }
  & { getAlertList: (
    { __typename?: 'AlertListModel' }
    & { records: Array<(
      { __typename?: 'AlertModel' }
      & AlertDetailFragment
    )>, lastEvaluatedKey?: Maybe<(
      { __typename?: 'LastEvaluatedKeyModel' }
      & Pick<LastEvaluatedKeyModel, 'id'>
    )> }
  ) }
);

export type GetAlertSummaryListQueryVariables = Exact<{
  options?: Maybe<AlertSummaryListOptionsInput>;
}>;


export type GetAlertSummaryListQuery = (
  { __typename?: 'Query' }
  & { getAlertSummaryList: (
    { __typename?: 'AlertSummaryListModel' }
    & { records: Array<(
      { __typename?: 'AlertSummaryListItem' }
      & { plant: (
        { __typename?: 'PlantModel' }
        & PlantDetailFragment
      ), latestPrimaryAlert?: Maybe<(
        { __typename?: 'AlertModel' }
        & AlertDetailFragment
      )>, alertSummaries: Array<(
        { __typename?: 'AlertSummary' }
        & AlertSummaryDetailFragment
      )> }
    )> }
  ) }
);

export type GetCompanyListQueryVariables = Exact<{
  options?: Maybe<CompanyListOptionsInput>;
}>;


export type GetCompanyListQuery = (
  { __typename?: 'Query' }
  & { getCompanyList: (
    { __typename?: 'CompanyListModel' }
    & { records: Array<(
      { __typename?: 'CompanyModel' }
      & CompanyDetailFragment
    )>, lastEvaluatedKey?: Maybe<(
      { __typename?: 'LastEvaluatedKeyModel' }
      & Pick<LastEvaluatedKeyModel, 'id'>
    )> }
  ) }
);

export type GetCompanyDataOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyDataOptionsQuery = (
  { __typename?: 'Query' }
  & { getCompanyDataOptions: (
    { __typename?: 'CompanyDataOptionsModel' }
    & CompanyDataOptionsDetailFragment
  ) }
);

export type GetCustomMeasurementItemListQueryVariables = Exact<{
  options?: Maybe<CustomMeasurementItemListOptionsInput>;
}>;


export type GetCustomMeasurementItemListQuery = (
  { __typename?: 'Query' }
  & { getCustomMeasurementItemList: (
    { __typename?: 'CustomMeasurementItemListModel' }
    & { records: Array<(
      { __typename?: 'CustomMeasurementItemModel' }
      & CustomMeasurementItemDetailFragment
    )>, lastEvaluatedKey?: Maybe<(
      { __typename?: 'LastEvaluatedKeyModel' }
      & Pick<LastEvaluatedKeyModel, 'id'>
    )> }
  ) }
);

export type GetCustomMeasurementItemDataOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomMeasurementItemDataOptionsQuery = (
  { __typename?: 'Query' }
  & { getCustomMeasurementItemDataOptions: (
    { __typename?: 'CustomMeasurementItemDataOptionsModel' }
    & CustomMeasurementItemDataOptionsDetailFragment
  ) }
);

export type GetDashboardQueryVariables = Exact<{
  id: Scalars['ID'];
  durationHours: Scalars['Int'];
  useCache: Scalars['Boolean'];
}>;


export type GetDashboardQuery = (
  { __typename?: 'Query' }
  & { getDashboard: (
    { __typename?: 'DashboardModel' }
    & DashboardDetailFragment
  ) }
);

export type GetDashboardByTimeQueryVariables = Exact<{
  id: Scalars['ID'];
  time: Scalars['String'];
}>;


export type GetDashboardByTimeQuery = (
  { __typename?: 'Query' }
  & { getDashboardByTime: (
    { __typename?: 'DashboardModel' }
    & DashboardDetailFragment
  ) }
);

export type GetDashboardListQueryVariables = Exact<{
  durationHours: Scalars['Int'];
  useCache: Scalars['Boolean'];
}>;


export type GetDashboardListQuery = (
  { __typename?: 'Query' }
  & { getDashboardList: (
    { __typename?: 'DashboardListModel' }
    & { records: Array<(
      { __typename?: 'DashboardModel' }
      & DashboardDetailFragment
    )> }
  ) }
);

export type GetPlantListQueryVariables = Exact<{
  options?: Maybe<PlantListOptionsInput>;
}>;


export type GetPlantListQuery = (
  { __typename?: 'Query' }
  & { getPlantList: (
    { __typename?: 'PlantListModel' }
    & { records: Array<(
      { __typename?: 'PlantModel' }
      & PlantDetailFragment
    )>, lastEvaluatedKey?: Maybe<(
      { __typename?: 'LastEvaluatedKeyModel' }
      & Pick<LastEvaluatedKeyModel, 'id'>
    )> }
  ) }
);

export type GetPlantDataOptionsQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GetPlantDataOptionsQuery = (
  { __typename?: 'Query' }
  & { getPlantDataOptions: (
    { __typename?: 'PlantDataOptionsModel' }
    & PlantDataOptionsDetailFragment
  ) }
);

export type GetSensorSummaryListQueryVariables = Exact<{
  interval: Scalars['String'];
  durationHours: Scalars['Int'];
  spotId: Scalars['ID'];
  plantId: Scalars['ID'];
  timestamp?: Maybe<Scalars['Int']>;
}>;


export type GetSensorSummaryListQuery = (
  { __typename?: 'Query' }
  & { getSensorSummaryList: (
    { __typename?: 'SensorSummaryListModel' }
    & Pick<SensorSummaryListModel, 'plantId'>
    & { records?: Maybe<Array<(
      { __typename?: 'SensorSummaryModel' }
      & SensorSummaryDetailFragment
    )>> }
  ) }
);

export type GetSensorTypeListQueryVariables = Exact<{
  options?: Maybe<SensorTypeListOptionsInput>;
}>;


export type GetSensorTypeListQuery = (
  { __typename?: 'Query' }
  & { getSensorTypeList: (
    { __typename?: 'SensorTypeListModel' }
    & { records?: Maybe<Array<(
      { __typename?: 'SensorTypeModel' }
      & SensorTypeDetailFragment
    )>>, lastEvaluatedKey?: Maybe<(
      { __typename?: 'LastEvaluatedKeyModel' }
      & Pick<LastEvaluatedKeyModel, 'id'>
    )> }
  ) }
);

export type GetSensorValueListQueryVariables = Exact<{
  options?: Maybe<SensorValueListOptions>;
}>;


export type GetSensorValueListQuery = (
  { __typename?: 'Query' }
  & { getSensorValueList: (
    { __typename?: 'SensorValueResponseListModel' }
    & { records: Array<(
      { __typename?: 'SensorValueResponseListItem' }
      & Pick<SensorValueResponseListItem, 'time' | 'spot_id' | 'plant_id' | 'value' | 'custom_measurement_id'>
    )> }
  ) }
);

export type GetSensorValueListFromStorageQueryVariables = Exact<{
  options?: Maybe<SensorValueListFromStorageOptions>;
}>;


export type GetSensorValueListFromStorageQuery = (
  { __typename?: 'Query' }
  & { getSensorValueListFromStorage: (
    { __typename?: 'SensorValueListFromStorageResponseModel' }
    & Pick<SensorValueListFromStorageResponseModel, 'hasNextPage'>
    & { records: Array<(
      { __typename?: 'SensorValueResponseListItem' }
      & Pick<SensorValueResponseListItem, 'time' | 'spot_id' | 'plant_id' | 'value' | 'custom_measurement_id'>
    )> }
  ) }
);

export type GetSpotDataOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpotDataOptionsQuery = (
  { __typename?: 'Query' }
  & { getSpotDataOptions: (
    { __typename?: 'SpotDataOptionsModel' }
    & SpotDataOptionsDetailFragment
  ) }
);

export type GetSpotsListQueryVariables = Exact<{
  options?: Maybe<SpotListOptionsInput>;
}>;


export type GetSpotsListQuery = (
  { __typename?: 'Query' }
  & { getSpotList: (
    { __typename?: 'SpotListModel' }
    & { records: Array<(
      { __typename?: 'SpotModel' }
      & SpotDetailFragment
    )>, lastEvaluatedKey?: Maybe<(
      { __typename?: 'LastEvaluatedKeyModel' }
      & Pick<LastEvaluatedKeyModel, 'id'>
    )> }
  ) }
);

export type GetThresholdQueryVariables = Exact<{
  plantId: Scalars['ID'];
}>;


export type GetThresholdQuery = (
  { __typename?: 'Query' }
  & { getThreshold: (
    { __typename?: 'ThresholdModel' }
    & ThresholdDetailFragment
  ) }
);

export type GetThresholdListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetThresholdListQuery = (
  { __typename?: 'Query' }
  & { getThresholdList: (
    { __typename?: 'ThresholdListModel' }
    & { records: Array<(
      { __typename?: 'ThresholdModel' }
      & ThresholdDetailFragment
    )> }
  ) }
);

export type GetUserDataOptionsQueryVariables = Exact<{
  company_id?: Maybe<Scalars['String']>;
}>;


export type GetUserDataOptionsQuery = (
  { __typename?: 'Query' }
  & { getUserDataOptions: (
    { __typename?: 'UserDataOptionsModel' }
    & UserDataOptionsDetailFragment
  ) }
);

export const AccountDetailFragmentDoc = gql`
    fragment AccountDetail on AccountModel {
  companyNameJp
  companyNameEn
}
    `;
export const CustomMeasurementItemDetailFragmentDoc = gql`
    fragment CustomMeasurementItemDetail on CustomMeasurementItemModel {
  id
  plant_id
  name_en
  name_jp
  unit
  round_pos
  tags
}
    `;
export const AlertDetailFragmentDoc = gql`
    fragment AlertDetail on AlertModel {
  id
  plant_id
  time
  alert_flg
  alert_status
  deal_status
  improvement
  reason
  response_detail
  response_person
  response_time
  normalized_time
  custom_measurement_id
  custom_measurement_item {
    ...CustomMeasurementItemDetail
  }
}
    ${CustomMeasurementItemDetailFragmentDoc}`;
export const AlertSummaryDetailFragmentDoc = gql`
    fragment AlertSummaryDetail on AlertSummary {
  time
  primaryAlert {
    ...AlertDetail
  }
}
    ${AlertDetailFragmentDoc}`;
export const PlantManagerDetailFragmentDoc = gql`
    fragment PlantManagerDetail on PlantManagerModel {
  id
  email
  role
  company_id
  plant_ids
  client_ids
  full_name
}
    `;
export const PlantDetailFragmentDoc = gql`
    fragment PlantDetail on PlantModel {
  id
  name_jp
  emails
  custom_measurement_item_ids
  companies {
    id
    name_jp
  }
  managers {
    ...PlantManagerDetail
  }
  tags
}
    ${PlantManagerDetailFragmentDoc}`;
export const CompanyDetailFragmentDoc = gql`
    fragment CompanyDetail on CompanyModel {
  id
  name_jp
  plant_ids
  plants {
    ...PlantDetail
  }
}
    ${PlantDetailFragmentDoc}`;
export const SpotDetailFragmentDoc = gql`
    fragment SpotDetail on SpotModel {
  id
  plant_id
  name_jp
  name_en
  tags
}
    `;
export const SensorLogSensorSpotDetailFragmentDoc = gql`
    fragment SensorLogSensorSpotDetail on SensorLogSensorSpotModel {
  spotId
  value
}
    `;
export const SensorLogSensorDetailFragmentDoc = gql`
    fragment SensorLogSensorDetail on SensorLogSensorModel {
  customMeasurementItemId
  spots {
    ...SensorLogSensorSpotDetail
  }
}
    ${SensorLogSensorSpotDetailFragmentDoc}`;
export const SensorLogDetailFragmentDoc = gql`
    fragment SensorLogDetail on SensorLogModel {
  time
  sensors {
    ...SensorLogSensorDetail
  }
}
    ${SensorLogSensorDetailFragmentDoc}`;
export const DashboardDetailFragmentDoc = gql`
    fragment DashboardDetail on DashboardModel {
  plantId
  plantNameJp
  plantNameEn
  durationHours
  spots {
    ...SpotDetail
  }
  customMeasurementItems {
    ...CustomMeasurementItemDetail
  }
  sensorLogs {
    ...SensorLogDetail
  }
}
    ${SpotDetailFragmentDoc}
${CustomMeasurementItemDetailFragmentDoc}
${SensorLogDetailFragmentDoc}`;
export const FormDataOptionRestrinctionDetailFragmentDoc = gql`
    fragment FormDataOptionRestrinctionDetail on FormDataOptionRestrinctionModel {
  plant_ids
}
    `;
export const FormDataOptionDetailFragmentDoc = gql`
    fragment FormDataOptionDetail on FormDataOptionModel {
  value
  label
  restrictions {
    ...FormDataOptionRestrinctionDetail
  }
}
    ${FormDataOptionRestrinctionDetailFragmentDoc}`;
export const UserDataOptionsDetailFragmentDoc = gql`
    fragment UserDataOptionsDetail on UserDataOptionsModel {
  company_id {
    ...FormDataOptionDetail
  }
  plant_ids {
    ...FormDataOptionDetail
  }
}
    ${FormDataOptionDetailFragmentDoc}`;
export const CompanyDataOptionsDetailFragmentDoc = gql`
    fragment CompanyDataOptionsDetail on CompanyDataOptionsModel {
  plant_ids {
    ...FormDataOptionDetail
  }
}
    ${FormDataOptionDetailFragmentDoc}`;
export const PlantDataOptionsDetailFragmentDoc = gql`
    fragment PlantDataOptionsDetail on PlantDataOptionsModel {
  custom_measurement_item_ids {
    ...FormDataOptionDetail
  }
}
    ${FormDataOptionDetailFragmentDoc}`;
export const CustomMeasurementItemDataOptionsDetailFragmentDoc = gql`
    fragment CustomMeasurementItemDataOptionsDetail on CustomMeasurementItemDataOptionsModel {
  plant_id {
    ...FormDataOptionDetail
  }
}
    ${FormDataOptionDetailFragmentDoc}`;
export const FormDataOptionRestrinctionNumberDetailFragmentDoc = gql`
    fragment FormDataOptionRestrinctionNumberDetail on FormDataOptionRestrinctionNumberModel {
  plant_ids
}
    `;
export const FormDataOptionNumberDetailFragmentDoc = gql`
    fragment FormDataOptionNumberDetail on FormDataOptionNumberModel {
  value
  label
  restrictions {
    ...FormDataOptionRestrinctionNumberDetail
  }
}
    ${FormDataOptionRestrinctionNumberDetailFragmentDoc}`;
export const SpotDataOptionsDetailFragmentDoc = gql`
    fragment SpotDataOptionsDetail on SpotDataOptionsModel {
  plant_ids {
    ...FormDataOptionNumberDetail
  }
}
    ${FormDataOptionNumberDetailFragmentDoc}`;
export const SensorSummaryDetailFragmentDoc = gql`
    fragment SensorSummaryDetail on SensorSummaryModel {
  spotId
  sensorType
  customMeasurementId
  time
  cnt
  sum
  avg
  max
  min
  fst
  fstTime
  lst
  lstTime
}
    `;
export const SensorTypeDetailFragmentDoc = gql`
    fragment SensorTypeDetail on SensorTypeModel {
  id
  nameJp
  nameEn
  roundPos
  unit
}
    `;
export const MinMaxDetailFragmentDoc = gql`
    fragment MinMaxDetail on MinMaxModel {
  min
  max
}
    `;
export const ThresholdSpotDetailFragmentDoc = gql`
    fragment ThresholdSpotDetail on ThresholdSpotModel {
  spotId
  notice {
    ...MinMaxDetail
  }
  warning {
    ...MinMaxDetail
  }
  stop {
    ...MinMaxDetail
  }
}
    ${MinMaxDetailFragmentDoc}`;
export const ThresholdCustomMeasurementItemDetailFragmentDoc = gql`
    fragment ThresholdCustomMeasurementItemDetail on ThresholdCustomMeasurementItemModel {
  customMeasurementItemId
  thresholdSpots {
    ...ThresholdSpotDetail
  }
}
    ${ThresholdSpotDetailFragmentDoc}`;
export const ThresholdDetailFragmentDoc = gql`
    fragment ThresholdDetail on ThresholdModel {
  plantId
  plantNameJp
  plantNameEn
  spots {
    ...SpotDetail
  }
  customMeasurementItems {
    ...CustomMeasurementItemDetail
  }
  thresholdCustomMeasurementItems {
    ...ThresholdCustomMeasurementItemDetail
  }
}
    ${SpotDetailFragmentDoc}
${CustomMeasurementItemDetailFragmentDoc}
${ThresholdCustomMeasurementItemDetailFragmentDoc}`;
export const CreateAlertDocument = gql`
    mutation createAlert($plant_id: ID!, $input: AlertInput!) {
  createAlert(plant_id: $plant_id, input: $input) {
    ...AlertDetail
  }
}
    ${AlertDetailFragmentDoc}`;
export type CreateAlertMutationFn = ApolloReactCommon.MutationFunction<CreateAlertMutation, CreateAlertMutationVariables>;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      plant_id: // value for 'plant_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAlertMutation, CreateAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAlertMutation, CreateAlertMutationVariables>(CreateAlertDocument, baseOptions);
      }
export type CreateAlertMutationHookResult = ReturnType<typeof useCreateAlertMutation>;
export type CreateAlertMutationResult = ApolloReactCommon.MutationResult<CreateAlertMutation>;
export type CreateAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAlertMutation, CreateAlertMutationVariables>;
export const UpdateAlertDocument = gql`
    mutation updateAlert($id: ID!, $input: UpdateAlertInput!) {
  updateAlert(id: $id, input: $input) {
    ...AlertDetail
  }
}
    ${AlertDetailFragmentDoc}`;
export type UpdateAlertMutationFn = ApolloReactCommon.MutationFunction<UpdateAlertMutation, UpdateAlertMutationVariables>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlertMutation, UpdateAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAlertMutation, UpdateAlertMutationVariables>(UpdateAlertDocument, baseOptions);
      }
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type UpdateAlertMutationResult = ApolloReactCommon.MutationResult<UpdateAlertMutation>;
export type UpdateAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAlertMutation, UpdateAlertMutationVariables>;
export const DeleteAlertDocument = gql`
    mutation deleteAlert($id: ID!) {
  deleteAlert(id: $id)
}
    `;
export type DeleteAlertMutationFn = ApolloReactCommon.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>;

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAlertMutation, DeleteAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAlertMutation, DeleteAlertMutationVariables>(DeleteAlertDocument, baseOptions);
      }
export type DeleteAlertMutationHookResult = ReturnType<typeof useDeleteAlertMutation>;
export type DeleteAlertMutationResult = ApolloReactCommon.MutationResult<DeleteAlertMutation>;
export type DeleteAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAlertMutation, DeleteAlertMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CompanyInput!) {
  createCompany(input: $input) {
    ...CompanyDetail
  }
}
    ${CompanyDetailFragmentDoc}`;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: ID!, $input: CompanyInput!) {
  updateCompany(id: $id, input: $input) {
    ...CompanyDetail
  }
}
    ${CompanyDetailFragmentDoc}`;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const CreateCustomMeasurementItemDocument = gql`
    mutation createCustomMeasurementItem($input: CustomMeasurementItemCreateInput!) {
  createCustomMeasurementItem(input: $input) {
    ...CustomMeasurementItemDetail
  }
}
    ${CustomMeasurementItemDetailFragmentDoc}`;
export type CreateCustomMeasurementItemMutationFn = ApolloReactCommon.MutationFunction<CreateCustomMeasurementItemMutation, CreateCustomMeasurementItemMutationVariables>;

/**
 * __useCreateCustomMeasurementItemMutation__
 *
 * To run a mutation, you first call `useCreateCustomMeasurementItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomMeasurementItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomMeasurementItemMutation, { data, loading, error }] = useCreateCustomMeasurementItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomMeasurementItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomMeasurementItemMutation, CreateCustomMeasurementItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomMeasurementItemMutation, CreateCustomMeasurementItemMutationVariables>(CreateCustomMeasurementItemDocument, baseOptions);
      }
export type CreateCustomMeasurementItemMutationHookResult = ReturnType<typeof useCreateCustomMeasurementItemMutation>;
export type CreateCustomMeasurementItemMutationResult = ApolloReactCommon.MutationResult<CreateCustomMeasurementItemMutation>;
export type CreateCustomMeasurementItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomMeasurementItemMutation, CreateCustomMeasurementItemMutationVariables>;
export const UpdateCustomMeasurementItemDocument = gql`
    mutation updateCustomMeasurementItem($id: ID!, $input: CustomMeasurementItemUpdateInput!) {
  updateCustomMeasurementItem(id: $id, input: $input) {
    ...CustomMeasurementItemDetail
  }
}
    ${CustomMeasurementItemDetailFragmentDoc}`;
export type UpdateCustomMeasurementItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomMeasurementItemMutation, UpdateCustomMeasurementItemMutationVariables>;

/**
 * __useUpdateCustomMeasurementItemMutation__
 *
 * To run a mutation, you first call `useUpdateCustomMeasurementItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomMeasurementItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomMeasurementItemMutation, { data, loading, error }] = useUpdateCustomMeasurementItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomMeasurementItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomMeasurementItemMutation, UpdateCustomMeasurementItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomMeasurementItemMutation, UpdateCustomMeasurementItemMutationVariables>(UpdateCustomMeasurementItemDocument, baseOptions);
      }
export type UpdateCustomMeasurementItemMutationHookResult = ReturnType<typeof useUpdateCustomMeasurementItemMutation>;
export type UpdateCustomMeasurementItemMutationResult = ApolloReactCommon.MutationResult<UpdateCustomMeasurementItemMutation>;
export type UpdateCustomMeasurementItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomMeasurementItemMutation, UpdateCustomMeasurementItemMutationVariables>;
export const DeleteCustomMeasurementItemDocument = gql`
    mutation deleteCustomMeasurementItem($id: ID!) {
  deleteCustomMeasurementItem(id: $id)
}
    `;
export type DeleteCustomMeasurementItemMutationFn = ApolloReactCommon.MutationFunction<DeleteCustomMeasurementItemMutation, DeleteCustomMeasurementItemMutationVariables>;

/**
 * __useDeleteCustomMeasurementItemMutation__
 *
 * To run a mutation, you first call `useDeleteCustomMeasurementItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomMeasurementItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomMeasurementItemMutation, { data, loading, error }] = useDeleteCustomMeasurementItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomMeasurementItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCustomMeasurementItemMutation, DeleteCustomMeasurementItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCustomMeasurementItemMutation, DeleteCustomMeasurementItemMutationVariables>(DeleteCustomMeasurementItemDocument, baseOptions);
      }
export type DeleteCustomMeasurementItemMutationHookResult = ReturnType<typeof useDeleteCustomMeasurementItemMutation>;
export type DeleteCustomMeasurementItemMutationResult = ApolloReactCommon.MutationResult<DeleteCustomMeasurementItemMutation>;
export type DeleteCustomMeasurementItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCustomMeasurementItemMutation, DeleteCustomMeasurementItemMutationVariables>;
export const CreatePlantDocument = gql`
    mutation createPlant($input: PlantInput!) {
  createPlant(input: $input) {
    ...PlantDetail
  }
}
    ${PlantDetailFragmentDoc}`;
export type CreatePlantMutationFn = ApolloReactCommon.MutationFunction<CreatePlantMutation, CreatePlantMutationVariables>;

/**
 * __useCreatePlantMutation__
 *
 * To run a mutation, you first call `useCreatePlantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlantMutation, { data, loading, error }] = useCreatePlantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlantMutation, CreatePlantMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlantMutation, CreatePlantMutationVariables>(CreatePlantDocument, baseOptions);
      }
export type CreatePlantMutationHookResult = ReturnType<typeof useCreatePlantMutation>;
export type CreatePlantMutationResult = ApolloReactCommon.MutationResult<CreatePlantMutation>;
export type CreatePlantMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlantMutation, CreatePlantMutationVariables>;
export const UpdatePlantDocument = gql`
    mutation updatePlant($id: ID!, $input: PlantInput!) {
  updatePlant(id: $id, input: $input) {
    ...PlantDetail
  }
}
    ${PlantDetailFragmentDoc}`;
export type UpdatePlantMutationFn = ApolloReactCommon.MutationFunction<UpdatePlantMutation, UpdatePlantMutationVariables>;

/**
 * __useUpdatePlantMutation__
 *
 * To run a mutation, you first call `useUpdatePlantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlantMutation, { data, loading, error }] = useUpdatePlantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlantMutation, UpdatePlantMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlantMutation, UpdatePlantMutationVariables>(UpdatePlantDocument, baseOptions);
      }
export type UpdatePlantMutationHookResult = ReturnType<typeof useUpdatePlantMutation>;
export type UpdatePlantMutationResult = ApolloReactCommon.MutationResult<UpdatePlantMutation>;
export type UpdatePlantMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlantMutation, UpdatePlantMutationVariables>;
export const DeletePlantDocument = gql`
    mutation deletePlant($id: ID!) {
  deletePlant(id: $id)
}
    `;
export type DeletePlantMutationFn = ApolloReactCommon.MutationFunction<DeletePlantMutation, DeletePlantMutationVariables>;

/**
 * __useDeletePlantMutation__
 *
 * To run a mutation, you first call `useDeletePlantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlantMutation, { data, loading, error }] = useDeletePlantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePlantMutation, DeletePlantMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePlantMutation, DeletePlantMutationVariables>(DeletePlantDocument, baseOptions);
      }
export type DeletePlantMutationHookResult = ReturnType<typeof useDeletePlantMutation>;
export type DeletePlantMutationResult = ApolloReactCommon.MutationResult<DeletePlantMutation>;
export type DeletePlantMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlantMutation, DeletePlantMutationVariables>;
export const SendMailToAssignerDocument = gql`
    mutation sendMailToAssigner($id: ID!) {
  sendMailToAssigner(id: $id)
}
    `;
export type SendMailToAssignerMutationFn = ApolloReactCommon.MutationFunction<SendMailToAssignerMutation, SendMailToAssignerMutationVariables>;

/**
 * __useSendMailToAssignerMutation__
 *
 * To run a mutation, you first call `useSendMailToAssignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMailToAssignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMailToAssignerMutation, { data, loading, error }] = useSendMailToAssignerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendMailToAssignerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMailToAssignerMutation, SendMailToAssignerMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMailToAssignerMutation, SendMailToAssignerMutationVariables>(SendMailToAssignerDocument, baseOptions);
      }
export type SendMailToAssignerMutationHookResult = ReturnType<typeof useSendMailToAssignerMutation>;
export type SendMailToAssignerMutationResult = ApolloReactCommon.MutationResult<SendMailToAssignerMutation>;
export type SendMailToAssignerMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMailToAssignerMutation, SendMailToAssignerMutationVariables>;
export const CreateSpotDocument = gql`
    mutation createSpot($input: SpotInput!) {
  createSpot(input: $input) {
    ...SpotDetail
  }
}
    ${SpotDetailFragmentDoc}`;
export type CreateSpotMutationFn = ApolloReactCommon.MutationFunction<CreateSpotMutation, CreateSpotMutationVariables>;

/**
 * __useCreateSpotMutation__
 *
 * To run a mutation, you first call `useCreateSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpotMutation, { data, loading, error }] = useCreateSpotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpotMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSpotMutation, CreateSpotMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSpotMutation, CreateSpotMutationVariables>(CreateSpotDocument, baseOptions);
      }
export type CreateSpotMutationHookResult = ReturnType<typeof useCreateSpotMutation>;
export type CreateSpotMutationResult = ApolloReactCommon.MutationResult<CreateSpotMutation>;
export type CreateSpotMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSpotMutation, CreateSpotMutationVariables>;
export const UpdateSpotDocument = gql`
    mutation updateSpot($id: ID!, $input: SpotInput!) {
  updateSpot(id: $id, input: $input) {
    ...SpotDetail
  }
}
    ${SpotDetailFragmentDoc}`;
export type UpdateSpotMutationFn = ApolloReactCommon.MutationFunction<UpdateSpotMutation, UpdateSpotMutationVariables>;

/**
 * __useUpdateSpotMutation__
 *
 * To run a mutation, you first call `useUpdateSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpotMutation, { data, loading, error }] = useUpdateSpotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpotMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSpotMutation, UpdateSpotMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSpotMutation, UpdateSpotMutationVariables>(UpdateSpotDocument, baseOptions);
      }
export type UpdateSpotMutationHookResult = ReturnType<typeof useUpdateSpotMutation>;
export type UpdateSpotMutationResult = ApolloReactCommon.MutationResult<UpdateSpotMutation>;
export type UpdateSpotMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSpotMutation, UpdateSpotMutationVariables>;
export const DeleteSpotDocument = gql`
    mutation deleteSpot($id: ID!) {
  deleteSpot(id: $id)
}
    `;
export type DeleteSpotMutationFn = ApolloReactCommon.MutationFunction<DeleteSpotMutation, DeleteSpotMutationVariables>;

/**
 * __useDeleteSpotMutation__
 *
 * To run a mutation, you first call `useDeleteSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpotMutation, { data, loading, error }] = useDeleteSpotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSpotMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSpotMutation, DeleteSpotMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSpotMutation, DeleteSpotMutationVariables>(DeleteSpotDocument, baseOptions);
      }
export type DeleteSpotMutationHookResult = ReturnType<typeof useDeleteSpotMutation>;
export type DeleteSpotMutationResult = ApolloReactCommon.MutationResult<DeleteSpotMutation>;
export type DeleteSpotMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSpotMutation, DeleteSpotMutationVariables>;
export const UpdateThresholdDocument = gql`
    mutation updateThreshold($input: ThresholdInput!) {
  updateThreshold(input: $input) {
    ...ThresholdDetail
  }
}
    ${ThresholdDetailFragmentDoc}`;
export type UpdateThresholdMutationFn = ApolloReactCommon.MutationFunction<UpdateThresholdMutation, UpdateThresholdMutationVariables>;

/**
 * __useUpdateThresholdMutation__
 *
 * To run a mutation, you first call `useUpdateThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThresholdMutation, { data, loading, error }] = useUpdateThresholdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThresholdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateThresholdMutation, UpdateThresholdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateThresholdMutation, UpdateThresholdMutationVariables>(UpdateThresholdDocument, baseOptions);
      }
export type UpdateThresholdMutationHookResult = ReturnType<typeof useUpdateThresholdMutation>;
export type UpdateThresholdMutationResult = ApolloReactCommon.MutationResult<UpdateThresholdMutation>;
export type UpdateThresholdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateThresholdMutation, UpdateThresholdMutationVariables>;
export const GetAccountDocument = gql`
    query getAccount {
  getAccount {
    ...AccountDetail
  }
}
    ${AccountDetailFragmentDoc}`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
      }
export function useGetAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = ApolloReactCommon.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const GetAlertListDocument = gql`
    query getAlertList($plant_id: ID, $options: AlertListOptionsInput) {
  getAlertList(plant_id: $plant_id, options: $options) {
    records {
      ...AlertDetail
    }
    lastEvaluatedKey {
      id
    }
  }
}
    ${AlertDetailFragmentDoc}`;

/**
 * __useGetAlertListQuery__
 *
 * To run a query within a React component, call `useGetAlertListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertListQuery({
 *   variables: {
 *      plant_id: // value for 'plant_id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAlertListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlertListQuery, GetAlertListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlertListQuery, GetAlertListQueryVariables>(GetAlertListDocument, baseOptions);
      }
export function useGetAlertListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertListQuery, GetAlertListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlertListQuery, GetAlertListQueryVariables>(GetAlertListDocument, baseOptions);
        }
export type GetAlertListQueryHookResult = ReturnType<typeof useGetAlertListQuery>;
export type GetAlertListLazyQueryHookResult = ReturnType<typeof useGetAlertListLazyQuery>;
export type GetAlertListQueryResult = ApolloReactCommon.QueryResult<GetAlertListQuery, GetAlertListQueryVariables>;
export const GetAlertSummaryListDocument = gql`
    query getAlertSummaryList($options: AlertSummaryListOptionsInput) {
  getAlertSummaryList(options: $options) {
    records {
      plant {
        ...PlantDetail
      }
      latestPrimaryAlert {
        ...AlertDetail
      }
      alertSummaries {
        ...AlertSummaryDetail
      }
    }
  }
}
    ${PlantDetailFragmentDoc}
${AlertDetailFragmentDoc}
${AlertSummaryDetailFragmentDoc}`;

/**
 * __useGetAlertSummaryListQuery__
 *
 * To run a query within a React component, call `useGetAlertSummaryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertSummaryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertSummaryListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAlertSummaryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlertSummaryListQuery, GetAlertSummaryListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlertSummaryListQuery, GetAlertSummaryListQueryVariables>(GetAlertSummaryListDocument, baseOptions);
      }
export function useGetAlertSummaryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertSummaryListQuery, GetAlertSummaryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlertSummaryListQuery, GetAlertSummaryListQueryVariables>(GetAlertSummaryListDocument, baseOptions);
        }
export type GetAlertSummaryListQueryHookResult = ReturnType<typeof useGetAlertSummaryListQuery>;
export type GetAlertSummaryListLazyQueryHookResult = ReturnType<typeof useGetAlertSummaryListLazyQuery>;
export type GetAlertSummaryListQueryResult = ApolloReactCommon.QueryResult<GetAlertSummaryListQuery, GetAlertSummaryListQueryVariables>;
export const GetCompanyListDocument = gql`
    query getCompanyList($options: CompanyListOptionsInput) {
  getCompanyList(options: $options) {
    records {
      ...CompanyDetail
    }
    lastEvaluatedKey {
      id
    }
  }
}
    ${CompanyDetailFragmentDoc}`;

/**
 * __useGetCompanyListQuery__
 *
 * To run a query within a React component, call `useGetCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCompanyListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, baseOptions);
      }
export function useGetCompanyListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, baseOptions);
        }
export type GetCompanyListQueryHookResult = ReturnType<typeof useGetCompanyListQuery>;
export type GetCompanyListLazyQueryHookResult = ReturnType<typeof useGetCompanyListLazyQuery>;
export type GetCompanyListQueryResult = ApolloReactCommon.QueryResult<GetCompanyListQuery, GetCompanyListQueryVariables>;
export const GetCompanyDataOptionsDocument = gql`
    query getCompanyDataOptions {
  getCompanyDataOptions {
    ...CompanyDataOptionsDetail
  }
}
    ${CompanyDataOptionsDetailFragmentDoc}`;

/**
 * __useGetCompanyDataOptionsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDataOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDataOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDataOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyDataOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyDataOptionsQuery, GetCompanyDataOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyDataOptionsQuery, GetCompanyDataOptionsQueryVariables>(GetCompanyDataOptionsDocument, baseOptions);
      }
export function useGetCompanyDataOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyDataOptionsQuery, GetCompanyDataOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyDataOptionsQuery, GetCompanyDataOptionsQueryVariables>(GetCompanyDataOptionsDocument, baseOptions);
        }
export type GetCompanyDataOptionsQueryHookResult = ReturnType<typeof useGetCompanyDataOptionsQuery>;
export type GetCompanyDataOptionsLazyQueryHookResult = ReturnType<typeof useGetCompanyDataOptionsLazyQuery>;
export type GetCompanyDataOptionsQueryResult = ApolloReactCommon.QueryResult<GetCompanyDataOptionsQuery, GetCompanyDataOptionsQueryVariables>;
export const GetCustomMeasurementItemListDocument = gql`
    query getCustomMeasurementItemList($options: CustomMeasurementItemListOptionsInput) {
  getCustomMeasurementItemList(options: $options) {
    records {
      ...CustomMeasurementItemDetail
    }
    lastEvaluatedKey {
      id
    }
  }
}
    ${CustomMeasurementItemDetailFragmentDoc}`;

/**
 * __useGetCustomMeasurementItemListQuery__
 *
 * To run a query within a React component, call `useGetCustomMeasurementItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomMeasurementItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomMeasurementItemListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCustomMeasurementItemListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomMeasurementItemListQuery, GetCustomMeasurementItemListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomMeasurementItemListQuery, GetCustomMeasurementItemListQueryVariables>(GetCustomMeasurementItemListDocument, baseOptions);
      }
export function useGetCustomMeasurementItemListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomMeasurementItemListQuery, GetCustomMeasurementItemListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomMeasurementItemListQuery, GetCustomMeasurementItemListQueryVariables>(GetCustomMeasurementItemListDocument, baseOptions);
        }
export type GetCustomMeasurementItemListQueryHookResult = ReturnType<typeof useGetCustomMeasurementItemListQuery>;
export type GetCustomMeasurementItemListLazyQueryHookResult = ReturnType<typeof useGetCustomMeasurementItemListLazyQuery>;
export type GetCustomMeasurementItemListQueryResult = ApolloReactCommon.QueryResult<GetCustomMeasurementItemListQuery, GetCustomMeasurementItemListQueryVariables>;
export const GetCustomMeasurementItemDataOptionsDocument = gql`
    query getCustomMeasurementItemDataOptions {
  getCustomMeasurementItemDataOptions {
    ...CustomMeasurementItemDataOptionsDetail
  }
}
    ${CustomMeasurementItemDataOptionsDetailFragmentDoc}`;

/**
 * __useGetCustomMeasurementItemDataOptionsQuery__
 *
 * To run a query within a React component, call `useGetCustomMeasurementItemDataOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomMeasurementItemDataOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomMeasurementItemDataOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomMeasurementItemDataOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomMeasurementItemDataOptionsQuery, GetCustomMeasurementItemDataOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomMeasurementItemDataOptionsQuery, GetCustomMeasurementItemDataOptionsQueryVariables>(GetCustomMeasurementItemDataOptionsDocument, baseOptions);
      }
export function useGetCustomMeasurementItemDataOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomMeasurementItemDataOptionsQuery, GetCustomMeasurementItemDataOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomMeasurementItemDataOptionsQuery, GetCustomMeasurementItemDataOptionsQueryVariables>(GetCustomMeasurementItemDataOptionsDocument, baseOptions);
        }
export type GetCustomMeasurementItemDataOptionsQueryHookResult = ReturnType<typeof useGetCustomMeasurementItemDataOptionsQuery>;
export type GetCustomMeasurementItemDataOptionsLazyQueryHookResult = ReturnType<typeof useGetCustomMeasurementItemDataOptionsLazyQuery>;
export type GetCustomMeasurementItemDataOptionsQueryResult = ApolloReactCommon.QueryResult<GetCustomMeasurementItemDataOptionsQuery, GetCustomMeasurementItemDataOptionsQueryVariables>;
export const GetDashboardDocument = gql`
    query getDashboard($id: ID!, $durationHours: Int!, $useCache: Boolean!) {
  getDashboard(id: $id, durationHours: $durationHours, useCache: $useCache) {
    ...DashboardDetail
  }
}
    ${DashboardDetailFragmentDoc}`;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      durationHours: // value for 'durationHours'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
      }
export function useGetDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = ApolloReactCommon.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const GetDashboardByTimeDocument = gql`
    query getDashboardByTime($id: ID!, $time: String!) {
  getDashboardByTime(id: $id, time: $time) {
    ...DashboardDetail
  }
}
    ${DashboardDetailFragmentDoc}`;

/**
 * __useGetDashboardByTimeQuery__
 *
 * To run a query within a React component, call `useGetDashboardByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardByTimeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useGetDashboardByTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDashboardByTimeQuery, GetDashboardByTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDashboardByTimeQuery, GetDashboardByTimeQueryVariables>(GetDashboardByTimeDocument, baseOptions);
      }
export function useGetDashboardByTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDashboardByTimeQuery, GetDashboardByTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDashboardByTimeQuery, GetDashboardByTimeQueryVariables>(GetDashboardByTimeDocument, baseOptions);
        }
export type GetDashboardByTimeQueryHookResult = ReturnType<typeof useGetDashboardByTimeQuery>;
export type GetDashboardByTimeLazyQueryHookResult = ReturnType<typeof useGetDashboardByTimeLazyQuery>;
export type GetDashboardByTimeQueryResult = ApolloReactCommon.QueryResult<GetDashboardByTimeQuery, GetDashboardByTimeQueryVariables>;
export const GetDashboardListDocument = gql`
    query getDashboardList($durationHours: Int!, $useCache: Boolean!) {
  getDashboardList(durationHours: $durationHours, useCache: $useCache) {
    records {
      ...DashboardDetail
    }
  }
}
    ${DashboardDetailFragmentDoc}`;

/**
 * __useGetDashboardListQuery__
 *
 * To run a query within a React component, call `useGetDashboardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardListQuery({
 *   variables: {
 *      durationHours: // value for 'durationHours'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useGetDashboardListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDashboardListQuery, GetDashboardListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDashboardListQuery, GetDashboardListQueryVariables>(GetDashboardListDocument, baseOptions);
      }
export function useGetDashboardListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDashboardListQuery, GetDashboardListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDashboardListQuery, GetDashboardListQueryVariables>(GetDashboardListDocument, baseOptions);
        }
export type GetDashboardListQueryHookResult = ReturnType<typeof useGetDashboardListQuery>;
export type GetDashboardListLazyQueryHookResult = ReturnType<typeof useGetDashboardListLazyQuery>;
export type GetDashboardListQueryResult = ApolloReactCommon.QueryResult<GetDashboardListQuery, GetDashboardListQueryVariables>;
export const GetPlantListDocument = gql`
    query getPlantList($options: PlantListOptionsInput) {
  getPlantList(options: $options) {
    records {
      ...PlantDetail
    }
    lastEvaluatedKey {
      id
    }
  }
}
    ${PlantDetailFragmentDoc}`;

/**
 * __useGetPlantListQuery__
 *
 * To run a query within a React component, call `useGetPlantListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetPlantListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlantListQuery, GetPlantListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlantListQuery, GetPlantListQueryVariables>(GetPlantListDocument, baseOptions);
      }
export function useGetPlantListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlantListQuery, GetPlantListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlantListQuery, GetPlantListQueryVariables>(GetPlantListDocument, baseOptions);
        }
export type GetPlantListQueryHookResult = ReturnType<typeof useGetPlantListQuery>;
export type GetPlantListLazyQueryHookResult = ReturnType<typeof useGetPlantListLazyQuery>;
export type GetPlantListQueryResult = ApolloReactCommon.QueryResult<GetPlantListQuery, GetPlantListQueryVariables>;
export const GetPlantDataOptionsDocument = gql`
    query getPlantDataOptions($id: ID) {
  getPlantDataOptions(id: $id) {
    ...PlantDataOptionsDetail
  }
}
    ${PlantDataOptionsDetailFragmentDoc}`;

/**
 * __useGetPlantDataOptionsQuery__
 *
 * To run a query within a React component, call `useGetPlantDataOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantDataOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantDataOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlantDataOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlantDataOptionsQuery, GetPlantDataOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlantDataOptionsQuery, GetPlantDataOptionsQueryVariables>(GetPlantDataOptionsDocument, baseOptions);
      }
export function useGetPlantDataOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlantDataOptionsQuery, GetPlantDataOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlantDataOptionsQuery, GetPlantDataOptionsQueryVariables>(GetPlantDataOptionsDocument, baseOptions);
        }
export type GetPlantDataOptionsQueryHookResult = ReturnType<typeof useGetPlantDataOptionsQuery>;
export type GetPlantDataOptionsLazyQueryHookResult = ReturnType<typeof useGetPlantDataOptionsLazyQuery>;
export type GetPlantDataOptionsQueryResult = ApolloReactCommon.QueryResult<GetPlantDataOptionsQuery, GetPlantDataOptionsQueryVariables>;
export const GetSensorSummaryListDocument = gql`
    query getSensorSummaryList($interval: String!, $durationHours: Int!, $spotId: ID!, $plantId: ID!, $timestamp: Int) {
  getSensorSummaryList(interval: $interval, durationHours: $durationHours, spotId: $spotId, plantId: $plantId, timestamp: $timestamp) {
    plantId
    records {
      ...SensorSummaryDetail
    }
  }
}
    ${SensorSummaryDetailFragmentDoc}`;

/**
 * __useGetSensorSummaryListQuery__
 *
 * To run a query within a React component, call `useGetSensorSummaryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorSummaryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorSummaryListQuery({
 *   variables: {
 *      interval: // value for 'interval'
 *      durationHours: // value for 'durationHours'
 *      spotId: // value for 'spotId'
 *      plantId: // value for 'plantId'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useGetSensorSummaryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorSummaryListQuery, GetSensorSummaryListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorSummaryListQuery, GetSensorSummaryListQueryVariables>(GetSensorSummaryListDocument, baseOptions);
      }
export function useGetSensorSummaryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorSummaryListQuery, GetSensorSummaryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorSummaryListQuery, GetSensorSummaryListQueryVariables>(GetSensorSummaryListDocument, baseOptions);
        }
export type GetSensorSummaryListQueryHookResult = ReturnType<typeof useGetSensorSummaryListQuery>;
export type GetSensorSummaryListLazyQueryHookResult = ReturnType<typeof useGetSensorSummaryListLazyQuery>;
export type GetSensorSummaryListQueryResult = ApolloReactCommon.QueryResult<GetSensorSummaryListQuery, GetSensorSummaryListQueryVariables>;
export const GetSensorTypeListDocument = gql`
    query getSensorTypeList($options: SensorTypeListOptionsInput) {
  getSensorTypeList(options: $options) {
    records {
      ...SensorTypeDetail
    }
    lastEvaluatedKey {
      id
    }
  }
}
    ${SensorTypeDetailFragmentDoc}`;

/**
 * __useGetSensorTypeListQuery__
 *
 * To run a query within a React component, call `useGetSensorTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorTypeListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSensorTypeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorTypeListQuery, GetSensorTypeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorTypeListQuery, GetSensorTypeListQueryVariables>(GetSensorTypeListDocument, baseOptions);
      }
export function useGetSensorTypeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorTypeListQuery, GetSensorTypeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorTypeListQuery, GetSensorTypeListQueryVariables>(GetSensorTypeListDocument, baseOptions);
        }
export type GetSensorTypeListQueryHookResult = ReturnType<typeof useGetSensorTypeListQuery>;
export type GetSensorTypeListLazyQueryHookResult = ReturnType<typeof useGetSensorTypeListLazyQuery>;
export type GetSensorTypeListQueryResult = ApolloReactCommon.QueryResult<GetSensorTypeListQuery, GetSensorTypeListQueryVariables>;
export const GetSensorValueListDocument = gql`
    query getSensorValueList($options: SensorValueListOptions) {
  getSensorValueList(options: $options) {
    records {
      time
      spot_id
      plant_id
      value
      custom_measurement_id
    }
  }
}
    `;

/**
 * __useGetSensorValueListQuery__
 *
 * To run a query within a React component, call `useGetSensorValueListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorValueListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorValueListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSensorValueListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorValueListQuery, GetSensorValueListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorValueListQuery, GetSensorValueListQueryVariables>(GetSensorValueListDocument, baseOptions);
      }
export function useGetSensorValueListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorValueListQuery, GetSensorValueListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorValueListQuery, GetSensorValueListQueryVariables>(GetSensorValueListDocument, baseOptions);
        }
export type GetSensorValueListQueryHookResult = ReturnType<typeof useGetSensorValueListQuery>;
export type GetSensorValueListLazyQueryHookResult = ReturnType<typeof useGetSensorValueListLazyQuery>;
export type GetSensorValueListQueryResult = ApolloReactCommon.QueryResult<GetSensorValueListQuery, GetSensorValueListQueryVariables>;
export const GetSensorValueListFromStorageDocument = gql`
    query getSensorValueListFromStorage($options: SensorValueListFromStorageOptions) {
  getSensorValueListFromStorage(options: $options) {
    records {
      time
      spot_id
      plant_id
      value
      custom_measurement_id
    }
    hasNextPage
  }
}
    `;

/**
 * __useGetSensorValueListFromStorageQuery__
 *
 * To run a query within a React component, call `useGetSensorValueListFromStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorValueListFromStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorValueListFromStorageQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSensorValueListFromStorageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorValueListFromStorageQuery, GetSensorValueListFromStorageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorValueListFromStorageQuery, GetSensorValueListFromStorageQueryVariables>(GetSensorValueListFromStorageDocument, baseOptions);
      }
export function useGetSensorValueListFromStorageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorValueListFromStorageQuery, GetSensorValueListFromStorageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorValueListFromStorageQuery, GetSensorValueListFromStorageQueryVariables>(GetSensorValueListFromStorageDocument, baseOptions);
        }
export type GetSensorValueListFromStorageQueryHookResult = ReturnType<typeof useGetSensorValueListFromStorageQuery>;
export type GetSensorValueListFromStorageLazyQueryHookResult = ReturnType<typeof useGetSensorValueListFromStorageLazyQuery>;
export type GetSensorValueListFromStorageQueryResult = ApolloReactCommon.QueryResult<GetSensorValueListFromStorageQuery, GetSensorValueListFromStorageQueryVariables>;
export const GetSpotDataOptionsDocument = gql`
    query getSpotDataOptions {
  getSpotDataOptions {
    ...SpotDataOptionsDetail
  }
}
    ${SpotDataOptionsDetailFragmentDoc}`;

/**
 * __useGetSpotDataOptionsQuery__
 *
 * To run a query within a React component, call `useGetSpotDataOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotDataOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotDataOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpotDataOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotDataOptionsQuery, GetSpotDataOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotDataOptionsQuery, GetSpotDataOptionsQueryVariables>(GetSpotDataOptionsDocument, baseOptions);
      }
export function useGetSpotDataOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotDataOptionsQuery, GetSpotDataOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotDataOptionsQuery, GetSpotDataOptionsQueryVariables>(GetSpotDataOptionsDocument, baseOptions);
        }
export type GetSpotDataOptionsQueryHookResult = ReturnType<typeof useGetSpotDataOptionsQuery>;
export type GetSpotDataOptionsLazyQueryHookResult = ReturnType<typeof useGetSpotDataOptionsLazyQuery>;
export type GetSpotDataOptionsQueryResult = ApolloReactCommon.QueryResult<GetSpotDataOptionsQuery, GetSpotDataOptionsQueryVariables>;
export const GetSpotsListDocument = gql`
    query getSpotsList($options: SpotListOptionsInput) {
  getSpotList(options: $options) {
    records {
      ...SpotDetail
    }
    lastEvaluatedKey {
      id
    }
  }
}
    ${SpotDetailFragmentDoc}`;

/**
 * __useGetSpotsListQuery__
 *
 * To run a query within a React component, call `useGetSpotsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotsListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSpotsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpotsListQuery, GetSpotsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpotsListQuery, GetSpotsListQueryVariables>(GetSpotsListDocument, baseOptions);
      }
export function useGetSpotsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpotsListQuery, GetSpotsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpotsListQuery, GetSpotsListQueryVariables>(GetSpotsListDocument, baseOptions);
        }
export type GetSpotsListQueryHookResult = ReturnType<typeof useGetSpotsListQuery>;
export type GetSpotsListLazyQueryHookResult = ReturnType<typeof useGetSpotsListLazyQuery>;
export type GetSpotsListQueryResult = ApolloReactCommon.QueryResult<GetSpotsListQuery, GetSpotsListQueryVariables>;
export const GetThresholdDocument = gql`
    query getThreshold($plantId: ID!) {
  getThreshold(plantId: $plantId) {
    ...ThresholdDetail
  }
}
    ${ThresholdDetailFragmentDoc}`;

/**
 * __useGetThresholdQuery__
 *
 * To run a query within a React component, call `useGetThresholdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThresholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThresholdQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function useGetThresholdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThresholdQuery, GetThresholdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetThresholdQuery, GetThresholdQueryVariables>(GetThresholdDocument, baseOptions);
      }
export function useGetThresholdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThresholdQuery, GetThresholdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetThresholdQuery, GetThresholdQueryVariables>(GetThresholdDocument, baseOptions);
        }
export type GetThresholdQueryHookResult = ReturnType<typeof useGetThresholdQuery>;
export type GetThresholdLazyQueryHookResult = ReturnType<typeof useGetThresholdLazyQuery>;
export type GetThresholdQueryResult = ApolloReactCommon.QueryResult<GetThresholdQuery, GetThresholdQueryVariables>;
export const GetThresholdListDocument = gql`
    query getThresholdList {
  getThresholdList {
    records {
      ...ThresholdDetail
    }
  }
}
    ${ThresholdDetailFragmentDoc}`;

/**
 * __useGetThresholdListQuery__
 *
 * To run a query within a React component, call `useGetThresholdListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThresholdListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThresholdListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetThresholdListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThresholdListQuery, GetThresholdListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetThresholdListQuery, GetThresholdListQueryVariables>(GetThresholdListDocument, baseOptions);
      }
export function useGetThresholdListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThresholdListQuery, GetThresholdListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetThresholdListQuery, GetThresholdListQueryVariables>(GetThresholdListDocument, baseOptions);
        }
export type GetThresholdListQueryHookResult = ReturnType<typeof useGetThresholdListQuery>;
export type GetThresholdListLazyQueryHookResult = ReturnType<typeof useGetThresholdListLazyQuery>;
export type GetThresholdListQueryResult = ApolloReactCommon.QueryResult<GetThresholdListQuery, GetThresholdListQueryVariables>;
export const GetUserDataOptionsDocument = gql`
    query getUserDataOptions($company_id: String) {
  getUserDataOptions(company_id: $company_id) {
    ...UserDataOptionsDetail
  }
}
    ${UserDataOptionsDetailFragmentDoc}`;

/**
 * __useGetUserDataOptionsQuery__
 *
 * To run a query within a React component, call `useGetUserDataOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataOptionsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetUserDataOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserDataOptionsQuery, GetUserDataOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserDataOptionsQuery, GetUserDataOptionsQueryVariables>(GetUserDataOptionsDocument, baseOptions);
      }
export function useGetUserDataOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserDataOptionsQuery, GetUserDataOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserDataOptionsQuery, GetUserDataOptionsQueryVariables>(GetUserDataOptionsDocument, baseOptions);
        }
export type GetUserDataOptionsQueryHookResult = ReturnType<typeof useGetUserDataOptionsQuery>;
export type GetUserDataOptionsLazyQueryHookResult = ReturnType<typeof useGetUserDataOptionsLazyQuery>;
export type GetUserDataOptionsQueryResult = ApolloReactCommon.QueryResult<GetUserDataOptionsQuery, GetUserDataOptionsQueryVariables>;