import "react-app-polyfill/ie11";
// TODO: core-jsに置き換えたほうが良さそう?
// http://var.blog.jp/archives/79457227.html
import "babel-polyfill";
import "array-flat-polyfill";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./store";

import { icons } from "./assets/icons";

import { UserAgentProvider } from "@quentin-sommer/react-useragent";

import "react-datepicker/dist/react-datepicker.css";
import "./assets/styles/index.scss";

import * as serviceWorker from "./serviceWorker";

import { AppContextProvider } from "./contexts/app";

import App from "./App";
import AppCoding from "./AppCoding";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "dayjs/locale/ja";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.locale("ja");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ja: require("./lang/ja.json"),
    },
    lng: "ja",
    fallbackLng: "ja",
    interpolation: {
      escapeValue: false,
    },
  });

Sentry.init({
  dsn: "https://fd606f1c472445569de4b5df0ead786a@o1086976.ingest.sentry.io/6142882",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_TARGET,
});

const React = require("react");
React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <AppContextProvider>
      <UserAgentProvider ua={window.navigator.userAgent}>
        <>
          <App />
          <AppCoding />
        </>
      </UserAgentProvider>
    </AppContextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
