import React, { useState } from "react";
import { Redirect, RouteProps } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppContext } from '../../contexts/app'
import { useHistory } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import LoadingButton from "./LoadingButton";
import logoDark from '../../assets/images/logo_dark.svg';
import LoadingSpinner from './LoadingSpinner'


const LoginPage : React.FC<RouteProps>  = (props: RouteProps) => {
  const history = useHistory()
  const { userSession, login, sessionLoading } = useAppContext()
  const { register, handleSubmit, } = useForm()
  const [loading, setLoading] = useState(false)

  const [loginError, setLoginError] = useState<boolean>(false)

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      await login(data.username, data.password)
    } catch (e: any) {
      setLoginError(true)
      setLoading(false)
    }
  }
  let redirectToPathname = '/'
  if (props.location && props.location.state) {
    const state = props.location.state as any
    redirectToPathname = state.from.pathname
  }

  if (userSession) {
    return <Redirect to={{pathname: redirectToPathname}} />;
  }

  if (sessionLoading) {
    return <LoadingSpinner className="mt-5"/>
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg="6" md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <h1 className="mb-5 text-center">
                    <img src={logoDark} alt="WOTA PLANT" height="24" width="233" style={{maxWidth: '100%'}} />
                  </h1>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="mb-5 text-center text-primary">ログイン</h4>
                    {
                      loginError &&
                      <div className="alert alert-danger">ユーザ名またはパスワードが異なります。</div>
                    }
                    
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <input name="username" ref={register} className="form-control" id="username" autoComplete="username" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <input name="password" ref={register} className="form-control" id="password" type="password" autoComplete="current-password" />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="12">
                        <LoadingButton type="submit" color="primary" className="p-2 mb-3 btn-block" loading={loading}>
                          ログイン
                        </LoadingButton>
                      </CCol>
                      <CCol xs="12" className="text-right">
                        <CButton color="link" className="px-0 btn-block" onClick={() => {
                          history.push('/forgot_password')
                        }}>パスワードをお忘れですか？</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default LoginPage
