export default {
  admin: 'SystemAdmin',
  organization_admin: 'Admin',
  default: 'User',
  guest_user: 'GuestUser',
  guest_user_r: 'GuestUser_R'
} as {
  admin: string
  organization_admin: string
  default: string
  guest_user: string
  guest_user_r: string
  [key: string]: string
}
