import React, { useEffect, useState } from "react"
import classNames from 'classnames'
import { useForm, Controller } from "react-hook-form"

import {
  CCard, CCardHeader, CCardBody,
  CLabel, CFormGroup, CFormText, CButton, CForm
} from '@coreui/react'
import Select from 'react-select'

import { FormDataOptionModel, useGetUserDataOptionsQuery } from '../../../api'
import { useAppContext } from "../../../contexts/app"
import { useToasts } from "react-toast-notifications"
import { useHistory, Link } from "react-router-dom"
import { CreateUserDto } from "../../../models/user"
import { CreateUserSchema } from '../../../formSchemas/manage/user'
import { UserModelUtil } from "../../../utils/UserModel"
import roleNameMap from './roleNameMap'
import CIcon from "@coreui/icons-react"

const UserCreate: React.FC = () => {
  const history = useHistory()
  const [plantIdsOptions, setPlantIdsOptions] = useState<FormDataOptionModel[]>([])
  const { createUser, userSession } = useAppContext()
  const { register, handleSubmit, reset, errors, control, getValues, setValue } = useForm<CreateUserDto>({
    validationSchema: CreateUserSchema,
  })
  const { addToast } = useToasts()
  
  useEffect(() => {
    if (userSession) {
      const user = userSession.user
      const isOrganizationAdmin = UserModelUtil.isOrganizationAdmin(user)
      const company_id = isOrganizationAdmin ? user.company_id : ''
      reset({
        company_id,
        plant_ids: ''
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  const user = userSession?.user
  const getUserDataOptionsQuery = useGetUserDataOptionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      company_id: user?.company_id
    },
    skip: !user
  })
  const userDataOptions = getUserDataOptionsQuery.data?.getUserDataOptions

  useEffect(() => {
    if (userDataOptions) {
      const companyId = getValues().company_id
      const companyIdRestrictions = userDataOptions.company_id.find(option => option.value === companyId)?.restrictions
      setPlantIdsOptions(userDataOptions.plant_ids.filter(option => companyIdRestrictions?.plant_ids?.includes(option.value)))
    }
  }, [getValues, userDataOptions])

  const onSubmit = async (data: CreateUserDto) => {
    try {
      await createUser(data)
      addToast('追加しました。', { appearance: 'success' })
      history.push('/users')
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '追加できませんでした', { appearance: 'error' })
    }
  }

  const isAdmin = UserModelUtil.isAdmin(userSession!.user)
  const isOrganizationAdmin = UserModelUtil.isOrganizationAdmin(userSession!.user)

  return (
    <>
      <Link to="/users"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>
          ユーザー新規登録
        </CCardHeader>
        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="name">氏名</CLabel>
              <input name="full_name" ref={register} autoComplete="full_name" placeholder="氏名" 
                className={classNames("form-control", { 'is-invalid': errors.full_name, })}
              />
              {
                errors.full_name?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.full_name?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="email">Eメール</CLabel>
              <input name="email" ref={register} autoComplete="email" placeholder="Eメール"
                className={classNames("form-control", { 'is-invalid': errors.email, })}
              />
              {
                errors.email?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                (errors.email?.type === 'email' || errors.email?.type === 'matches') &&
                <div className="invalid-feedback">
                  不正なフォーマットです
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="company_id">所属会社ID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={userDataOptions?.company_id} isDisabled={!isAdmin}
                    defaultValue={userDataOptions?.company_id.find(option => option.value === getValues().company_id)}
                    onChange={(option: any) => {
                      props.onChange(option.value)
                      setValue('plant_ids', '')
                      if (userDataOptions) {
                        setPlantIdsOptions(userDataOptions.plant_ids.filter(plantIdsOption => option.restrictions?.plant_ids?.includes(plantIdsOption.value)))
                      }
                    }}
                    className={classNames("select-form", { 'is-invalid': errors.company_id, })}
                  />
                )}
                name="company_id"
                control={control}
              />
              {
                errors.company_id?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は選択必須です
                </div>
              }
              {isOrganizationAdmin &&
                <CFormText className="help-block">※ 組織管理者と同じ会社を設定します</CFormText>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="plant_ids">担当プラントID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={plantIdsOptions} 
                    isMulti
                    onChange={(options: any) => {
                      const value = options ? options.map((option: any) => option.value).join(',') : ''
                      props.onChange(value)
                    }}
                  />
                )}
                name="plant_ids"
                control={control}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="role">ロール</CLabel>
              <select name="role" ref={register({ required: true })} placeholder="ロール" className="form-control">
                <option value="default">{roleNameMap['default']}</option>
                <option value="guest_user">{roleNameMap['guest_user']}</option>
                <option value="guest_user_r">{roleNameMap['guest_user_r']}</option>
                <option value="organization_admin">{roleNameMap['organization_admin']}</option>
                {isAdmin && <option value="admin">{roleNameMap['admin']}</option>}
              </select>
            </CFormGroup>
            <CFormGroup className="mt-4">
              <CButton type="submit" color="primary">追加</CButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default UserCreate
