import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";

import {
  CButton,
  CBadge,
  CRow,
  CCol,
  CFormGroup,
  CSelect,
} from "@coreui/react";

import { Link, useHistory, useParams } from "react-router-dom";

import CIcon from "@coreui/icons-react";
import { useGetAlertListQuery } from "../../../api";
import { AlertModel, RootState } from "../../../store";
import DataTable from "../../parts/DataTable";
import LoadingSpinner from "../../common/LoadingSpinner";
import ReactDatePicker from "react-datepicker";
import TableNoItemView from "../../common/TableNoItemView";
import { UserModelUtil } from '../../../utils/UserModel';
import { useAppContext } from '../../../contexts/app';


type alertStatusType = "ok" | "notice" | "warning" | "stop";

const alert_statuses: { key: alertStatusType; name: string }[] = [
  { key: "notice", name: "要観察" },
  { key: "warning", name: "警告" },
  { key: "stop", name: "通信不通" },
];

const getAlertStatusBadge = (alertStatus: alertStatusType) => {
  switch (alertStatus) {
    case "notice":
      return (
        <CBadge color="warning" className="text-white">
          <CIcon width={12} name="cil-warning" /> 要観察
        </CBadge>
      );
    case "warning":
      return (
        <CBadge color="danger">
          <CIcon width={12} name="cil-bell" /> 警告
        </CBadge>
      );
    case "stop":
      return (
        <CBadge color="dark">
          <CIcon width={12} name="cil-ban" /> 通信不通
        </CBadge>
      );
  }
};

type dealStatusType = "未対応" | "対応済み";

const deal_statuses: { key: dealStatusType; name: string }[] = [
  { key: "未対応", name: "未対応" },
  { key: "対応済み", name: "対応済み" },
];

const getDealStatusBadge = (dealStatus: dealStatusType) => {
  switch (dealStatus) {
    case "未対応":
      return (
        <CBadge color="danger">
          <CIcon width={12} name="cil-warning" /> 未対応
        </CBadge>
      );
    case "対応済み":
      return (
        <CBadge color="success">
          <CIcon width={12} name="cil-check-circle" /> 対応済み
        </CBadge>
      );
  }
};

type Rules = {
  filterAlertStatus: string;
  filterDealStatus: string;
  filterStartDate: Dayjs;
  filterEndDate: Dayjs;
};

function filterAlerts(alerts: AlertModel[] | null, rules: Rules): AlertModel[] {
  if (!alerts) return [];
  let displayAlerts = alerts;
  if (rules.filterAlertStatus) {
    displayAlerts =
      displayAlerts.filter(
        (alert) => alert.alert_status === rules.filterAlertStatus
      ) || null;
  }
  if (rules.filterDealStatus) {
    displayAlerts =
      displayAlerts.filter(
        (alert) => alert.deal_status === rules.filterDealStatus
      ) || null;
  }
  displayAlerts =
    displayAlerts.filter((alert) =>
      rules.filterStartDate.isBefore(alert.time * 1000)
    ) || null;
  displayAlerts =
    displayAlerts.filter((alert) =>
      rules.filterEndDate.isAfter(alert.time * 1000)
    ) || null;
  return displayAlerts;
}

const limit = 60;

const usePlantAlertList = (filterStartDate: Dayjs, filterEndDate: Dayjs) => {
  const dispatch = useDispatch();
  const { alerts } = useSelector((state: RootState) => state.alertsState);
  const [displayLimit, setDisplayLimit] = useState(limit);
  const { plantId } = useParams<{ plantId: string }>();

  const alertListQuery = useGetAlertListQuery({
    fetchPolicy: "no-cache",
    variables: {
      plant_id: plantId,
      options: {
        startTime: filterStartDate.unix(),
        endTime: filterEndDate.unix(),
      },
    },
  });
  const hasNext = !!alerts && displayLimit < alerts?.length;

  useEffect(() => {
    if (alertListQuery.data) {
      const alertList = alertListQuery.data.getAlertList;
      dispatch({
        type: "set",
        alertsState: {
          alerts: alertList.records,
          lastEvaluatedKey: alertList.lastEvaluatedKey,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertListQuery.data]);

  const getNextAlerts = () => {
    if (hasNext) {
      setDisplayLimit(displayLimit + limit);
    }
  };

  return {
    alertListQuery,
    alerts,
    displayLimit,
    getNextAlerts,
  };
};

const PlantDetailAlertList: React.FC = () => {
  const history = useHistory();
  const { userSession } = useAppContext();
  const { plantId } = useParams<{ plantId: string }>();
  const [filterAlertStatus, setFilterAlertStatus] = useState("");
  const [filterDealStatus, setFilterDealStatus] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(
    dayjs().startOf("day").subtract(2, 'week')
  );
  const [filterEndDate, setFilterEndDate] = useState(dayjs().endOf("day"));

  const { alertListQuery, alerts, displayLimit, getNextAlerts } =
    usePlantAlertList(filterStartDate, filterEndDate);
  const fields = [
    { key: "time", label: "警報日時" },
    { key: "alert_status", label: "警報種別" },
    { key: "deal_status", label: "対応状況" },
    { key: "custom_measurement_item_name", label: "対象センサー" },
    { key: "reason", label: "原因（想定込み）" },
    { key: "normalized_time", label: "復旧日時" },
    { key: "response_person", label: "対応者" },
    { key: "response_detail", label: "対処内容" },
    { key: "improvement", label: "今後の改善策" },
  ];

  const displayAlerts = filterAlerts(alerts, {
    filterAlertStatus,
    filterDealStatus,
    filterStartDate,
    filterEndDate,
  }).sort((a, b) => (a.time < b.time ? 1 : -1));

  function formatTextareaText(text: string | undefined) {
    if (!text) return "";
    if (text.length <= 50) return text;
    return text.slice(0, 50) + "...";
  }

  const tableItems = displayAlerts?.slice(0, displayLimit).map((alert) => {
    console.log(alert);
    return {
      id: alert.id,
      time: alert.time,
      alert_status: alert.alert_status || "",
      deal_status: alert.deal_status || "",
      reason: formatTextareaText(alert.reason),
      normalized_time: alert.normalized_time
        ? dayjs(alert.normalized_time * 1000).format("YYYY/MM/DD (ddd) HH:mm")
        : "",
      response_person: alert.response_person || "",
      response_detail: formatTextareaText(alert.response_detail),
      improvement: formatTextareaText(alert.improvement),
      custom_measurement_item_name:
        alert?.custom_measurement_item?.name_jp || "",
    };
  });

  const onFilterAlertStatusChanged = function (event: any) {
    setFilterAlertStatus(event.target.value);
  };

  const onFilterDealStatusChanged = function (event: any) {
    setFilterDealStatus(event.target.value);
  };

  return (
    <>
      <div className="flex items-center justify-content-between mt-2 mb-2">
        {
          userSession && !UserModelUtil.isGuestUserR(userSession.user) &&
          <>
            <span></span>
            <CButton
              type="button"
              color="primary"
              onClick={() => {
                history.push(`/plants/${plantId}/alerts/new`);
              }}
            >
              <i className="mr-1">
                <CIcon size={"sm"} name={"cil-plus"} />
              </i>
              追加
            </CButton>
          </>
        }
      </div>
      <CRow>
        <CCol sm="3">
          <div className="react-datepicker-has-icon d-inline-block">
            <ReactDatePicker
              locale="ja"
              maxDate={filterEndDate.toDate()}
              selected={filterStartDate.toDate()}
              onChange={(date) => setFilterStartDate(dayjs(date))}
              selectsStart
              startDate={filterStartDate.toDate()}
              endDate={filterEndDate.toDate()}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              dateFormat="yyyy/MM/dd HH:mm"
              popperPlacement="top-start"
              className="form-control"
              ref={(el: any) => {
                if (el && el.input) {
                  el.input.readOnly = true;
                }
              }}
            />
            <i className="react-datepicker-icon">
              <CIcon name={"cilCalendar"} />
            </i>
          </div>
        </CCol>
        <CCol sm="3">
          <div className="react-datepicker-has-icon d-inline-block">
            <ReactDatePicker
              locale="ja"
              maxDate={dayjs().toDate()}
              minDate={filterStartDate.toDate()}
              selected={filterEndDate.toDate()}
              onChange={(date) => setFilterEndDate(dayjs(date))}
              selectsStart
              startDate={filterStartDate.toDate()}
              endDate={filterEndDate.toDate()}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              dateFormat="yyyy/MM/dd HH:mm"
              popperPlacement="top-start"
              className="form-control"
              ref={(el: any) => {
                if (el && el.input) {
                  el.input.readOnly = true;
                }
              }}
            />
            <i className="react-datepicker-icon">
              <CIcon name={"cilCalendar"} />
            </i>
          </div>
        </CCol>
        <CCol sm="3">
          <CFormGroup>
            <CSelect
              name="filter_alert_status"
              onChange={(e) => onFilterAlertStatusChanged(e)}
            >
              <option value="">すべての警報種別</option>
              {alert_statuses.map((alert_status) => {
                return (
                  <option value={alert_status.key} key={alert_status.key}>
                    {alert_status.name}
                  </option>
                );
              })}
            </CSelect>
          </CFormGroup>
        </CCol>
        <CCol sm="3">
          <CFormGroup>
            <CSelect
              name="filter_deal_status"
              onChange={(e) => onFilterDealStatusChanged(e)}
            >
              <option value="">すべての対応状況</option>
              {deal_statuses.map((deal_status) => {
                return (
                  <option value={deal_status.key} key={deal_status.key}>
                    {deal_status.name}
                  </option>
                );
              })}
            </CSelect>
          </CFormGroup>
        </CCol>
      </CRow>
      {alertListQuery.loading && <LoadingSpinner className="py-4" />}
      {!alertListQuery.loading && tableItems && (
        <>
          <DataTable
            fields={fields}
            items={tableItems}
            scopedSlots={{
              alert_status: (item: any) => (
                <td>{getAlertStatusBadge(item.alert_status)}</td>
              ),
              deal_status: (item: any) => (
                <td>{getDealStatusBadge(item.deal_status)}</td>
              ),
              time: (item: any) => (
                <td>
                  {
                    userSession && UserModelUtil.isGuestUserR(userSession.user)
                      ? <span>{dayjs(item.time * 1000).format("YYYY/MM/DD (ddd) HH:mm")}</span>
                      : <Link to={`/plants/${plantId}/alerts/${item.id}`}>
                        {dayjs(item.time * 1000).format("YYYY/MM/DD (ddd) HH:mm")}
                      </Link>
                  }
                </td>
              ),
            }}
            noItemsViewSlot={
              <TableNoItemView text="該当の警報発令対応報告書はありません" />
            }
          />
        </>
      )}
      {!!displayAlerts && displayLimit < displayAlerts?.length && (
        <CButton
          type="button"
          color="secondary"
          onClick={() => {
            getNextAlerts();
          }}
        >
          次を読み込む
        </CButton>
      )}
    </>
  );
};

export default PlantDetailAlertList;
