import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { UserDto, UserListItemDto, CreateUserDto, UpdateUserDto } from '../models/user'

export const getApi = (jwtToken?: string | null, baseURL?: string) => {
  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    }
  })
}

export const getUsers = async (
  api: AxiosInstance, params?: { lastEvaluatedKey?: {id: string}; }): Promise<AxiosResponse<{ Items: UserListItemDto[], LastEvaluatedKey?: {id: string}; }>> => {
  const url = '/users'
  return await api.get(url,  { params })
}

export const createUser = async (
  api: AxiosInstance, params: CreateUserDto): Promise<AxiosResponse<any>> => {
  const url = '/users'
  return await api.post(url, params)
}

export const getUser = async (
    api: AxiosInstance, username: string): Promise<AxiosResponse<UserDto>> => {
  const url = `/users/${username}`
  return await api.get(url)
}

export const updateUser = async (
  api: AxiosInstance, username: string, params: UpdateUserDto): Promise<AxiosResponse<any>> => {
  const url = `/users/${username}`
  return await api.put(url, params)
}

export const deleteUser = async (
  api: AxiosInstance, username: string): Promise<AxiosResponse<any>> => {
  const url = `/users/${username}`
  return await api.delete(url)
}

export type SystemDiagramsRefData = {
  urls: string[]
}
export const getSystemDiagramUrls = async (
  api: AxiosInstance,
  params: {plantId: number}): Promise<AxiosResponse<SystemDiagramsRefData>> => {
  const url = '/systemDiagrams'
  return await api.get(url + '/' + params.plantId)
}
export const postSystemDiagram = async (
  api: AxiosInstance,
  params: {form: FormData, plantId: number}): Promise<AxiosResponse<SystemDiagramsRefData>> => {
  const url = '/systemDiagrams'
  return await api.post(url + '/' + params.plantId, params.form)
}
export const deleteSystemDiagram = async (
  api: AxiosInstance,
  params: {plantId: number, diagramId: string}): Promise<AxiosResponse<SystemDiagramsRefData>> => {
  const url = '/systemDiagrams'
  return await api.delete(url + '/' + params.plantId + '/' + params.diagramId)
}