import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { FiEdit } from 'react-icons/fi';

import {
  CCard, CCardHeader, CCardBody, CButton, CRow, CCol, CFormGroup, CInputGroup, CInput, CInputGroupAppend, CInputGroupText, CLink
} from '@coreui/react'

import {Link, useHistory} from 'react-router-dom'

import CIcon from '@coreui/icons-react'
import {useGetCompanyListQuery, useGetPlantListQuery} from '../../../api'
import { RootState } from '../../../store'
import DataTable from '../../parts/DataTable'
import LoadingSpinner from "../../common/LoadingSpinner"
import Select from "react-select";
import {useAppContext} from "../../../contexts/app";
import {UserDto} from "../../../models/user";
import TagLabel from "../../common/TagLabel";

const limit = 60

const usePlantList = (user?: UserDto) => {
  const dispatch = useDispatch()
  const { plants } = useSelector((state: RootState) => state.plantsState)
  const [displayLimit, setDisplayLimit] = useState(limit)

  const { loading, data } = useGetPlantListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      options: {
        includes: ['companies'],
        company_id: Number(user?.company_id)
      }
    }
  })
  const hasNext = !!plants && displayLimit < plants?.length

  useEffect(() => {
    if (data) {
      const plantList = data.getPlantList
      dispatch({ type: 'set', plantsState: {
        plants: plantList.records,
        lastEvaluatedKey: plantList.lastEvaluatedKey
      }})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getNextCompanies = () => {
    if (hasNext) {
      setDisplayLimit(displayLimit + limit)
    }
  }

  return {
    loadingPlant: loading,
    plants,
    displayLimit,
    getNextCompanies,
  }
}

const useCompanyList = (user?: UserDto) => {
  const dispatch = useDispatch()
  const { companies } = useSelector((state: RootState) => state.companiesState)

  const { loading, data } = useGetCompanyListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      options: {
        company_id: Number(user?.company_id)
      }
    }
  })

  useEffect(() => {
    if (data) {
      const companyList = data.getCompanyList
      dispatch({ type: 'set', companiesState: {
          companies: companyList.records
        }})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    loadingCompany: loading,
    companies
  }
}

const PlantList: React.FC = () => {
  const { userSession } = useAppContext()
  const user = userSession?.user

  const history = useHistory()
  const { loadingPlant, plants, displayLimit, getNextCompanies } = usePlantList(user)
  const { companies } = useCompanyList(user)
  const [searchName, setSearchName] = useState('')
  const [searchCompanyIDs, setSearchCompanyIDs] = useState('')
  const fields = [
    {key: 'id', label: 'ID'},
    {key: 'name_jp', label: 'プラント名'},
    {key: 'companies', label: '所属会社'},
    {key: 'tags', label: 'タグ'},
    {key: 'actions', label: ''},
  ];

  const companyOptions = companies?.
    map(company=> {
      return {value: company.id, label: company.name_jp}
    })

  let displayPlants = plants?.
    filter(plants => plants.name_jp.match(new RegExp(searchName))).
    filter(plant => {
      if (!searchCompanyIDs) {
        return true;
      }

      const filterdPlantIds = companies?.filter(company=>{
        return searchCompanyIDs.split(',').includes(String(company.id))
      })?.map(company=>company.plant_ids)?.join(',');
      return filterdPlantIds?.split(',').includes(String(plant.id))
    })

  const tableItems = displayPlants?.slice(0, displayLimit).map(plant => {
    return {
      id: plant.id,
      name_jp: plant.name_jp || '',
      companies: plant.companies,
      tags: plant.tags
    }
  })

  const onNameChanged = function(event: any) {
    setSearchName(event.target.value)
  }

  return (
    <>
      <CCard>
        <CCardHeader>
          <div className="flex items-center justify-content-between">
            <span>プラント一覧</span>
            <CButton type="button" color="primary" onClick={() => {
              history.push(`/manage/plants/new`);
            }}>
              <i className="mr-1">
                <CIcon size={'sm'} name={'cil-plus'} />
              </i>
              追加
            </CButton>
          </div>

        </CCardHeader>
        <CCardBody>
          {
            tableItems &&
            <>
              <CRow>
                <CCol sm="3">
                  <CFormGroup>
                    <CInputGroup>
                      <CInput type="text" name="name" placeholder="名前で検索"  onChange={(e) => onNameChanged(e)}/>
                      <CInputGroupAppend>
                        <CInputGroupText>
                          <CIcon name="cil-magnifying-glass" />
                        </CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                </CCol>
                {user?.role === 'admin'  && (
                    <CCol sm="3">
                      <CFormGroup>
                        <Select name='search_companies' options={companyOptions}
                                isMulti
                                onChange={(options: any) => {
                                  const value = options ? options.map((option: any) => option.value).join(',') : ''
                                  setSearchCompanyIDs(value)
                                }}
                                placeholder='組織IDで検索'
                        />
                      </CFormGroup>
                    </CCol>
                )}
              </CRow>
              <DataTable
                fields={fields}
                items={tableItems}
                scopedSlots={{
                  companies: (item: any) => {
                    return (
                      <td>
                        {item.companies && item.companies.map((company: any, i: number) => (
                          <Link to={`/companies/${company.id}`} className={i > 0 ? 'ml-2' : ''} key={`company${i}`}>{company.name_jp}</Link>
                        ))}
                      </td>
                    );
                  },
                  tags: (item: any) => {
                    return <TagLabel item={item} />
                  },
                  actions: (item: any) => {
                    return (
                      <td>
                        <CLink
                          to={`/manage/plants/${item.id}`}
                        >
                          <FiEdit className="text-lg" />
                        </CLink>
                      </td>
                    )
                  }
                }}
                noItemsView={{'noItems': '該当のプラントはありません'}}
              />
            </>
          }
          {
            !!displayPlants && displayLimit < displayPlants?.length &&
            <CButton type="button" color="secondary" onClick={() => {
              getNextCompanies()
            }}>次を読み込む</CButton>
          }
          {loadingPlant && <LoadingSpinner className="py-4" />}
        </CCardBody>
      </CCard>
    </>
  )
}

export default PlantList;
