import * as yup from 'yup'
import { CreateCompanyDto, UpdateCompanyDto } from "../../models/company";

export const CreateCompanySchema = yup.object().shape<CreateCompanyDto>({
  name_jp: yup.string().required().max(256),
  plant_ids: yup.string().matches(/^(\d+(,\d+)*)?$/),
})

export const UpdateCompanySchema = yup.object().shape<UpdateCompanyDto>({
  name_jp: yup.string().required().max(256),
  plant_ids: yup.string().matches(/^(\d+(,\d+)*)?$/),
})