import React from "react";

import { Redirect, useParams } from "react-router-dom";

import { CCard, CCardBody, CCardHeader, CNav, CNavItem, CNavLink, } from "@coreui/react";

import PlantDetailValue from "./PlantDetailValue";
import PlantDetailChart from "./PlantDetailChart";
import PlantDetailSetting from "./PlantDetailSetting";
import PlantDetailSystemDialog from "./PlantDetailSystemDialog";
import PlantDetailReport from "./PlantDetailReport";
import { useGetThresholdQuery } from "../../api";
import PlantDetailAlertList from "./alerts/PlantDetailAlertList";
import PlantDetailAlertDetail from "./alerts/PlantDetailAlertDetail";
import PlantDetailAlertCreate from "./alerts/PlantDetailAlertCreate";
import { useAppContext } from '../../contexts/app';
import { UserModelUtil } from '../../utils/UserModel';

const tabs = [
  "values",
  "chart",
  "setting",
  "systemDiagram",
  "report",
  "alerts",
];

const PlantDetailPage = () => {
  const { userSession } = useAppContext();
  const { plantId, tab, alertId } =
    useParams<{ plantId: string; tab: string; alertId: string }>();

  const thresholdQuery = useGetThresholdQuery({
    fetchPolicy: "no-cache",
    variables: {
      plantId,
    },
  });

  // /plants/:plantId → デフォルトのタブへリダイレクト
  const activeTab = tabs.indexOf(tab || "");
  if (activeTab === -1) {
    return <Redirect to={"/plants/" + plantId + "/chart"} />;
  }

  const threshold = thresholdQuery.data?.getThreshold;

  return (
    <>
      <CCard>
        <CCardHeader>{threshold?.plantNameJp}</CCardHeader>
        <CCardBody>
          <CNav variant="pills">
            <CNavItem>
              <CNavLink to={`/plants/${plantId}/chart`}>推移</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink to={`/plants/${plantId}/values`}>
                <i className="cil-list"></i>
                <span>値一覧</span>
              </CNavLink>
            </CNavItem>
            {userSession && !UserModelUtil.isGuestUserR(userSession.user) &&
              <>
                <CNavItem>
                  <CNavLink to={`/plants/${plantId}/setting`}>閾値設定</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink to={`/plants/${plantId}/systemDiagram`}>
                    系統図
                  </CNavLink>
                </CNavItem>
              </>
            }
            <CNavItem>
              <CNavLink to={`/plants/${plantId}/report`}>運転記録</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink to={`/plants/${plantId}/alerts`}>
                警報発令対応報告書
              </CNavLink>
            </CNavItem>
          </CNav>
          {tab === "values" && <PlantDetailValue threshold={threshold} />}
          {tab === "chart" && threshold && (
            <PlantDetailChart threshold={threshold} />
          )}
          {tab === "setting" && (
            <PlantDetailSetting
              threshold={threshold}
              onThresholdUpdated={thresholdQuery.refetch}
            />
          )}
          {tab === "systemDiagram" && <PlantDetailSystemDialog />}
          {tab === "report" && <PlantDetailReport threshold={threshold} />}
          {tab === "alerts" && !alertId && <PlantDetailAlertList />}
          {tab === "alerts" && alertId && alertId !== "new" && (
            <PlantDetailAlertDetail />
          )}
          {tab === "alerts" && alertId && alertId === "new" && (
            <PlantDetailAlertCreate />
          )}
        </CCardBody>
      </CCard>
    </>
  );
};

export default PlantDetailPage;
