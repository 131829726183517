import React, { useEffect, useState } from "react"
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams, useHistory, Link } from "react-router-dom"

import {
  CCard, CCardHeader, CCardBody,
  CForm, CFormGroup, CLabel, CListGroup, CListGroupItem
} from '@coreui/react'
import Select from 'react-select'

import { useUpdateCompanyMutation, useDeleteCompanyMutation, useGetCompanyDataOptionsQuery, PlantModel } from '../../../api'
import { RootState } from '../../../store'
import { useForm, Controller } from "react-hook-form"
import { useToasts } from "react-toast-notifications"
import { UpdateCompanyDto } from "../../../models/company"
import { UpdateCompanySchema } from '../../../formSchemas/manage/company'
import LoadingButton from "../../common/LoadingButton"
import ConfirmModal from '../../common/modals/ConfirmModal'
import CIcon from "@coreui/icons-react"

const CompanyDetail: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [companyPlants, setComapnyPlants] = useState<PlantModel[]>([])
  const { company_id } = useParams<{ company_id: string }>()
  const { register, handleSubmit, reset, errors, control, getValues } = useForm<UpdateCompanyDto>({
    validationSchema: UpdateCompanySchema,
  })
  const { addToast } = useToasts()
  const [updateCompany] = useUpdateCompanyMutation()
  const [deleteCompany] = useDeleteCompanyMutation()

  let company = useSelector((state: RootState) => state.companiesState.companies?.find(x => x.id === Number(company_id)))
  
  const getCompanyDataOptionsQuery = useGetCompanyDataOptionsQuery({
    fetchPolicy: 'no-cache'
  })
  const companyDataOptions = getCompanyDataOptionsQuery.data?.getCompanyDataOptions

  useEffect(() => {
    if (company) {
      const name_jp = company.name_jp
      const plant_ids = company.plant_ids!
      reset({
        name_jp,
        plant_ids,
      })
      if (company.plants) {
        setComapnyPlants(company.plants)
        dispatch({ type: 'set', plantsState: {
          plants: company.plants
        }})
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  if (!company) {
    return <Redirect to='/companies' />
  }

  const onSubmit = async (data: UpdateCompanyDto) => {
    setIsLoading(true)
    try {
      const res = await updateCompany({
        variables: {
          id: company_id,
          input: data
        }
      })
      const plants = res.data?.updateCompany.plants as PlantModel[]
      setComapnyPlants(plants)
      dispatch({ type: 'set', plantsState: {
        plants: plants
      }})
      addToast('更新しました。', { appearance: 'success' })
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '更新できませんでした', { appearance: 'error' })
    }
    setIsLoading(false)
  }

  const onSubmitDelete = async () => {
    setShowModal(false)
    setIsLoading(true)
    try {
      await deleteCompany({
        variables: {
          id: company_id
        }
      })
      addToast('削除しました。', { appearance: 'success' })
      history.push('/companies')
    } catch (e: any) {
      addToast('削除できませんでした', { appearance: 'error' })
      setIsLoading(false)
    }
  }

  return (
    <>
      <Link to="/companies"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>組織詳細</CCardHeader>

        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="name_jp">会社名</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="会社名"
                className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="plant_ids">所属プラントID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={companyDataOptions?.plant_ids}
                    isMulti
                    defaultValue={companyDataOptions?.plant_ids.filter(option => getValues().plant_ids?.split(',').includes(option.value))}
                    onChange={(options: any) => {
                      const value = options ? options.map((option: any) => option.value).join(',') : ''
                      props.onChange(value)
                    }}
                  />
                )}
                name="plant_ids"
                control={control}
              />
            </CFormGroup>
            <CFormGroup className="mt-4">
              <LoadingButton type="submit" color="primary" loading={isLoading}>更新</LoadingButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>

      <CCard className="mt-4">
        <CCardHeader>設定済みのプラント一覧</CCardHeader>
        <CCardBody>
          {companyPlants.length && 
            <CListGroup className="mb-3">
              {companyPlants.map(plant => {
                return <CListGroupItem key={plant.id} href={`#/manage/plants/${plant.id}`}>
                  {plant.name_jp}
                </CListGroupItem>
              })}
            </CListGroup>
          }
          {companyPlants.length === 0 &&
            <span>プラントは存在しません。</span>
          }
        </CCardBody>
      </CCard>

      <CCard className="mt-4">
        <CCardHeader>その他の操作</CCardHeader>
        <CCardBody>
          <CForm>
            <CFormGroup>
              <CLabel className="block">組織を削除する</CLabel>
              <div className="mt-2">
                <LoadingButton type="button" color="danger" loading={isLoading} onClick={() => setShowModal(true)}>削除</LoadingButton>
                <ConfirmModal 
                  title={`本当に削除しますか？`}
                  isOpen={showModal}
                  handleCloseModal={() => setShowModal(false)}
                  handleSubmit={() => onSubmitDelete()}
                />
              </div>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default CompanyDetail
