import _ from "lodash";

export const cmiName = (customMeasurementItem: any): string => {
  return (
    customMeasurementItem?.name_en || customMeasurementItem?.name_jp || "-"
  );
};

/**
 * 指定のround_posで小数点四捨五入
 */
export const getRoundedValue = (
  value: number | null | undefined,
  roundPos: number | null | undefined
): number | null => {
  if (value === null || value === undefined) {
    return null;
  }

  if (roundPos === null || roundPos === undefined) {
    return _.round(value, 3);
  }

  return _.round(value, roundPos);
};
