import { ThresholdModel, ThresholdSpotModel } from "../api";

const util = require("@coreui/utils/src");
const { getStyle } = util;

export interface ThresholdType {
  type1: ThresholdType1;
  type2: ThresholdType2;
}

export type ThresholdType1 = "notice" | "warning" | "stop";
export type ThresholdType2 = "min" | "max";

export const thresholdTypes: ThresholdType[] = [
  { type1: "notice", type2: "min" },
  { type1: "notice", type2: "max" },
  { type1: "warning", type2: "min" },
  { type1: "warning", type2: "max" },
  { type1: "stop", type2: "min" },
  { type1: "stop", type2: "max" },
];

const brandWarning = "#ffa75d";
const brandDanger = "#f86c6b";
const brandStop = "#5e6973";

interface BorderSettings extends ThresholdType {
  label: string;
  color: string;
}

export const borderSettings: BorderSettings[] = [
  { type1: "notice", type2: "min", label: "要観察(下限)", color: brandWarning },
  { type1: "notice", type2: "max", label: "要観察(上限)", color: brandWarning },
  { type1: "warning", type2: "min", label: "警告(下限)", color: brandDanger },
  { type1: "warning", type2: "max", label: "警告(上限)", color: brandDanger },
  { type1: "stop", type2: "min", label: "通信不通(下限)", color: brandStop },
  { type1: "stop", type2: "max", label: "通信不通(上限)", color: brandStop },
];

export function getThresholdSpot(
  threshold: ThresholdModel | null,
  customMeasurementItemId: number,
  spotId: number
): ThresholdSpotModel | undefined {
  if (!threshold) return;
  const thresholdCustomMeasurementItem =
    threshold.thresholdCustomMeasurementItems.find(
      (tCMI) => tCMI.customMeasurementItemId === customMeasurementItemId
    );
  if (!thresholdCustomMeasurementItem) {
    threshold.thresholdCustomMeasurementItems.push({
      customMeasurementItemId: customMeasurementItemId,
      thresholdSpots: [{ spotId }],
    });
    return threshold.thresholdCustomMeasurementItems
      .find((tCMI) => tCMI.customMeasurementItemId === customMeasurementItemId)!
      .thresholdSpots.find((tS) => tS.spotId === spotId);
  }
  const thresholdSpot = thresholdCustomMeasurementItem.thresholdSpots.find(
    (tS) => tS.spotId === spotId
  );
  if (!thresholdSpot) {
    thresholdCustomMeasurementItem.thresholdSpots.push({ spotId });
    return thresholdCustomMeasurementItem.thresholdSpots.find(
      (tS) => tS.spotId === spotId
    );
  }
  return thresholdSpot;
}

export function getThresholdValue(
  threshold: ThresholdModel | null,
  customMeasurementItemId: number,
  spotId: number,
  thresholdType: ThresholdType
): number | undefined {
  if (!threshold) return;
  const ThresholdSpot = getThresholdSpot(
    threshold,
    customMeasurementItemId,
    spotId
  );
  if (!ThresholdSpot || !ThresholdSpot[thresholdType.type1]) return;
  const value = ThresholdSpot[thresholdType.type1]![thresholdType.type2];
  return value as number | undefined;
}
