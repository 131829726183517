import React from "react"
import classNames from 'classnames'
import { useForm, Controller } from "react-hook-form"

import {
  CCard, CCardHeader, CCardBody,
  CLabel, CFormGroup, CButton, CForm
} from '@coreui/react'
import Select from 'react-select'

import { useCreateCustomMeasurementItemMutation, useGetCustomMeasurementItemDataOptionsQuery } from '../../../api'
import { useToasts } from "react-toast-notifications"
import { useHistory, Link } from "react-router-dom"
import { CreateCustomMeasurementItemDto } from "../../../models/customMeasurementItem"
import { CreateCustomMeasurementItemSchema } from '../../../formSchemas/manage/customMeasurementItem'
import CIcon from "@coreui/icons-react"
import MultipleTagsInput from "../../common/forms/MultipleTagsInput";

const CustomMeasurementItemCreate: React.FC = () => {
  const { register, handleSubmit, errors, control, getValues } = useForm<CreateCustomMeasurementItemDto>({
    validationSchema: CreateCustomMeasurementItemSchema,
  })
  const { addToast } = useToasts()
  const [createCustomMeasurementItem] = useCreateCustomMeasurementItemMutation()

  const history = useHistory()

  const getCustomMeasurementItemDataOptionsQuery = useGetCustomMeasurementItemDataOptionsQuery({
    fetchPolicy: 'no-cache'
  })
  const customMeasurementItemDataOptions = getCustomMeasurementItemDataOptionsQuery.data?.getCustomMeasurementItemDataOptions

  const onSubmit = async (data: CreateCustomMeasurementItemDto) => {
    try {
      await createCustomMeasurementItem({
        variables: { input: data }
      })
      addToast('追加しました。', { appearance: 'success' })
      history.push('/manage/customMeasurementItems')
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '追加できませんでした', { appearance: 'error' })
    }
  }

  return (
    <>
      <Link to="/manage/customMeasurementItems"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>センサー新規登録</CCardHeader>
        
        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="plant_id">プラント</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={customMeasurementItemDataOptions?.plant_id}
                    defaultValue={customMeasurementItemDataOptions?.plant_id.find(option => option.value === getValues().plant_id)}
                    onChange={(option: any) => props.onChange(option.value)}
                    className={classNames("select-form", { 'is-invalid': errors.plant_id, })}
                  />
                )}
                name="plant_id"
                control={control}
              />
              {
                errors.plant_id?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は選択必須です
                </div>
              }
            </CFormGroup>
            
            <CFormGroup>
              <CLabel htmlFor="name_en">センサー（英語表記）</CLabel>
              <input name="name_en" ref={register} autoComplete="name_en" placeholder="センサー（英語表記）"
                className={classNames("form-control", { 'is-invalid': errors.name_en, })}
              />
              {
                errors.name_en?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_en?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            
            <CFormGroup>
              <CLabel htmlFor="name_jp">センサー（日本語表記）</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="センサー（日本語表記）"
                className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>

            <CFormGroup>
              <CLabel htmlFor="unit">単位</CLabel>
              <input name="unit" ref={register} autoComplete="unit" placeholder="単位"
                className={classNames("form-control", { 'is-invalid': errors.unit, })}
              />
              {
                errors.unit?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>

            <CFormGroup>
              <CLabel htmlFor="round_pos">小数点桁数</CLabel>
              <input type="number" name="round_pos" ref={register} autoComplete="round_pos" placeholder="0" defaultValue="0"
                className={classNames("form-control", { 'is-invalid': errors.round_pos, })}
              />
            </CFormGroup>

            <CFormGroup>
              <CLabel htmlFor="tags">タグ</CLabel>
              <Controller
                  as={(props: any) => (
                      <MultipleTagsInput type="text" max={5} onChange={props.onChange} />
                  )}
                  name="tags"
                  control={control}
              />
            </CFormGroup>

            <CFormGroup className="mt-4">
              <CButton type="submit" color="primary">追加</CButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default CustomMeasurementItemCreate
