import _ from 'lodash'
import { AddToast } from 'react-toast-notifications'

export function watchErrorWithToast(addToast: AddToast, err: any, message: string, state: [boolean, React.Dispatch<React.SetStateAction<boolean>>]) {
  if (err && !state[0]) {
    state[1](true)
    const errorType = _.get(err, 'graphQLErrors[0].extensions.exception.response.error')
    if (!message) {
      if (errorType === 'not_found') {
        message = '存在しないプラントです'
      }
      if (errorType === 'unauthorized') {
        message = 'プラントへのアクセス権限がありません'
      }
    }
    setTimeout(() => {
      addToast(message+(err && process.env.NODE_ENV === 'development' ? ' err: ' + err.message : ''), {
        appearance: 'error',
        autoDismiss: false
      })
    })
  }
}