import React, { useEffect } from "react"
import { RouteProps } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'

import { useAppContext } from '../../contexts/app'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CLabel,
  CRow
} from '@coreui/react'

import queryString from 'query-string'
import { useToasts } from "react-toast-notifications"
import logoDark from '../../assets/images/logo_dark.svg';

const getEmailAndCode = (search: string) => {
  const queryParams = queryString.parse(search)
  const email = queryParams.email as string
  const code = queryParams.code as string
  return {
    email,
    code,
  }
}

const ConfirmPasswordPage : React.FC<RouteProps>  = (props: RouteProps) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const location = useLocation()
  const { confirmPassword } = useAppContext()
  const { register, handleSubmit, reset } = useForm()
  const { t } = useTranslation()

  const emailAndCode = getEmailAndCode(location.search)

  useEffect(() => {
    reset(emailAndCode)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data: any) => {
    try {
      await confirmPassword(data.email, data.code, data.password)
      history.push('/login')
      addToast('パスワードを変更しました', { appearance: 'success' })
    } catch (e: any) {
      const defaultValue = e.message || 'パスワードが変更できませんでした'
      // 辞書ファイルに存在しなかった場合はdefaultValueを利用
      const translatedMessage = t(`aws.cognito.error.${e.code}`, { defaultValue })
      addToast(translatedMessage, { appearance: 'error' })
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg="6" md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <h1 className="mb-5 text-center">
                    <img src={logoDark} alt="WOTA PLANT" height="24" width="233" style={{maxWidth: '100%'}} />
                  </h1>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="mb-5 text-center text-primary">パスワードの<span className="d-inline-block">再設定</span></h4>
                    {
                      emailAndCode.email &&
                      <div className="alert alert-info">
                        {emailAndCode.email} に検証コードが記載されたメールを送信しました。
                        <br />
                        メール内に記載の検証コードを入力して新しいパスワードを設定してください。
                      </div>
                    }
                    
                    <CFormGroup>
                      <CLabel>メールアドレス</CLabel>
                      <input
                        name="email" ref={register({ required: true })} className="form-control" />
                    </CFormGroup>
                    <CFormGroup>
                      <CLabel>検証コード</CLabel>
                      <input
                        name="code" ref={register({ required: true })} className="form-control" />
                    </CFormGroup>
                    <CFormGroup>
                      <CLabel>新しいパスワード</CLabel>
                      <input
                        type="password"
                        name="password" ref={register({ required: true })} className="form-control" />
                    </CFormGroup>
                    <CRow>
                      <CCol xs="12">
                        <CButton type="submit" color="primary" className="p-2 mb-3 btn-block">パスワードを再設定する</CButton>
                      </CCol>
                      <CCol xs="12" className="text-right">
                        <CButton color="link" className="px-0 btn-block" onClick={() => {
                          history.push('/login')
                        }}>ログインへ戻る</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ConfirmPasswordPage
