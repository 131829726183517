import React from "react";

type Props = {
  item: any;
};

const TagLabel: React.FC<Props> = ({ item }) => {
  if (!item.tags) {
    return <td></td>
  }
  const buttons = []
  for(const key in item.tags) {
    buttons.push(<button type="button" className="btn btn-secondary">{key}: {item.tags[key]}</button>)
  }
  return (
      <td>
        {buttons.map((button)=>{
          return button
        })}
      </td>
  )
};

export default TagLabel;
