import { UserDto, } from "../models/user"

export class UserModelUtil {
  static isAdmin(user: UserDto){
    return user.role === 'admin'
  }

  static isOrganizationAdmin(user: UserDto){
    return user.role === 'organization_admin'
  }

  static isDefault(user: UserDto){
    return user.role === 'default'
  }

  static isGuestUser(user: UserDto){
    return user.role === 'guest_user'
  }

  static isGuestUserR(user: UserDto){
    return user.role === 'guest_user_r'
  }

  static isOverOrganizationAdmin(user: UserDto){
    return this.isAdmin(user) || this.isOrganizationAdmin(user)
  }
}
