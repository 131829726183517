import React from "react";
import dayjs from "dayjs";
import { getRoundedValue } from "../../utils/CMIData";
import { SensorChartTimeUnit } from './SensorChart';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import FixedDataTable from "../parts/FixedDataTable";

export type DataTableItem = {
  label: string;
  time: number;
  value: number | null;
  roundPos?: number | null;
};

interface Props {
  items: DataTableItem[];
  startTime: number;
  endTime: number;
  dateFormat: string;
  timeUnit: SensorChartTimeUnit;
}

const SensorTable: React.FC<Props> = (props) => {
  const items = getTableItems(
    props.items,
    props.startTime,
    props.endTime,
    props.dateFormat,
    props.timeUnit
  );
  return <FixedDataTable items={items} />
};

type getLabelsProps = {
  items: DataTableItem[];
};

const getLabels = (items: DataTableItem[]) => {
  const labelMap: any = {};
  items.forEach((item) => {
    labelMap[item.label] = 1;
  });
  const labels = Object.keys(labelMap);
  return labels;
};

const getTableItems = (
  items: DataTableItem[],
  startTime: number,
  endTime: number,
  dateFormat: string,
  timeUnit: SensorChartTimeUnit
) => {
  let time = startTime;
  const tableItems: any[] = [];
  const labels = getLabels(items);
  let defaultAttributes: any = {};
  labels.forEach((label) => {
    defaultAttributes[label] = "";
  });

  while (time <= endTime) {

    let stepSecond: number;
    let roundedTime: number;
    switch (timeUnit) {
      case 'hour':
        stepSecond = 60 * 60; // 60分
        roundedTime = dayjs.unix(time).tz().startOf('hour').unix()
        break
      case 'day':
        stepSecond = 60 * 60 * 24; // 1日
        roundedTime = dayjs.unix(time).tz().startOf('day').unix()
        break
      case 'month':
        const currentStartOfMonth = dayjs.unix(time).startOf('month').unix()
        const nextStartOfMonth = dayjs.unix(time).add(1, 'month').startOf('month').unix()
        stepSecond = nextStartOfMonth - currentStartOfMonth; // 1ヶ月
        roundedTime = dayjs.unix(time).tz().startOf('month').unix()
        break
      case 'minute':
      default:
        stepSecond = 60; // 1分
        roundedTime = dayjs.unix(time).tz().startOf('minutes').unix()
        break
    }
    const nextTime = dayjs.unix(time).add(stepSecond, 'second').unix();

    // stepSecondの単位の時刻で記録する
    const tickItems = items.filter((item) => {
      if (roundedTime <= item.time && item.time < roundedTime + stepSecond) {
        return true;
      }
      return false;
    });

    if (!tickItems) {
      continue;
    }

    let row: any = {
      "日時　　　　　　　　": dayjs(time * 1000).format(dateFormat),
      ...defaultAttributes,
    };
    tickItems.forEach((tickItem: DataTableItem) => {
      let value = getRoundedValue(tickItem.value, tickItem.roundPos);
      if (value === null) {
        row[tickItem.label] = "";
      } else {
        row[tickItem.label] = value;
      }
    });

    tableItems.push(row);
    time = nextTime
  }
  return tableItems;
};

export const makeCsv = (
  items: DataTableItem[],
  startTime: number,
  endTime: number,
  dateFormat: string,
  timeUnit: SensorChartTimeUnit
) => {
  const tmp: any[] = [];
  const tableItems = getTableItems(
    items,
    startTime,
    endTime,
    dateFormat,
    timeUnit
  );
  // Title 作成
  tableItems.forEach((data) => {
    Object.keys(data).forEach((key) => {
      if (tmp.indexOf(key) === -1) tmp.push(key);
    });
  });
  // データ結合
  const lineArray = [tmp.join(",")].concat(
    tableItems.map((data) =>
      tmp
        .map((key: string | null) => {
          let valueString = "";

          if (key && typeof data[key] === "number") {
            valueString = data[key];
          } else if (key && typeof data[key] === "string") {
            valueString = `"${data[key]}"`;
          } else if (key && data[key] === null) {
            valueString = "";
          } else if (key) {
            valueString = `"${JSON.stringify(data[key])}"`;
          }
          return valueString;
        })
        .join(",")
    )
  );

  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  let blob = new Blob([bom, lineArray.join("\r\n")], { type: "text/csv" });

  // 作成した CSV のダウンロード処理
  const fileName = "data.csv";
  const ele = document.createElement("a");
  let url = "";

  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("msie") !== -1 || userAgent.indexOf("trident") !== -1) {
    // IE
    /* @ts-ignore */
    window.navigator.msSaveOrOpenBlob(blob, fileName);
    return;
  } else if (
    userAgent.indexOf("edge") !== -1 ||
    userAgent.indexOf("chrome") !== -1
  ) {
    // edge, chrome
    url = window.webkitURL.createObjectURL(blob);
  } else if (userAgent.indexOf("safari") !== -1) {
    // safari
    blob = new Blob([bom, lineArray.join("\r\n")], {
      type: "application/octet-stream",
    });
    url = URL.createObjectURL(blob);
    ele.setAttribute("target", "_blank");
  } else {
    // firefox, others
    url = window.webkitURL.createObjectURL(blob);
  }

  ele.setAttribute("href", url);
  ele.setAttribute("download", fileName);
  ele.style.visibility = "hidden";
  document.body.appendChild(ele);
  ele.click();
  document.body.removeChild(ele);
};

export default SensorTable;
