import React, {useState} from "react"

import {
  CForm, CFormGroup, CLabel
} from '@coreui/react'
import { useToasts } from "react-toast-notifications"
import LoadingButton from "../../common/LoadingButton"
import AppModal, { useModal } from '../../common/modals/AppModal'
import {useForm} from "react-hook-form";
import {UpdateUserEmailDto} from "../../../models/user";
import {UpdateUserEmailSchema} from "../../../formSchemas/manage/user";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../contexts/app";

type Props = {
    updateEmailModal: any;
}

const UserEmailChangeModal = ({updateEmailModal}: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { updateUserEmail } = useAppContext()

  // Eメール更新フォーム
  const updateEmailForm = useForm<UpdateUserEmailDto>({
      validationSchema: UpdateUserEmailSchema
  });
  const [isLoadingEmailChange, setIsLoadingEmailChange] = useState(false);

  return (
    <>
      <AppModal title="Eメールアドレスの変更" {...updateEmailModal} footer={false}>
        <CForm onSubmit={updateEmailForm.handleSubmit((values: { email: any; emailConfirm: any; password: any }) => {

          if (values.email != values.emailConfirm) {
            addToast('確認用Eメールアドレスが一致しません', { appearance: 'error' })
            return;
          }

          const { email, password } = values;
          setIsLoadingEmailChange(true);
          updateUserEmail(email, password)
              .then(() => {
                addToast('メールアドレスを更新しました', { appearance: 'success' })
                updateEmailModal.onClose();
              })
              .catch((err: { code: any; message: any }) => {
                const translatedMessage = t(`aws.cognito.error.${err.code}`, {
                  defaultValue: err.message || 'メールアドレスの更新ができませんでした',
                });
                addToast(translatedMessage, { appearance: "error" });
              })
              .finally(() => {
                setIsLoadingEmailChange(false);
              });
        })}>
          <CFormGroup>
            <CLabel>変更後のEメールアドレス</CLabel>
            <input ref={updateEmailForm.register} name="email" type="email" className="form-control" />
          </CFormGroup>
          <CFormGroup>
            <CLabel>変更後のEメールアドレス（確認用)</CLabel>
            <input ref={updateEmailForm.register} name="emailConfirm" type="email" className="form-control" />
          </CFormGroup>
          <CFormGroup>
            <CLabel>現在のパスワード</CLabel>
            <input ref={updateEmailForm.register} name="password" type="password" className="form-control" />
          </CFormGroup>
          <CFormGroup className="mt-4">
            <LoadingButton type="submit" color="primary" loading={isLoadingEmailChange}>Eメールアドレスを変更する</LoadingButton>
          </CFormGroup>
        </CForm>

      </AppModal>
    </>
  )
}

export default UserEmailChangeModal
