import React from 'react'
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import { GraphqlProvider } from './graphql'
import { ToastProvider } from 'react-toast-notifications'


import PrivateRoute from './components/common/PrivateRoute'
import DefaultLayout from './components/common/DefaultLayout'
import Login from './components/common/Login'
import ForgotPassword from './components/common/ForgotPassword'
import ConfirmPassword from './components/common/ConfirmPassword'

import Home from './components/Home'
import TheLayout from './layout/containers/TheLayout'

import PlantDetail from './components/plants/PlantDetail'

import ManageUserList from './components/manage/users/UserList'
import ManageUserDetail from './components/manage/users/UserDetail'
import ManageUserCreate from './components/manage/users/UserCreate'
import ManageCompanyList from './components/manage/companies/CompanyList'
import ManageCompanyDetail from './components/manage/companies/CompanyDetail'
import ManageCompanyCreate from './components/manage/companies/CompanyCreate'
import ManagePlantList from './components/manage/plants/PlantList'
import ManagePlantDetail from './components/manage/plants/PlantDetail'
import ManagePlantCreate from './components/manage/plants/PlantCreate'
import ManageCustomMeasurementItemList from './components/manage/customMeasurementItems/CustomMeasurementItemList'
import ManageCustomMeasurementItemDetail from './components/manage/customMeasurementItems/CustomMeasurementItemDetail'
import ManageCustomMeasurementItemCreate from './components/manage/customMeasurementItems/CustomMeasurementItemCreate'
import AdminRoute from './components/common/AdminRoute'
import SpotList from "./components/manage/spots/SpotList";
import SpotCreate from "./components/manage/spots/SpotCreate";
import SpotDetail from "./components/manage/spots/SpotDetail";
import { TrackingView } from './components/tracking';

const App = () => {
  return (
        <GraphqlProvider>
          <ToastProvider
            autoDismiss
          >
            <Router>
              <TrackingView>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot_password" component={ForgotPassword} />
                <Route exact path="/confirm_password" component={ConfirmPassword} />
                <Route path="/layout" component={TheLayout} />
                <PrivateRoute fallbackPath="/login">
                  <DefaultLayout>
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/plants/:plantId" component={PlantDetail} />
                      <Route exact path="/plants/:plantId/:tab" component={PlantDetail} />
                      <Route exact path="/plants/:plantId/:tab/:alertId" component={PlantDetail} />
                      <Route exact path="/profile" component={ManageUserDetail} />
                      <AdminRoute path="/users" fallbackPath="/">
                        <Switch>
                          <Route exact path="/users" component={ManageUserList} />
                          <Route exact path="/users/new" component={ManageUserCreate} />
                          <Route exact path="/users/:user_id" component={ManageUserDetail} />
                        </Switch>
                      </AdminRoute>
                      <AdminRoute path="/companies" fallbackPath="/">
                        <Switch>
                          <Route exact path="/companies" component={ManageCompanyList} />
                          <Route exact path="/companies/new" component={ManageCompanyCreate} />
                          <Route exact path="/companies/:company_id" component={ManageCompanyDetail} />
                        </Switch>
                      </AdminRoute>
                      <AdminRoute path="/manage/plants" fallbackPath="/">
                        <Switch>
                          <Route exact path="/manage/plants" component={ManagePlantList} />
                          <Route exact path="/manage/plants/new" component={ManagePlantCreate} />
                          <Route exact path="/manage/plants/:plant_id" component={ManagePlantDetail} />
                        </Switch>
                      </AdminRoute>
                      <AdminRoute path="/manage/customMeasurementItems" fallbackPath="/">
                        <Switch>
                          <Route exact path="/manage/customMeasurementItems" component={ManageCustomMeasurementItemList} />
                          <Route exact path="/manage/customMeasurementItems/new" component={ManageCustomMeasurementItemCreate} />
                          <Route exact path="/manage/customMeasurementItems/:custom_measurement_item_id" component={ManageCustomMeasurementItemDetail} />
                        </Switch>
                      </AdminRoute>
                      <AdminRoute path="/manage/spot" fallbackPath="/">
                        <Switch>
                          <Route exact path="/manage/spot" component={SpotList} />
                          <Route exact path="/manage/spot/new" component={SpotCreate} />
                          <Route exact path="/manage/spot/:spot_id" component={SpotDetail} />
                        </Switch>
                      </AdminRoute>
                    </Switch>
                  </DefaultLayout>
                </PrivateRoute>
              </Switch>
              </TrackingView>
            </Router>
          </ToastProvider>
        </GraphqlProvider>
  )
}

export default App
