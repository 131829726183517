import * as yup from 'yup'
import { CreateCustomMeasurementItemDto, UpdateCustomMeasurementItemDto } from "../../models/customMeasurementItem";

export const CreateCustomMeasurementItemSchema = yup.object().shape<CreateCustomMeasurementItemDto>({
  plant_id: yup.string().required().matches(/^(\d*)?$/),
  name_en: yup.string().max(256),
  name_jp: yup.string().required().max(256),
  unit: yup.string().max(256),
  round_pos: yup.number(),
})

export const UpdateCustomMeasurementItemSchema = yup.object().shape<UpdateCustomMeasurementItemDto>({
  plant_id: yup.string().required().matches(/^(\d*)?$/),
  name_en: yup.string().max(256),
  name_jp: yup.string().required().max(256),
  unit: yup.string().max(256),
  round_pos: yup.number(),
})