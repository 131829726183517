import React, { useContext, useEffect, useState } from "react"
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams, useHistory, Link } from "react-router-dom"

import {
  CCard, CCardHeader, CCardBody,
  CForm, CFormGroup, CLabel, CListGroup, CListGroupItem
} from '@coreui/react'
import Select from 'react-select'

import { useUpdatePlantMutation, useDeletePlantMutation, useGetPlantDataOptionsQuery, useSendMailToAssignerMutation } from '../../../api'
import { RootState } from '../../../store'
import { useForm, Controller } from "react-hook-form"
import { useToasts } from "react-toast-notifications"
import { UpdatePlantDto } from "../../../models/plant"
import LoadingButton from "../../common/LoadingButton"
import ConfirmModal from '../../common/modals/ConfirmModal'
import CIcon from "@coreui/icons-react"
import MultipleInput from '../../common/forms/MultipleInput'
import MultipleTagsInput from "../../common/forms/MultipleTagsInput";
import { useAppContext } from '../../../contexts/app'
import { UpdatePlantSchema } from '../../../formSchemas/manage/plant'

const PlantDetail: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const { plant_id } = useParams<{ plant_id: string }>()
  const { register, handleSubmit, reset, errors, control, getValues } = useForm<UpdatePlantDto>({
    validationSchema: UpdatePlantSchema,
  })
  const { addToast } = useToasts()
  const [updatePlant] = useUpdatePlantMutation()
  const [deletePlant] = useDeletePlantMutation()
  const [sendMailToAssigner] = useSendMailToAssignerMutation();
  const { userSession } = useAppContext();

  let plant = useSelector((state: RootState) => state.plantsState.plants?.find(x => x.id === Number(plant_id)))
  
  const getPlantDataOptionsQuery = useGetPlantDataOptionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: plant_id
    },
    skip: !plant
  })
  const plantDataOptions = getPlantDataOptionsQuery.data?.getPlantDataOptions

  useEffect(() => {
    if (plant) {
      const name_jp = plant.name_jp
      const emails = plant.emails || []
      const custom_measurement_item_ids = plant.custom_measurement_item_ids!
      const tags = plant.tags || {}
      reset({
        name_jp,
        emails,
        custom_measurement_item_ids,
        tags,
      })
      dispatch({ type: 'set', usersState: {
        users: plant.managers
      }})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  if (!plant) {
    return <Redirect to='/manage/plants' />
  }

  const onSubmit = async (data: UpdatePlantDto) => {
    setIsLoading(true)
    try {
      await updatePlant({
        variables: {
          id: plant_id,
          input: data
        }
      })
      addToast('更新しました。', { appearance: 'success' })
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '更新できませんでした', { appearance: 'error' })
    }
    setIsLoading(false)
  }

  const onSubmitDelete = async () => {
    setShowModal(false)
    setIsLoading(true)
    try {
      await deletePlant({
        variables: {
          id: plant_id
        }
      })
      addToast('削除しました。', { appearance: 'success' })
      history.push('/manage/plants')
    } catch (e: any) {
      addToast('削除できませんでした', { appearance: 'error' })
      setIsLoading(false)
    }
  }

  const onClickSendTestMail = async () => {
    setIsLoading(true);
    try {
      await sendMailToAssigner({
        variables: {
          id: plant_id,
        },
      });
      addToast("通知先メールアドレスにテストメールを送信しました。", {
        appearance: "success",
      });
    } catch {
      addToast("メールの送信エラー", { appearance: "error" });
    }
    setIsLoading(false);
  }

  return (
    <>
      <Link to="/manage/plants"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>プラント詳細</CCardHeader>

        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="name_jp">プラント名</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="プラント名"
                className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="emails">通知先のEメールアドレス</CLabel>
              <Controller
                as={(props: any) => (
                  <MultipleInput type="email" max={5} defaultValues={getValues({nest: true}).emails} onChange={props.onChange} />
                )}
                name="emails"
                control={control}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="custom_measurement_item_ids">センサーID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={plantDataOptions?.custom_measurement_item_ids}
                    isMulti
                    defaultValue={plantDataOptions?.custom_measurement_item_ids.filter(option => getValues({nest: true}).custom_measurement_item_ids?.split(',').includes(option.value))}
                    onChange={(options: any) => {
                      const value = options ? options.map((option: any) => option.value).join(',') : ''
                      props.onChange(value)
                    }}
                  />
                )}
                name="custom_measurement_item_ids"
                control={control}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="tags">タグ</CLabel>
              <Controller
                  as={(props: any) => (
                      <MultipleTagsInput type="text" max={5} tags={getValues({nest: true}).tags} onChange={props.onChange} />
                  )}
                  name="tags"
                  control={control}
              />
            </CFormGroup>
            <CFormGroup className="mt-4">
              <LoadingButton type="submit" color="primary" loading={isLoading}>更新</LoadingButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>

      <CCard className="mt-4">
        <CCardHeader>設定済みの担当者一覧</CCardHeader>
        <CCardBody>
          {plant.managers && 0 < plant.managers.length &&
            <CListGroup className="mb-3">
              {plant.managers?.map(manager => (
                <CListGroupItem key={manager.id} href={`#/users/${manager.id}`}>
                  {manager.full_name}
                </CListGroupItem>
              ))}
            </CListGroup>
          }
          {(!plant.managers || plant.managers.length === 0) &&
            <span>担当者は存在しません。</span>
          }
        </CCardBody>
      </CCard>

      <CCard className="mt-4">
        <CCardHeader>その他の操作</CCardHeader>
        <CCardBody>
          <CForm>
            <CFormGroup>
              <CLabel className="block">プラントを削除する</CLabel>
              <div className="mt-2">
                <LoadingButton type="button" color="danger" loading={isLoading} onClick={() => setShowModal(true)}>削除</LoadingButton>
                <ConfirmModal 
                  title={`本当に削除しますか？`}
                  isOpen={showModal}
                  handleCloseModal={() => setShowModal(false)}
                  handleSubmit={() => onSubmitDelete()}
                />
              </div>
            </CFormGroup>
          </CForm>
          <CForm>
            <CFormGroup>
              <CLabel className="block">テストメール送信</CLabel>
              <div className="mt-2">
                <LoadingButton type="button" color="primary" loading={isLoading} onClick={onClickSendTestMail}>送信</LoadingButton>
              </div>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default PlantDetail
