import React from 'react';

const Sidebar = () => {
  return pug`
    nav.sidebar#sidebar
      .sidebar-wrapper
        div side
  `
}

export default Sidebar;
