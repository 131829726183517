import React from "react"
import classNames from 'classnames'
import { useForm, Controller } from "react-hook-form"

import {
  CCard, CCardHeader, CCardBody,
  CLabel, CFormGroup, CButton, CForm
} from '@coreui/react'
import Select from 'react-select'

import { useCreatePlantMutation, useGetPlantDataOptionsQuery } from '../../../api'
import { useToasts } from "react-toast-notifications"
import { useHistory, Link } from "react-router-dom"
import { CreatePlantDto } from "../../../models/plant"
import { CreatePlantSchema } from '../../../formSchemas/manage/plant'
import CIcon from "@coreui/icons-react"
import MultipleInput from '../../common/forms/MultipleInput';
import MultipleTagsInput from "../../common/forms/MultipleTagsInput";

const PlantCreate: React.FC = () => {
  const { register, handleSubmit, errors, control, getValues } = useForm<CreatePlantDto>({
    validationSchema: CreatePlantSchema,
  })
  const { addToast } = useToasts()
  const [createPlant] = useCreatePlantMutation()

  const history = useHistory()
  
  const getPlantDataOptionsQuery = useGetPlantDataOptionsQuery({
    fetchPolicy: 'no-cache'
  })
  const plantDataOptions = getPlantDataOptionsQuery.data?.getPlantDataOptions

  const onSubmit = async (data: CreatePlantDto) => {
    try {
      await createPlant({
        variables: { input: data }
      })
      addToast('追加しました。', { appearance: 'success' })
      history.push('/manage/plants')
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '追加できませんでした', { appearance: 'error' })
    }
  }

  return (
    <>
      <Link to="/manage/plants"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>プラント新規登録</CCardHeader>
        
        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="name_jp">プラント名</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="プラント名"
                className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="emails">通知先のEメールアドレス</CLabel>
              <Controller
                as={(props: any) => (
                  <MultipleInput type="email" max={5} onChange={props.onChange} />
                )}
                name="emails"
                control={control}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="custom_measurement_item_ids">センサーID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={plantDataOptions?.custom_measurement_item_ids}
                    isMulti
                    defaultValue={plantDataOptions?.custom_measurement_item_ids.filter(option => getValues({nest: true}).custom_measurement_item_ids?.split(',').includes(option.value))}
                    onChange={(options: any) => {
                      const value = options ? options.map((option: any) => option.value).join(',') : ''
                      props.onChange(value)
                    }}
                  />
                )}
                name="custom_measurement_item_ids"
                control={control}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="tags">タグ</CLabel>
              <Controller
                  as={(props: any) => (
                      <MultipleTagsInput type="text" max={5} onChange={props.onChange} />
                  )}
                  name="tags"
                  control={control}
              />
            </CFormGroup>
            <CFormGroup className="mt-4">
              <CButton type="submit" color="primary">追加</CButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default PlantCreate
