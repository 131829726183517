
import React, { useState } from 'react'
import _ from 'lodash'
import { CButton, CButtonClose } from '@coreui/react';
import CIcon from '@coreui/icons-react';

interface Props {
  max?: number
  type: 'text' | 'email'
  defaultValues?: string[]
  onChange: any
}

const MultipleInput = (props: Props) => {
  const [values, setValues] = useState<string[]>(props.defaultValues || []);

  // 最大数でbuttonをdisabledにする
  const isActiveButton = props.max === undefined || values.length < props.max;
  
  return (
    <>
      {values.map((value, inputIndex) => {
        return (
          <div className="flex mb-2" key={inputIndex}>
            <input required type={props.type} className="form-control" value={value} onChange={evt => {
              const newValue = evt.target.value;
              const newValues = [...values];
              newValues[inputIndex] = newValue;
              setValues(newValues)
              props.onChange(newValues)
            }}/>
            <CButtonClose className="ml-2" type="button" onClick={() => {
              const newValues = values.filter((v, i) => i !== inputIndex)
              setValues(newValues);
              props.onChange(newValues)
            }} />
          </div>
        );
      })}
       <div className="mt-1">
        <CButton 
          type="button"
          color="secondary"
          disabled={isActiveButton === false}
          onClick={() => {
            setValues(values.concat(''))
            props.onChange(values)
          }}
        >
          <CIcon size={'sm'} name={'cil-plus'} />追加する
        </CButton>
       </div>
    </>
  );
}

export default MultipleInput