import React, { useEffect, useState } from "react"
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams, useHistory, Link } from "react-router-dom"

import {
  CCard, CCardHeader, CCardBody,
  CForm, CFormGroup, CLabel, CListGroup, CListGroupItem
} from '@coreui/react'
import Select from 'react-select'

import { useUpdateSpotMutation, useDeleteSpotMutation, PlantModel, useGetSpotDataOptionsQuery } from '../../../api'
import { RootState } from '../../../store'
import { useForm, Controller } from "react-hook-form"
import { useToasts } from "react-toast-notifications"
import { UpdateSpotDto } from "../../../models/spot"
import { UpdateSpotSchema } from '../../../formSchemas/manage/spot'
import LoadingButton from "../../common/LoadingButton"
import ConfirmModal from '../../common/modals/ConfirmModal'
import CIcon from "@coreui/icons-react"
import MultipleTagsInput from "../../common/forms/MultipleTagsInput";

const SpotDetail: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [spotPlants, setComapnyPlants] = useState<PlantModel[]>([])
  const { spot_id } = useParams<{ spot_id: string }>()
  const { register, handleSubmit, reset, errors, control, getValues } = useForm<UpdateSpotDto>({
    validationSchema: UpdateSpotSchema,
  })
  const { addToast } = useToasts()
  const [updateSpot] = useUpdateSpotMutation()
  const [deleteSpot] = useDeleteSpotMutation()

  let spot = useSelector((state: RootState) => state.spotState.spots?.find(x => x.id === Number(spot_id)))

  const getSpotDataOptionsQuery = useGetSpotDataOptionsQuery({
    fetchPolicy: 'no-cache'
  })
  const spotDataOptions = getSpotDataOptionsQuery.data?.getSpotDataOptions

  useEffect(() => {
    if (spot) {
      const name_jp = spot.name_jp
      const name_en = spot.name_en || ''
      const plant_id = spot.plant_id || undefined
      const tags = spot.tags || []
      reset({
        name_jp,
        name_en,
        plant_id,
        tags,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  if (!spot) {
    return <Redirect to='/manage/spot' />
  }

  const onSubmit = async (data: UpdateSpotDto) => {
    setIsLoading(true)
    try {
      await updateSpot({
        variables: {
          id: spot_id,
          input: data
        }
      })
      addToast('更新しました。', { appearance: 'success' })
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '更新できませんでした', { appearance: 'error' })
    }
    setIsLoading(false)
  }

  const onSubmitDelete = async () => {
    setShowModal(false)
    setIsLoading(true)
    try {
      await deleteSpot({
        variables: {
          id: spot_id
        }
      })
      addToast('削除しました。', { appearance: 'success' })
      history.push('/manage/spot')
    } catch (e: any) {
      addToast('削除できませんでした', { appearance: 'error' })
      setIsLoading(false)
    }
  }

  return (
    <>
      <Link to="/manage/spot"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>スポット詳細</CCardHeader>

        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="plant_id">プラント</CLabel>
              <Controller
                  as={(props: any) => (
                      <Select name={props.name} options={spotDataOptions?.plant_ids}
                              defaultValue={spotDataOptions?.plant_ids.find(option => option.value === getValues({nest: true}).plant_id)}
                              onChange={(option: any) => props.onChange(option.value)}
                              className={classNames("select-form", { 'is-invalid': errors.plant_id, })}
                      />
                  )}
                  name="plant_id"
                  control={control}
              />
              {
                  errors.plant_id?.type === 'required' &&
                  <div className="invalid-feedback">
                    この項目は選択必須です
                  </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="name_jp">名前（日本）</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="名前（日本）"
                className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="name_en">名前（英語）</CLabel>
              <input name="name_en" ref={register} autoComplete="name_en" placeholder="名前（英語）"
                     className={classNames("form-control", { 'is-invalid': errors.name_en, })}
              />
              {
                errors.name_en?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_en?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="tags">タグ</CLabel>
              <Controller
                  as={(props: any) => (
                      <MultipleTagsInput type="text" max={5} tags={getValues({nest: true}).tags} onChange={props.onChange} />
                  )}
                  name="tags"
                  control={control}
              />
            </CFormGroup>
            <CFormGroup className="mt-4">
              <LoadingButton type="submit" color="primary" loading={isLoading}>更新</LoadingButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>

      <CCard className="mt-4">
        <CCardHeader>その他の操作</CCardHeader>
        <CCardBody>
          <CForm>
            <CFormGroup>
              <CLabel className="block">スポットを削除する</CLabel>
              <div className="mt-2">
                <LoadingButton type="button" color="danger" loading={isLoading} onClick={() => setShowModal(true)}>削除</LoadingButton>
                <ConfirmModal 
                  title={`本当に削除しますか？`}
                  isOpen={showModal}
                  handleCloseModal={() => setShowModal(false)}
                  handleSubmit={() => onSubmitDelete()}
                />
              </div>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default SpotDetail
