import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { FiEdit } from 'react-icons/fi';

import {
  CCard, CCardHeader, CCardBody, CButton, CLink
} from '@coreui/react'

import {Link, useHistory} from 'react-router-dom'

import CIcon from '@coreui/icons-react'
import { useGetSpotsListQuery } from '../../../api'
import { RootState } from '../../../store'
import DataTable from '../../parts/DataTable'
import LoadingSpinner from '../../common/LoadingSpinner'
import TagLabel from "../../common/TagLabel";

const limit = 60

const useSpotList = () => {
  const dispatch = useDispatch()
  const { spots } = useSelector((state: RootState) => state.spotState)
  const [displayLimit, setDisplayLimit] = useState(limit)

  const { loading, data } = useGetSpotsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      options: {
      }
    }
  })
  const hasNext = !!spots && displayLimit < spots?.length

  useEffect(() => {
    if (data) {
      const spotList = data.getSpotList
      dispatch({ type: 'set', spotState: {
        spots: spotList.records,
        lastEvaluatedKey: spotList.lastEvaluatedKey
      }})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getNextSpots = () => {
    if (hasNext) {
      setDisplayLimit(displayLimit + limit)
    }
  }

  return {
    loading,
    spots,
    displayLimit,
    getNextSpots,
  }
}

const SpotList: React.FC = () => {
  const history = useHistory()
  const { loading, spots, displayLimit, getNextSpots } = useSpotList()
  const fields = [
    {key: 'id', label: 'ID'},
    {key: 'name_jp', label: '名前（日本）'},
    {key: 'name_en', label: '名前（英語）'},
    {key: 'tags', label: 'タグ'},
    {key: 'actions', label: ''},
  ];

  const tableItems = spots?.slice(0, displayLimit).map(spot => {
    return {
      id: spot.id,
      name_jp: spot.name_jp || '',
      name_en: spot.name_en || '',
      plant_id: spot.plant_id || '',
      tags: spot.tags || '',
    }
  })

  return (
    <>
      <CCard>
        <CCardHeader>
          <div className="flex items-center justify-content-between">
            <span>スポット一覧</span>
            <CButton type="button" color="primary" onClick={() => {
              history.push(`/manage/spot/new`);
            }}>
              <i className="mr-1">
                <CIcon size={'sm'} name={'cil-plus'} />
              </i>
              追加
            </CButton>
          </div>

        </CCardHeader>
        <CCardBody>
          {
            tableItems &&
            <DataTable
              fields={fields}
              items={tableItems}
              scopedSlots={{
                name_jp: (item: any) => <td><Link to={`/manage/spot/${item.id}`}>{item.name_jp}</Link></td>,
                tags: (item: any) => {
                  return <TagLabel item={item} />
                },
                actions: (item: any) => {
                  return (
                      <td>
                        <CLink
                            to={`/manage/spot/${item.id}`}
                        >
                          <FiEdit className="text-lg" />
                        </CLink>
                      </td>
                  )
                }
              }}
              noItemsView={{'noItems': '該当のスポットはありません'}}
            />
          }
          {
            !!spots && displayLimit < spots?.length &&
            <CButton type="button" color="secondary" onClick={() => {
              getNextSpots()
            }}>次を読み込む</CButton>
          }
          {loading && <LoadingSpinner className="py-4" />}
        </CCardBody>
      </CCard>
    </>
  )
}

export default SpotList;
