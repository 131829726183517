import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit } from 'react-icons/fi';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CLink,
  CRow,
  CSelect
} from '@coreui/react';

import { useHistory } from 'react-router-dom';

import CIcon from '@coreui/icons-react';
import { useAppContext } from '../../../contexts/app';
import { RootState } from '../../../store';
import DataTable from '../../parts/DataTable';
import LoadingSpinner from "../../common/LoadingSpinner";
import roleNameMap from './roleNameMap';
import { UserModelUtil } from "../../../utils/UserModel";
import { UserListItemDto } from "../../../models/user";
import { UserSession } from "../../../services/app";

const limit = 60

const roles: {key: string, name: string}[] = [
  {key: 'admin', name: roleNameMap['admin']},
  {key: 'organization_admin', name: roleNameMap['organization_admin']},
  {key: 'default', name: roleNameMap['default']},
  {key: 'guest_user', name: roleNameMap['guest_user']},
  {key: 'guest_user_r', name: roleNameMap['guest_user_r']},
]

const useUserList = () => {
  const { getUsers, userSession } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [displayLimit, setDisplayLimit] = useState(limit)
  const dispatch = useDispatch()
  const { users } = useSelector((state: RootState) => state.usersState)
  const hasNext = !!users && displayLimit < users?.length

  useEffect(() => {
    if (userSession) {
      setLoading(true)
      getUsers({})
        .then(x => {
          dispatch({ type: 'set', usersState: {
            users: x.Items,
            lastEvaluatedKey: x.LastEvaluatedKey
          }})
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession])

  const showNextUsers = () => {
    if (hasNext) {
      setDisplayLimit(displayLimit + limit)
    }
  }

  return {
    loading,
    users,
    displayLimit,
    showNextUsers,
  }
}

type Rules = {
  filterRole: string
  searchName: string
  searchEmail: string
}

function filterUsers(users: UserListItemDto[] | null, userSession: UserSession | null, rules: Rules): UserListItemDto[]{
  if (!users) return []
  let displayUsers = users
  if (rules.filterRole) {
    displayUsers = displayUsers.filter(user => user.role === rules.filterRole) || null
  }
  if (rules.searchName) {
    displayUsers = displayUsers.filter(user => user.full_name?.match(new RegExp(rules.searchName))) || null
  }
  if (rules.searchEmail) {
    displayUsers = displayUsers.filter(user => user.email.match(new RegExp(rules.searchEmail))) || null
  }
  return displayUsers
}

const UserList: React.FC = () => {
  const { userSession } = useAppContext()
  const history = useHistory()
  const { loading, users, displayLimit, showNextUsers } = useUserList()
  const [filterRole, setFilterRole] = useState('')
  const [searchName, setSearchName] = useState('')
  const [searchEmail, setSearchEMail] = useState('')
  const isOrganizationAdmin = UserModelUtil.isOrganizationAdmin(userSession!.user)

  const fields = [
    {key: 'name', label: '氏名'},
    {key: 'email', label: 'Email'},
    {key: 'plant_ids', label: '所属プラントID'},
    {key: 'role', label: '権限'},
    {key: 'actions', label: ''},
  ]

  const displayUsers = filterUsers(users, userSession, {filterRole, searchName, searchEmail})

  const tableItems = displayUsers.map(user => {
    return {
      id: user.id,
      email: user.email,
      name: user.full_name || '',
      plant_ids: user.plant_ids || '',
      role: user.role,
    }
  }).slice(0, displayLimit)

  const onRoleChanged = function(event: any) {
    setFilterRole(event.target.value)
  }

  const onNameChanged = function(event: any) {
    setSearchName(event.target.value)
  }

  const onEMailChanged = function(event: any) {
    setSearchEMail(event.target.value)
  }

  return (
    <>
      <CCard>
        <CCardHeader>
          <div className="flex items-center justify-content-between">
            <span>ユーザー一覧</span>
            <CButton
              type="button"
              color="primary"
              onClick={() => {
                history.push(`/users/new`);
              }}
            >
              <i className="mr-1">
                <CIcon size={"sm"} name={"cil-plus"} />
              </i>
              追加
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          {tableItems && (
            <>
              <CRow>
                <CCol sm="3">
                  <CFormGroup>
                    <CSelect name="roles" onChange={(e) => onRoleChanged(e)}>
                      <option value="">すべてのロール</option>
                      {roles.map((role) => {
                        if (isOrganizationAdmin && role.key === "admin") return;
                        return (
                          <option value={role.key} key={role.key}>
                            {role.name}
                          </option>
                        );
                      })}
                    </CSelect>
                  </CFormGroup>
                </CCol>
                <CCol sm="3">
                  <CFormGroup>
                    <CInputGroup>
                      <CInput
                        type="text"
                        name="name"
                        placeholder="名前で検索"
                        onChange={(e) => onNameChanged(e)}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>
                          <CIcon name="cil-magnifying-glass" />
                        </CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                </CCol>
                <CCol sm="3">
                  <CFormGroup>
                    <CInputGroup>
                      <CInput
                        type="text"
                        name="email"
                        placeholder="Eメールで検索"
                        onChange={(e) => onEMailChanged(e)}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>
                          <CIcon name="cil-magnifying-glass" />
                        </CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                </CCol>
              </CRow>
              {loading && <LoadingSpinner className="mt-5" />}
              {!loading && (
                <DataTable
                  fields={fields}
                  items={tableItems}
                  scopedSlots={{
                    role: (item: any) => (
                      <td>{roleNameMap[item.role] || "User"}</td>
                    ),
                    actions: (item: any) => {
                      return (
                        <td>
                          <CLink to={`/users/${item.id}`}>
                            <FiEdit className="text-lg" />
                          </CLink>
                        </td>
                      );
                    },
                  }}
                  noItemsView={{ noItems: "該当のユーザーはありません" }}
                />
              )}
            </>
          )}
          {!!displayUsers && displayLimit < displayUsers?.length && (
            <CButton
              type="button"
              color="secondary"
              onClick={() => {
                showNextUsers();
              }}
            >
              次を読み込む
            </CButton>
          )}
        </CCardBody>
      </CCard>
    </>
  );
}

export default UserList
