import React from "react";

type Props = {
  text: string;
};

const TableNoItemView: React.FC<Props> = ({ text }) => {
  return <div className="text-base text-center">{text}</div>;
};

export default TableNoItemView;
