
import Modal from "react-modal"
import React, { useState } from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';

Modal.setAppElement("#root")

type Props = UseModalResult & {
  title: string;
  onOk?(): void;
  onCancel?(): void;
  okText?: string;
  cancelText?: string;
  footer?: boolean;
}

type UseModalResult = {
  show: boolean;
  onOpen(): void;
  onClose(): void;
}

const AppModal: React.FC<Props> = (props) => {
  const footer = props.footer === false ? false : true;
  return (
    <CModal
      show={props.show}
      onOpened={props.onOpen}
      onClose={props.onClose}
    >
      <CModalHeader closeButton>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {props.children}
      </CModalBody>
      {footer && (
        <CModalFooter>
          <CButton color="secondary" onClick={() => {
            props?.onCancel && props.onCancel();
            props.onClose();
          }}>{props.onCancel || 'キャンセル'}</CButton>
          <CButton color="primary" onClick={() => {
            props?.onOk && props.onOk();
            props.onClose();
          }}>{props.okText || 'OK'}</CButton>
        </CModalFooter>
      )}
    </CModal>
  );
}

export function useModal(): UseModalResult {
  const [show, setShow] = useState(false);

  const onOpen = () => {
    setShow(true);
  }

  const onClose = () => {
    setShow(false);
  }

  return {show, onOpen, onClose};
}

export default AppModal;