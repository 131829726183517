import React, { useEffect, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Redirect, useParams, useHistory } from "react-router-dom";
import ReactDatePicker from "react-datepicker";

import {
  CCard,
  CCardHeader,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
} from "@coreui/react";

import { useUpdateAlertMutation, useDeleteAlertMutation } from "../../../api";
import { RootState } from "../../../store";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { UpdatePlantAlertDto } from "../../../models/plant_alert";
import { UpdatePlantAlertSchema } from "../../../formSchemas/plant_alert";
import LoadingButton from "../../common/LoadingButton";
import ConfirmModal from "../../common/modals/ConfirmModal";

const PlantDetailAlertLogDetail: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { plantId, alertId } = useParams<{ plantId: string; alertId: string }>();
  const { register, handleSubmit, reset, errors, control } =
    useForm<UpdatePlantAlertDto>({
      validationSchema: UpdatePlantAlertSchema,
    });
  const { addToast } = useToasts();
  const [updateAlert] = useUpdateAlertMutation();
  const [deleteAlert] = useDeleteAlertMutation();

  let alert = useSelector((state: RootState) =>
    state.alertsState.alerts?.find((x) => {
      return x.id === alertId
    })
  );

  useEffect(() => {
    if (alert) {
      const alert_status = alert.alert_status;
      const deal_status = alert.deal_status;
      const reason = alert.reason;
      const response_time = alert.response_time
        ? new Date(alert.response_time * 1000)
        : undefined;
      const response_person = alert.response_person!;
      const response_detail = alert.response_detail!;
      const improvement = alert.improvement!;
      reset({
        alert_status,
        deal_status,
        reason,
        response_time,
        response_person,
        response_detail,
        improvement,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  if (!alert) {
    return <Redirect to={`/plants/${plantId}/alerts`} />;
  }

  const onSubmit = async (data: UpdatePlantAlertDto) => {
    setIsLoading(true);
    try {
      await updateAlert({
        variables: {
          id: alert!.id,
          input: {
            ...data,
            response_time: data.response_time
              ? dayjs(data.response_time).unix()
              : undefined,
          },
        },
      });
      addToast("更新しました。", { appearance: "success" });
      history.push(`/plants/${plantId}/alerts`);
    } catch (e: any) {
      addToast(
        e && e.response && e.response.data
          ? e.response.data.message
          : "更新できませんでした",
        { appearance: "error" }
      );
    }
    setIsLoading(false);
  };

  const onSubmitDelete = async () => {
    setShowModal(false);
    setIsLoading(true);
    try {
      await deleteAlert({
        variables: {
          id: alert!.id
        },
      });
      addToast("削除しました。", { appearance: "success" });
      history.push(`/plants/${plantId}/alerts`);
    } catch (e: any) {
      addToast("削除できませんでした", { appearance: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <CFormGroup>
          <CLabel htmlFor="time">警報日時</CLabel>
          <div>
            {dayjs(alert.time * 1000).format("YYYY/MM/DD (ddd) HH:mm")}
          </div>
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="custom_measurement_id">対象センサー</CLabel>
          <div>{alert.custom_measurement_item?.name_jp || ""}</div>
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="alert_status">警報種別</CLabel>
          <select
            name="alert_status"
            defaultValue="notice"
            ref={register({ required: true })}
            className="form-control"
          >
            <option value="notice">要観察</option>
            <option value="warning">警告</option>
            <option value="stop">通信不通</option>
          </select>
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="deal_status">対応状況</CLabel>
          <select
            name="deal_status"
            defaultValue="未対応"
            ref={register({ required: true })}
            className="form-control"
          >
            <option value="未対応">未対応</option>
            <option value="対応済み">対応済み</option>
          </select>
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="reason">原因（想定込み）</CLabel>
          <textarea
            rows={3}
            name="reason"
            ref={register}
            autoComplete="reason"
            placeholder="原因（想定込み）"
            className={classNames("form-control", {
              "is-invalid": errors.reason,
            })}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="response_time">復旧日時</CLabel>
          <div>
            {alert.normalized_time? dayjs(alert.normalized_time * 1000).format("YYYY/MM/DD (ddd) HH:mm"): '-'}
          </div>
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="response_person">対応者</CLabel>
          <input
            name="response_person"
            ref={register}
            autoComplete="response_person"
            placeholder="対応者"
            className={classNames("form-control", {
              "is-invalid": errors.response_person,
            })}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="response_detail">対処内容</CLabel>
          <textarea
            rows={3}
            name="response_detail"
            ref={register}
            autoComplete="response_detail"
            placeholder="対処内容"
            className={classNames("form-control", {
              "is-invalid": errors.response_detail,
            })}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="improvement">今後の改善策</CLabel>
          <textarea
            rows={3}
            name="improvement"
            ref={register}
            autoComplete="improvement"
            placeholder="今後の改善策"
            className={classNames("form-control", {
              "is-invalid": errors.improvement,
            })}
          />
        </CFormGroup>

        <CFormGroup className="mt-4">
          <LoadingButton type="submit" color="primary" loading={isLoading}>
            更新
          </LoadingButton>
        </CFormGroup>
      </CForm>

      <CCard className="mt-4">
        <CCardHeader>その他の操作</CCardHeader>
        <CCardBody>
          <CForm>
            <CFormGroup>
              <CLabel className="block">報告書を削除する</CLabel>
              <div className="mt-2">
                <LoadingButton
                  type="button"
                  color="danger"
                  loading={isLoading}
                  onClick={() => setShowModal(true)}
                >
                  削除
                </LoadingButton>
                <ConfirmModal
                  title={`本当に削除しますか？`}
                  isOpen={showModal}
                  handleCloseModal={() => setShowModal(false)}
                  handleSubmit={() => onSubmitDelete()}
                />
              </div>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  );
};

export default PlantDetailAlertLogDetail;
