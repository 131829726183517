import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { MockedProvider, } from '@apollo/react-testing';

import CodingHomePage from './components/coding/HomePage'
import CodingLoginPage from './components/coding/LoginPage'

const App = () => {
  return (
    <MockedProvider>
      <Router>
        <Switch>
          <Route exact path="/coding" component={CodingHomePage} />
          <Route exact path="/coding/login" component={CodingLoginPage} />
        </Switch>
      </Router>
    </MockedProvider>
  );
}

export default App;
