import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CLabel,
  CRow,
  CSelect,
  CSwitch,
  CWidgetIcon,
} from "@coreui/react";

import { watchErrorWithToast } from "../utils/toast";
import DataTable from "./parts/DataTable";

import LoadingSpinner from "../components/common/LoadingSpinner";

import CIcon from "@coreui/icons-react";
import { useGetAlertSummaryListQuery, AlertSummaryListItem } from "../api";
import { useAppContext } from "../contexts/app";

type statusType = "null" | "ok" | "notice" | "warning" | "stop";

const terms = [
  { key: 6, name: "6時間" },
  { key: 12, name: "12時間" },
  { key: 24, name: "24時間" },
];

const statuses: { key: statusType; name: string }[] = [
  { key: "null", name: "すべてのステータス" },
  { key: "ok", name: "異常なし" },
  { key: "notice", name: "要観察" },
  { key: "warning", name: "警告" },
  { key: "stop", name: "通信不通" },
];

const getIcon = (status: statusType) => {
  switch (status) {
    case "notice":
      return <CIcon width={24} className="text-warning" name="cil-warning" />;
    case "warning":
      return <CIcon width={24} className="text-danger" name="cil-bell" />;
    case "ok":
      return (
        <CIcon width={24} className="text-success" name="cil-check-circle" />
      );
    case "stop":
      return <CIcon width={24} className="text-dark" name="cil-ban" />;
  }
};

const makeTableData = function (
  records: AlertSummaryListItem[],
  currentTerm: number
) {
  const times: any[] = _.range(currentTerm)
    .map((i) => {
      // 始端と0:00のみ日付を表示
      // 2行表示に対応するためwhite-space: break-space
      // 9/14                  9/15
      // 10:00 11:00 12:00 ... 0:00 01:00 ...
      const time = dayjs().subtract(i, "hour");
      let label = '      ' + time.format("HH:00");
      const firstIndex = _.range(currentTerm).length - 1;

      if (i === firstIndex) {
        label = time.format("MM/DD") + ' ' + time.format("HH:00")
      }
      if (time.hour() === 0) {
        label = time.format("MM/DD") + ' ' + time.format("HH:00")
      }
      return { key: time.format("YYYY-MM-DD HH:00"), label, _style: {
        'fontSize': '12px',
        'width': '5em',
        'whiteSpace': 'break-spaces',
      } };
    })
    .reverse();
  const fields = [
    { key: "current_status", label: "最新" },
    { key: "plant_name", label: "プラント名称" },
  ].concat(times);

  let plantStatuses: any[] = records.map((record) => {
    let plantStatus: any = {
      plantId: record.plant.id,
      plantName: record.plant.name_jp,
      current: "null",
    };
    for (let time of times) {
      let status = "null";
      const sensorLog = record.alertSummaries.find((alertSummary) => {
        const sensortime = dayjs
          .unix(Number(alertSummary.time))
          .format("YYYY-MM-DD HH:00");
        return sensortime === time.key;
      });
      if (sensorLog) {
        if (
          !sensorLog.primaryAlert ||
          sensorLog.primaryAlert.alert_status === "safe"
        ) {
          status = "ok";
        } else {
          status = sensorLog.primaryAlert.alert_status;
        }
      }
      plantStatus[time.key] = status;
      if (status !== "null") {
        plantStatus["current"] = status;
      }
    }
    return plantStatus;
  });

  let scopedSlots: any = {
    current_status: (plantStatus: any) => (
      <td style={{ minWidth: "60px" }}>{getIcon(plantStatus["current"])}</td>
    ),
    plant_name: (plantStatus: any) => (
      <td style={{ minWidth: "140px" }}>
        <Link to={`/plants/${plantStatus.plantId}`}>
          {plantStatus.plantName}
        </Link>
      </td>
    ),
  };
  for (let time of times) {
    scopedSlots[time.key] = (plantStatus: any) => (
      <td>{getIcon(plantStatus[time.key])}</td>
    );
  }
  return { fields, plantStatuses, scopedSlots };
};

const useHomeWidgetClickScrollToOptions = () => {
  const [windowWidth, setWindowWidth] = useState<number>();
  const [scrollToOptions, setScrollToOptions] = useState<ScrollToOptions>();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    let tentativeScrollToOptions: ScrollToOptions = {
      top: 230,
      behavior: 'smooth'
    };
    if (typeof windowWidth === 'number' && windowWidth <= 575) {
      tentativeScrollToOptions = {
        ...tentativeScrollToOptions,
        top: 400
      };
    }
    setScrollToOptions(tentativeScrollToOptions);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  return scrollToOptions;
};

const HomePage = () => {
  const { userSession } = useAppContext();
  const { addToast } = useToasts();
  const hasShownAlertSummaryListErrorToastState = useState(false);
  const [isOnlyInCharge, setIsOnlyInCharge] = useState(true);
  const [useCache, setUseCache] = useState(true);
  const [currentStatus, setCurrentStatus] = useState("null");
  const [currentTerm, setCurrentTerm] = useState(terms[0].key);
  const homeWidgetClickScrollToOptions = useHomeWidgetClickScrollToOptions();

  const alertSummaryListQuery = useGetAlertSummaryListQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        durationHours: 24, // 表示時間幅指定 6 or 12 or 24
        status: null, // 「ステータス」で絞り込み 通信不通: "stop", 警告: "warning" 要観察: "notice"
        plantIds: null, // 「自分の担当プラントで絞り込み」の場合はuserSession.user.plant_ids
      },
    },
  });

  watchErrorWithToast(
    addToast,
    alertSummaryListQuery.error,
    "閾値設定の取得に失敗しました。",
    hasShownAlertSummaryListErrorToastState
  );

  let tableData: any = {};
  if (!alertSummaryListQuery.loading && alertSummaryListQuery.data) {
    let records = alertSummaryListQuery.data.getAlertSummaryList.records;
    if (isOnlyInCharge) {
      const user = userSession?.user;
      records = records.filter((record: AlertSummaryListItem) =>
        user!.plant_ids?.split(",").includes(String(record.plant.id))
      );
    }
    for (let dashboard of records) {
      if (
        useCache &&
        !dashboard.alertSummaries.find(
          (log) => log.time === dayjs().format("YYYY-MM-DD HH:00")
        )
      ) {
        setUseCache(false);
      }
    }
    tableData = makeTableData(records, currentTerm);
  }
  let { fields, plantStatuses, scopedSlots } = tableData;

  const onTermChanged = function (event: any) {
    setCurrentTerm(Number(event.target.value));
  };

  const onStatusChanged = function (event: any) {
    setCurrentStatus(event.target.value);
  };

  const makeCurrentStatusCountDisplay = (
    text: string,
    type: string,
    colorType: string,
    icon: string
  ) => {
    let statusCount: string | number = "-";
    if (
      !alertSummaryListQuery.loading &&
      alertSummaryListQuery.data &&
      alertSummaryListQuery.data.getAlertSummaryList.records &&
      0 < alertSummaryListQuery.data.getAlertSummaryList.records.length
    ) {
      let records = alertSummaryListQuery.data.getAlertSummaryList.records;
      if (isOnlyInCharge) {
        const user = userSession?.user;
        records = records.filter((record: AlertSummaryListItem) =>
          user!.plant_ids?.split(",").includes(String(record.plant.id))
        );
      }
      statusCount = records.filter((record: AlertSummaryListItem) => {
        if (type === "ok") {
          return (
            !record.latestPrimaryAlert ||
            record.latestPrimaryAlert.alert_status === "safe"
          );
        } else {
          return (
            record.latestPrimaryAlert &&
            record.latestPrimaryAlert.alert_status === type
          );
        }
      }).length;
    }
    return (
      <CCol xs="12" sm="6" lg="4">
        <CWidgetIcon
          text={text}
          header={String(statusCount)}
          color={colorType}
          iconPadding={false}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setCurrentStatus(type);
            window.scroll(homeWidgetClickScrollToOptions);
          }}
        >
          <CIcon width={24} name={icon} className="mx-5" />
        </CWidgetIcon>
      </CCol>
    );
  };

  return (
    <>
      <CRow>
        {makeCurrentStatusCountDisplay(
          "異常なし",
          "ok",
          "success",
          "cil-check-circle"
        )}
        {makeCurrentStatusCountDisplay(
          "要観察",
          "notice",
          "warning",
          "cil-warning"
        )}
        {makeCurrentStatusCountDisplay("警告", "warning", "danger", "cil-bell")}
        {makeCurrentStatusCountDisplay("通信不通", "stop", "dark", "cil-ban")}
      </CRow>

      <CCard className="mt-2">
        <CCardHeader>プラントの状態</CCardHeader>
        <CCardBody>
          <CRow className="-mb-4">
            <CCol sm="4" className="flex items-center">
              <CFormGroup className="flex items-center">
                <CSwitch
                  className="mx-1"
                  shape="pill"
                  color="primary"
                  defaultChecked
                  onChange={(e) => setIsOnlyInCharge(!isOnlyInCharge)}
                />
                <CLabel variant="checkbox" htmlFor="only-mine">
                  自分の担当現場
                </CLabel>
              </CFormGroup>
            </CCol>
            <CCol sm="4">
              <CFormGroup>
                <CSelect name="term" onChange={(e) => onTermChanged(e)}>
                  {terms.map((term) => {
                    return (
                      <option value={term.key} key={term.key}>
                        {term.name}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol sm="4">
              <CFormGroup>
                <CSelect name="status" value={currentStatus} onChange={(e) => onStatusChanged(e)}>
                  {statuses.map((status) => {
                    return (
                      <option value={status.key} key={status.key}>
                        {status.name}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CRow>
        </CCardBody>
        {!(plantStatuses && 0 < plantStatuses.length) &&
          alertSummaryListQuery.loading && <LoadingSpinner className="py-4" />}
        {plantStatuses && 0 < plantStatuses.length && (
          <DataTable
            fields={fields}
            items={plantStatuses.filter(
              (status: any) =>
                !currentStatus || currentStatus === "null" || status.current === currentStatus
            )}
            scopedSlots={scopedSlots}
            itemsPerPage={100}
            pagination
            noItemsView={{ noItems: "該当のプラントはありません" }}
          />
        )}
      </CCard>
    </>
  );
};


export default HomePage;
