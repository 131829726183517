import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

import { UserModelUtil } from '../../utils/UserModel'

// sidebar nav config
import navigationItems from './_nav'

import { RootState } from '../../store'
import { useAppContext } from '../../contexts/app'

import logoLight from '../../assets/images/logo_light.svg';

const Sidebar = () => {
  const { userSession } = useAppContext()
  const dispatch = useDispatch()
  const show: any = useSelector((state: RootState) => state.sidebarShow)

  let items = navigationItems
  if (userSession && (UserModelUtil.isDefault(userSession.user) || UserModelUtil.isGuestUser(userSession.user) || UserModelUtil.isGuestUserR(userSession.user))) {
    items = items.filter(x => !x.organization_admin && !x.admin)
  }

  if (userSession && UserModelUtil.isOrganizationAdmin(userSession.user)) {
    items = items.filter(x => !x.admin)
  }

  const _items = items.map(item => {
    delete item.organization_admin;
    delete item.admin;
    return item;
  })

  return (
    <CSidebar
      show={show}
      onShowChange={(val: boolean) => dispatch({type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <img src={logoLight} alt="WOTA PLANT" height="16" width="156" />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={_items}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(Sidebar)
