import { createStore } from "redux";

import { UserListItemDto } from "./models/user";
import {
  CompanyModel,
  PlantModel,
  CustomMeasurementItemModel,
  SpotModel,
} from "./api";

export type AlertModel = {
  id: string;
  time: number;
  alert_flg: boolean;
  alert_status: string;
  deal_status: string;
  improvement?: string;
  reason?: string;
  response_detail?: string;
  response_person?: string;
  response_time?: number;
  normalized_time?: number;
  custom_measurement_id: number;
  custom_measurement_item?: CustomMeasurementItemModel;
};

export interface UsersState {
  users: UserListItemDto[] | null;
  lastEvaluatedKey: { id: string } | null;
}

export interface CompaniesState {
  companies: CompanyModel[] | null;
  lastEvaluatedKey: { id: string } | null;
}

export interface PlantsState {
  plants: PlantModel[] | null;
  lastEvaluatedKey: { id: string } | null;
}

export interface CustomMeasurementItemsState {
  customMeasurementItems: CustomMeasurementItemModel[] | null;
  lastEvaluatedKey: { id: string } | null;
}

export interface AlertsState {
  alerts: AlertModel[] | null;
  lastEvaluatedKey: { id: string } | null;
}

export interface SpotsState {
  spots: SpotModel[] | null;
  lastEvaluatedKey: { id: string } | null;
}

export interface RootState {
  sidebarShow: string;
  usersState: UsersState;
  companiesState: CompaniesState;
  plantsState: PlantsState;
  customMeasurementItemsState: CustomMeasurementItemsState;
  alertsState: AlertsState;
  spotState: SpotsState;
}

const initialState: RootState = {
  sidebarShow: "responsive",
  usersState: {
    users: null,
    lastEvaluatedKey: null,
  },
  companiesState: {
    companies: null,
    lastEvaluatedKey: null,
  },
  plantsState: {
    plants: null,
    lastEvaluatedKey: null,
  },
  customMeasurementItemsState: {
    customMeasurementItems: null,
    lastEvaluatedKey: null,
  },
  alertsState: {
    alerts: null,
    lastEvaluatedKey: null,
  },
  spotState: {
    spots: null,
    lastEvaluatedKey: null,
  },
};

const changeState = (
  state = initialState,
  { type = "", ...rest }
): RootState => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
