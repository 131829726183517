
import Modal from "react-modal"
import React from 'react'

Modal.setAppElement("#root")

interface Props {
  title: string;
  isOpen: boolean;
  handleCloseModal(): void;
  handleSubmit(): void;
}

const ConfirmModal = (props: Props) => {
  return (
    <div className="modal confirm-modal">
      <Modal
          isOpen={props.isOpen}
          className="modal-dialog"
          overlayClassName="Overlay"
          onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>{props.title}</div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={props.handleCloseModal}>キャンセル</button>
            <button className="btn btn-primary" onClick={props.handleSubmit}>OK</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmModal