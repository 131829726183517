import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import SensorChart from "./SensorChartForSummary";

import {
  ThresholdModel,
  useGetSensorSummaryListQuery,
  SensorSummaryModel,
  CustomMeasurementItemModel,
} from "../../api";

import { CCol, CRow } from "@coreui/react";
import { cmiName } from "../../utils/CMIData";

import LoadingSpinner from "../common/LoadingSpinner";
import dayjs from "dayjs";
import { TermKey } from "./PlantDetailChart";

interface Props {
  plantId: number;
  termKey: TermKey;
  threshold: ThresholdModel;
  customMeasurementItems: CustomMeasurementItemModel[];
  spotId: number;
}

export type CustomDashboardListModel = {
  round_pos?: number;
  unit?: string;
  name_jp?: string;
  name_en?: string;
  records: SensorSummaryModel[];
  customMeasurementId: number;
  sensorType?: number | null;
};

const PlantDetailChartSummary: React.FC<Props> = (props) => {
  const { plantId } = useParams<{ plantId: string }>();
  const timestamp = useMemo(() => {
    return dayjs().unix();
  }, []);

  const [interval, setInterval] = useState<string>("15m");
  const [durationHours, setDurationHours] = useState<number>(24);

  useEffect(() => {
    let _interval;
    let _durationHour;
    switch (props.termKey) {
      case "summary1week":
        _interval = "04h";
        _durationHour = 24 * 7;
        break;
      // 1ヶ月サマリー不具合のため非表示
      // case "summary1month":
      //   _interval = "24h";
      //   _durationHour = 24 * 7;
      //   break;
      case "summary24h":
      default:
        _interval = "15m";
        _durationHour = 24;
    }

    setInterval(_interval);
    setDurationHours(_durationHour);
  }, [props.termKey]);

  // センサーデータ取得
  const getSensorSummaryList = useGetSensorSummaryListQuery({
    fetchPolicy: "no-cache",
    variables: {
      timestamp, // 取得の基点となる日時 {timestamp指定の場合は {(timestamp - durationHours) 〜 timestamp} の範囲となる。(nullの場合は現在時刻となる）
      interval, // "15m" | "01h" | "04h" | "24h" | "01mo"
      durationHours, // 24 (1day) | 24 * 7 (1week) | 24 * 30 (1month) | 24 * 365 (1year)
      plantId: plantId,
      spotId: String(props.spotId),
    },
    skip: props.spotId === null,
  });

  const dashboard = getSensorSummaryList.data?.getSensorSummaryList;
  const customMeasurementItemList = props.customMeasurementItems;
  const customMeasurementItemIdList: number[] = [];
  customMeasurementItemList.forEach((record) =>
    customMeasurementItemIdList.push(record.id)
  );

  let sensorTypeData: CustomDashboardListModel[] = [];

  // dashboardデータを sensorType 毎に整形
  dashboard?.records?.forEach((record, i) => {
    if (!customMeasurementItemIdList.includes(record.customMeasurementId))
      return;
    if (sensorTypeData.length === 0) {
      sensorTypeData.push({
        customMeasurementId: record.customMeasurementId,
        records: [record],
        sensorType: record.sensorType,
      });
    }
    const isExist = sensorTypeData.some((data: CustomDashboardListModel) => {
      if (data.customMeasurementId === record.customMeasurementId) {
        data.records.push(record);
        return true;
      }
      return false;
    });
    if (!isExist)
      sensorTypeData.push({
        customMeasurementId: record.customMeasurementId,
        records: [record],
        sensorType: record.sensorType,
      });
  });

  sensorTypeData.forEach((data: CustomDashboardListModel) => {
    customMeasurementItemList.some((record) => {
      if (data.customMeasurementId === record.id) {
        data.name_jp = record.name_jp || "";
        data.name_en = record.name_en || "";
        data.unit = record.unit || "";
        data.round_pos =
          typeof record.round_pos === "number" ? record.round_pos : 3;
        return true;
      }
    });
  });

  // TODO 暫定
  sensorTypeData = _.sortBy(sensorTypeData, "customMeasurementId");

  if (getSensorSummaryList.loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {getSensorSummaryList.loading && <LoadingSpinner className="py-4" />}
      {!getSensorSummaryList.loading &&
        !getSensorSummaryList.error &&
        // TODO: threshold が応答されるようになったら下記のコメントに入れ替える
        // : dashboard && dashboard.records && threshold &&
        dashboard &&
        dashboard.records && (
          <CRow className="-mt-8 mb-8">
            {sensorTypeData &&
              dashboard &&
              dashboard.records &&
              sensorTypeData.map(
                (slot: CustomDashboardListModel, i: number) => (
                  <CCol xs="12" sm="6" className="mt-20" key={i}>
                    <h5>{cmiName(slot)}</h5>
                    <SensorChart
                      threshold={props.threshold}
                      customMeasurementItemId={slot.customMeasurementId}
                      spotId={Number(props.spotId)}
                      dashboard={slot}
                      durationHours={durationHours}
                      timestamp={timestamp}
                      currentTermInterval={interval}
                    />
                  </CCol>
                )
              )}
          </CRow>
        )}
    </>
  );
};
export default PlantDetailChartSummary;
