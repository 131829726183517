import React from "react";
import { CChartLine } from "@coreui/react-chartjs";
import dayjs from "dayjs";
import _ from "lodash";
import { ThresholdModel } from "../../api";
import { getThresholdValue, borderSettings } from "../../utils/threshold";
import { makeGraphBase } from "../../utils/SensorData";
import { CustomDashboardListModel } from "./PlantDetailChart";

const util = require("@coreui/utils/src");
const { getStyle, hexToRgba } = util;

const brandInfo = getStyle("info") || "#20a8d8";
const brandWarning = getStyle("warning") || "#e6c100";
const brandDanger = getStyle("danger") || "#f86c6b";

/**
 * 1つのグラフに1つのSpotを表示するパターン
 * @param attributes
 * @constructor
 */
interface Props {
  customMeasurementItemId: number;
  spotId: number;
  dashboard: CustomDashboardListModel;
  threshold?: ThresholdModel;
  durationHours: number;
  timestamp: number | null;
  currentTermInterval: string;
  time?: string;
}

const addGraphBorders = function (
  dataSets: any[],
  customMeasurementItemId: number,
  spotId: number,
  dataLength: number,
  threshold?: ThresholdModel
) {
  if (!threshold) return;
  for (let setting of borderSettings) {
    const value = getThresholdValue(
      threshold,
      customMeasurementItemId,
      spotId,
      setting
    );
    if (!value) continue;
    dataSets.push({
      label: setting.label,
      backgroundColor: "transparent",
      borderColor: setting.color,
      pointHoverBackgroundColor: setting.color,
      borderWidth: 2,
      borderDash: [2, 2],
      data: _.range(dataLength).map((i) => value),
    });
  }
};

const SensorChart: React.FC<Props> = ({
  threshold,
  customMeasurementItemId,
  spotId,
  dashboard,
  durationHours,
  timestamp,
  currentTermInterval,
  time,
}) => {
  const { data, labels, maxValue } = makeGraphBase(
    dashboard,
    durationHours,
    timestamp,
    currentTermInterval,
    time
  );

  const defaultDatasets = (() => {
    const dataSets = [];
    const mainLine = {
      label: `センサー値${dashboard.unit ? `(${dashboard.unit})` : ""}`,
      backgroundColor: hexToRgba(brandInfo, 10),
      borderColor: brandInfo,
      pointHoverBackgroundColor: brandInfo,
      borderWidth: 2,
      data,
    };
    dataSets.push(mainLine);

    addGraphBorders(
      dataSets,
      customMeasurementItemId,
      spotId,
      data.length,
      threshold
    );

    return dataSets;
  })();

  const defaultOptions = (() => {
    const stepSize = Math.ceil(maxValue / 5);
    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              callback: function (value: any) {
                // 運転記録画面
                if (time) {
                  // 日報用チャートラベル
                  if (currentTermInterval === "01h") {
                    value = dayjs(value).format("HH");
                    // 月報用チャートラベル
                  } else if (currentTermInterval === "24h") {
                    value = dayjs(value).format("D");
                    // 年報用チャートラベル
                  } else if (currentTermInterval === "01mo") {
                    value = dayjs(value).format("M");
                  }
                  // 推移画面: 24時間
                } else if (durationHours === 24 && value.slice(-2) === "00") {
                  value = dayjs(value).format("HH");
                  // 推移画面: 1週間
                } else if (
                  durationHours === 168 &&
                  value.slice(-5) === "00:00"
                ) {
                  value = dayjs(value).format("D");
                  // 推移画面: 1ヶ月
                } else if (durationHours === 720) {
                  value = dayjs(value).format("D");
                } else {
                  value = "";
                }
                return value;
              },
            },
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: stepSize,
              max: Math.max(stepSize * 6, 1),
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: (tooltipItems: any[], data: any) => {
            if (0 < _.get(tooltipItems, "length")) {
              return dayjs(tooltipItems[0].label).format("M/D HH:mm");
            }
          },
          label: function (tooltipItem: any, data: any) {
            let label = `${tooltipItem.yLabel}`;
            return label;
          },
        },
      },
      hover: {
        mode: "nearest",
        intersect: false,
      },
    };
  })();

  return (
    <>
      <CChartLine
        style={{
          height: "300px",
        }}
        datasets={defaultDatasets}
        options={defaultOptions}
        labels={labels}
      />
    </>
  );
};

export default SensorChart;
