import React, { useEffect, useState } from "react";
import classNames from 'classnames';
import {Link, Redirect, useHistory, useParams} from "react-router-dom";

import { CButton, CCard, CCardBody, CCardHeader, CForm, CFormGroup, CFormText, CLabel } from '@coreui/react';
import Select from 'react-select';

import { FormDataOptionModel, useGetUserDataOptionsQuery } from '../../../api';
import { Controller, useForm } from "react-hook-form";
import { useAppContext } from "../../../contexts/app";
import { useToasts } from "react-toast-notifications";
import { UpdateUserDto } from "../../../models/user";
import { UserModelUtil } from "../../../utils/UserModel";
import { UpdateUserSchema } from '../../../formSchemas/manage/user';
import LoadingButton from "../../common/LoadingButton";
import ConfirmModal from '../../common/modals/ConfirmModal';
import roleNameMap from './roleNameMap';
import CIcon from "@coreui/icons-react";
import { useModal } from '../../common/modals/AppModal';
import UserEmailChangeModal from "./UserEmailChangeModal";
import UserPasswordChangeModal from "./UserPasswordChangeModal";
import LoadingSpinner from "../../common/LoadingSpinner";

const UserDetail: React.FC = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [plantIdsOptions, setPlantIdsOptions] = useState<FormDataOptionModel[]>([])
  const { getUser, updateUser, deleteUser, userSession } = useAppContext()
  const { user_id } = useParams<{ user_id: string }>()
  const [user, setUser] = useState<any>()
  const { register, handleSubmit, reset, errors, control, getValues, setValue } = useForm<UpdateUserDto>({
    validationSchema: UpdateUserSchema,
  })

  // Eメール・パスワード更新フォーム
  const updateEmailModal = useModal();
  const updatePasswordModal = useModal();

  // 自分のページかどうか
  const isMyPage = !user_id;

  useEffect(() => {
    if (user_id) {
      getUser(user_id)
          .then(x => {
            setUser(x)
            setIsLoading(false)
          })
    } else {
      setUser(userSession!.user)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (user) {
      const full_name = user.full_name
      const company_id = user.company_id
      const plant_ids = user.plant_ids
      const role = user.role
      reset({
        full_name,
        company_id,
        plant_ids,
        role,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, user])

  const getUserDataOptionsQuery = useGetUserDataOptionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      company_id: user?.company_id
    },
    skip: !user
  })
  const userDataOptions = getUserDataOptionsQuery.data?.getUserDataOptions
  const { addToast } = useToasts()

  useEffect(() => {
    if (userDataOptions) {
      const companyId = getValues().company_id
      const companyIdRestrictions = userDataOptions.company_id.find(option => option.value === companyId)?.restrictions
      setPlantIdsOptions(userDataOptions.plant_ids.filter(option => companyIdRestrictions?.plant_ids?.includes(option.value)))
    }
  }, [getValues, userDataOptions])

  // ユーザー情報かuserDataOptionsのデータがロード中
  if (isLoading || getUserDataOptionsQuery.loading) {
    return <LoadingSpinner className="mt-5" />
  }

  // ロードした上でユーザー情報がなければリダイレクト
  if (!user) {
    return <Redirect to='/users' />
  }

  const onSubmit = async (data: UpdateUserDto) => {
    setIsLoading(true)
    try {
      await updateUser(user!.id, data)
      addToast('更新しました。', { appearance: 'success' })
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '更新できませんでした', { appearance: 'error' })
    }
    setIsLoading(false)
  }

  const onSubmitDelete = async () => {
    setShowModal(false)
    setIsLoading(true)
    try {
      await deleteUser(user!.id)
      addToast('削除しました。', { appearance: 'success' })
      history.push('/users')
    } catch (e: any) {
      if (e.message === 'cannot_self_excecution') {
        addToast('自分自信は削除できません', { appearance: 'error' })
      } else {
        addToast('削除できませんでした', { appearance: 'error' })
      }
      setIsLoading(false)
    }
  }

  const id = user.id
  const email = user.email
  const role = user.role
  const isOverOrganizationAdmin = UserModelUtil.isOverOrganizationAdmin(userSession!.user)
  const isAdmin = UserModelUtil.isAdmin(userSession!.user)
  const isOrganizationAdmin = UserModelUtil.isOrganizationAdmin(userSession!.user)

  return (
    <>
      <Link to="/users"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>ユーザー詳細</CCardHeader>
        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="id">ID</CLabel>
              <input disabled className="form-control" value={id} />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="full_name">氏名</CLabel>
              <input name="full_name" ref={register} autoComplete="full_name" placeholder="氏名" disabled={!isOverOrganizationAdmin}
                className={classNames("form-control", { 'is-invalid': errors.full_name, })}
              />
              {
                errors.full_name?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.full_name?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="email">Eメール</CLabel>
              <input disabled className="form-control" value={email} />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="company_id">所属会社ID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={userDataOptions?.company_id} isDisabled={!isAdmin}
                    defaultValue={userDataOptions?.company_id.find(option => option.value === getValues().company_id)}
                    onChange={(option: any) => {
                      props.onChange(option.value)
                      setValue('plant_ids', '')
                      if (userDataOptions) {
                        setPlantIdsOptions(userDataOptions.plant_ids.filter(plantIdsOption => option.restrictions?.plant_ids?.includes(plantIdsOption.value)))
                      }
                    }}
                    className={classNames("select-form", { 'is-invalid': errors.company_id, })}
                  />
                )}
                name="company_id"
                control={control}
              />
              {
                errors.company_id?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は選択必須です
                </div>
              }
              {isOrganizationAdmin &&
                <CFormText className="help-block">※ 組織管理者と同じ会社を設定します</CFormText>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="plant_ids">担当プラントID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={plantIdsOptions} isDisabled={!isOverOrganizationAdmin}
                    isMulti
                    value={plantIdsOptions.filter(option => getValues().plant_ids?.split(',').includes(option.value))}
                    onChange={(options: any) => {
                      const value = options ? options.map((option: any) => option.value).join(',') : ''
                      props.onChange(value)
                    }}
                  />
                )}
                name="plant_ids"
                control={control}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="role">ロール</CLabel>
              {!isAdmin && role === 'admin' && 
                <div>{roleNameMap['admin']}</div>
              }
              {(isAdmin || role !== 'admin') &&
                <select name="role" ref={register({ required: true })} placeholder="ロール" className="form-control" disabled={!isOverOrganizationAdmin}>
                  <option value="default">{roleNameMap['default']}</option>
                  <option value="guest_user">{roleNameMap['guest_user']}</option>
                  <option value="guest_user_r">{roleNameMap['guest_user_r']}</option>
                  {isOverOrganizationAdmin && <option value="organization_admin">{roleNameMap['organization_admin']}</option>}
                  {isAdmin && <option value="admin">{roleNameMap['admin']}</option>}
                </select>
              }
            </CFormGroup>
            {isOverOrganizationAdmin &&
              <CFormGroup className="mt-4">
                <LoadingButton type="submit" color="primary" loading={isLoading}>更新</LoadingButton>
              </CFormGroup>
            }
          </CForm>
        </CCardBody>
      </CCard>

      {isOverOrganizationAdmin &&
        <CCard className="mt-4">
          <CCardHeader>その他の操作</CCardHeader>
          <CCardBody>
            <CForm>
              {!isMyPage && (
                <CFormGroup>
                  <CLabel className="block">ユーザーを削除する</CLabel>
                  <div className="mt-2">
                    <LoadingButton type="button" color="danger" loading={isLoading} onClick={() => setShowModal(true)}>削除</LoadingButton>
                    <ConfirmModal 
                      title={`本当に削除しますか？`}
                      isOpen={showModal}
                      handleCloseModal={() => setShowModal(false)}
                      handleSubmit={() => onSubmitDelete()}
                    />
                  </div>
                </CFormGroup>
              )}
              {isMyPage && (
                <>
                  <CFormGroup>
                    <CLabel className="block">Eメールアドレスの変更</CLabel>
                    <div className="mt-2">
                      <CButton type="button" color="primary" onClick={updateEmailModal.onOpen}>Eメールアドレスを変更</CButton>
                    </div>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel className="block">パスワードの変更</CLabel>
                    <div className="mt-2">
                      <CButton type="button" color="primary" onClick={updatePasswordModal.onOpen}>パスワードを変更</CButton>
                    </div>
                  </CFormGroup>
                </>
              )}
            </CForm>
          </CCardBody>
        </CCard>
      }
      <UserEmailChangeModal updateEmailModal={updateEmailModal}/>
      <UserPasswordChangeModal updatePasswordModal={updatePasswordModal}/>
    </>
  )
}

export default UserDetail
