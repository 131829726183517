import React from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from 'react-router'

import { UserModelUtil } from '../../utils/UserModel'
import { useAppContext } from "../../contexts/app";

type Props = RouteProps & { fallbackPath: string; }

const AdminRoute: React.FC<Props> = (props: Props) => {
  const { userSession } = useAppContext()

  if (userSession && !UserModelUtil.isOverOrganizationAdmin(userSession.user)) {
    return <Redirect to={props.fallbackPath} />;
  }

  return (
    <Route {...props}>
      { userSession && props.children }
    </Route>
  );
}

export default AdminRoute