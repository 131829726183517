import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";

import SensorChart, {
  SensorChartItem,
  SensorChartTimeUnit,
  ChartJsAxesTimeUnit,
} from "./SensorChart";
import SensorTable, { DataTableItem, makeCsv } from "./SensorTable";

import {
  ThresholdModel,
  useGetSensorSummaryListQuery,
  useGetSpotsListQuery,
  useGetCustomMeasurementItemListQuery,
  CustomMeasurementItemModel,
  CustomSensorValueItemModel,
} from "../../api";

import { CCol, CRow, CFormGroup, CLabel, CSelect } from "@coreui/react";
import CIcon from "@coreui/icons-react";

import LoadingSpinner from "../common/LoadingSpinner";
import { cmiName } from "../../utils/CMIData";
import axios from "axios";
import { useUserSession } from "../../contexts/app";
import { UserSession } from "../../services/app";

interface Props {
  threshold?: ThresholdModel;
}

type TermType = "summary" | "realtime";

type TermOption = {
  name: string;
  unit: dayjs.OpUnitType;
  type: TermType;
  durationHours: number;
  interval: "01h" | "24h" | "01mo";
  chartTimeUnit: SensorChartTimeUnit;
  calenderDateFormat: string;
  chartXAxesUnit: ChartJsAxesTimeUnit;
  chartDateFormat: string;
  tableDateFormat: string;
};

type TermKey = "daily" | "dailySummary" | "monthlySummary" | "yearlySummary";

const termOptions: { [key in TermKey]: TermOption } = {
  daily: {
    name: "日報（実値）",
    unit: "day",
    type: "realtime",
    durationHours: 24,
    interval: "01h",
    chartTimeUnit: "minute",
    calenderDateFormat: "yyyy/MM/dd",
    chartXAxesUnit: "hour",
    chartDateFormat: "H", // TODO 分単位にする
    tableDateFormat: "MM/DD HH:mm",
  },
  dailySummary: {
    name: "日報（サマリー）",
    unit: "day",
    type: "realtime",
    durationHours: 24,
    interval: "01h",
    chartTimeUnit: "hour",
    chartXAxesUnit: "hour",
    calenderDateFormat: "yyyy/MM/dd",
    chartDateFormat: "H", // TODO 分単位にする
    tableDateFormat: "MM/DD HH:mm",
  },
  monthlySummary: {
    name: "月報（サマリー）",
    unit: "month",
    type: "summary",
    durationHours: 24 * 30,
    interval: "24h",
    chartTimeUnit: "day",
    chartXAxesUnit: "day",
    calenderDateFormat: "yyyy/MM",
    chartDateFormat: "D",
    tableDateFormat: "MM/DD",
  },
  yearlySummary: {
    name: "年報（サマリー）",
    unit: "year",
    type: "summary",
    durationHours: 24 * 365,
    interval: "01mo",
    chartTimeUnit: "month",
    chartXAxesUnit: "month",
    calenderDateFormat: "yyyy",
    chartDateFormat: "M",
    tableDateFormat: "YYYY/MM",
  },
};

// custom-sensor-summariesで集計しているフィールドに合わせる
const optionMaps = [
  'avg', // 平均値
  'sum', // 合計値
  'max', // 最大値
  'min', // 最小値
  'lst', // 最新値
  'fst' // 最古値
]

const defaultOptions = [
  { value: 0, label: '平均値' },
  { value: 1, label: '合計値' },
  { value: 2, label: '最大値' },
  { value: 3, label: '最小値' },
  { value: 4, label: '最新値' },
  { value: 5, label: '最古値' }
]

const chartHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between'
}

const chartHeaderSelectStyle = {
  width: 'unset',
}

const PlantDetailReport: React.FC<Props> = ({ threshold }) => {
  const { plantId } = useParams<{ plantId: string }>();
  const [currentSpotId, setCurrentSpotId] = useState<number | null>(null);
  const [currentTermKey, setCurrentTermKey] = useState<TermKey>("daily");
  const [sensorValuesFromStorageStatus, setSensorValuesFromStorageStatus] =
    useState<boolean>(false);
  const [sensorValuesFromStorages, setSensorValuesFromStorages] = useState<
    CustomSensorValueItemModel[]
  >([]);
  const [defaultOptionValues, setDefaultOptionValues] = useState<any>({});

  const timestamp = useMemo(() => {
    return dayjs().subtract(1, "day").unix();
  }, []);

  const currentTerm = useMemo(() => {
    return termOptions[currentTermKey];
  }, [currentTermKey]);

  const [currentTime, setCurrentTime] = useState<number>(timestamp);

  // クエリで取得する終端時間
  const endTime = useMemo(() => {
    return dayjs(currentTime * 1000)
      .endOf(currentTerm.unit)
      .unix();
  }, [currentTime, currentTerm]);

  // クエリで取得する始端時間
  const startTime = useMemo(() => {
    return dayjs(endTime * 1000)
      .subtract(currentTerm.durationHours)
      .startOf(currentTerm.unit)
      .unix();
  }, [endTime, currentTerm.durationHours, currentTerm.unit]);

  // センサーデータ（サマリー）一覧取得
  const getSensorSummaryListQuery = useGetSensorSummaryListQuery({
    fetchPolicy: "no-cache",
    variables: {
      durationHours: currentTerm.durationHours,
      interval: currentTerm.interval,
      timestamp: endTime, // 取得の基点となる日時 {timestamp指定の場合は {(timestamp - durationHours) 〜 timestamp} の範囲となる。(nullの場合は現在時刻となる）
      plantId: plantId,
      spotId: String(currentSpotId),
    },
    skip: currentSpotId === null || currentTermKey === "daily",
  });

  const sensorSummaries =
    getSensorSummaryListQuery.data?.getSensorSummaryList.records || [];

  // 設置場所（spots）一覧取得
  const getSpotsListQuery = useGetSpotsListQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        plant_id: Number(plantId),
      },
    },
  });

  const spots = getSpotsListQuery.data?.getSpotList.records || [];

  // デフォルトのspot設定
  useEffect(() => {
    const firstSpotId = getSpotsListQuery?.data?.getSpotList?.records?.[0]?.id;
    if (firstSpotId) {
      setCurrentSpotId(firstSpotId);
    }
  }, [getSpotsListQuery, getSpotsListQuery.data]);

  // customMeasurementItems一覧取得
  const getCustomMeasurementItemListQuery =
    useGetCustomMeasurementItemListQuery({
      fetchPolicy: "no-cache",
      variables: {
        options: {
          plant_id: Number(plantId),
          sortField: "id",
          sortBy: "asc",
        },
      },
      // skip: false // TODO 日報の場合、自動fetchをスキップする
    });

  const customMeasurementItems =
    getCustomMeasurementItemListQuery.data?.getCustomMeasurementItemList
      .records || [];

  const userSession = useUserSession();
  useEffect(() => {
    if (currentTermKey === "daily") {
      const f = async () => {
        setSensorValuesFromStorageStatus(true);
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
        const sensorValues = await useGetAllSensorValuesFromStorageQuery(
          {
            plantId: plantId,
            timestamp: dayjs(startTime * 1000)
              .startOf("day")
              .add(9, "hour")
              .unix(),
          },
          userSession
        );
        setSensorValuesFromStorages(sensorValues?.allData);
        setSensorValuesFromStorageStatus(false);
      };
      f();
    }
  }, [startTime, currentTermKey, plantId, userSession]);

  // 初期表示時にデフォルトのプルダウンを設定する
  useEffect(()=>{
    if (customMeasurementItems.length) {
      const optionValues = {};
      for (const cmi of customMeasurementItems) {
        if (!cmi.name_jp) {
          // @ts-ignore
          optionValues[cmi.id] = optionMaps.indexOf('avg')
          continue
        } else if (cmi?.unit && ['m3/hr', 'M3/hr', 'm3/h', 'kWh'].includes(cmi.unit)) {
          // デフォルトを合計値とする水量などのセンサー種類判定
          // j-monitor scgi-bin/aidi/new/sub_daily.cgiからロジック抽出
          // 従来はセンサーのマスタに設定されている「単位」で判定していたようで、これを踏襲する形とした
          // @ts-ignore
          optionValues[cmi.id] = optionMaps.indexOf('sum')
          continue
        } else if(cmi.tags) {
          // @ts-ignore
          if (cmi.tags['convert_custom_measurement_id']?.match(/^dino_.*/)) {
            // @ts-ignore
            optionValues[cmi.id] = optionMaps.indexOf('max')
            continue
          }
        }
        // @ts-ignore
        optionValues[cmi.id] = optionMaps.indexOf('avg')
      }
      setDefaultOptionValues(optionValues);
    }
  }, [customMeasurementItems])

  // センサーごとにデータを分ける
  const chartItemsMap: { [key: string]: SensorChartItem[] } = useMemo(() => {
    if (sensorSummaries.length) {
      return convertChartItems(sensorSummaries, "customMeasurementId", defaultOptionValues);
    }
    if (sensorValuesFromStorages.length) {
      return convertChartItems(
        sensorValuesFromStorages,
        "custom_measurement_id", defaultOptionValues
      );
    }
    return {};
  }, [sensorSummaries, sensorValuesFromStorages, defaultOptionValues]);

  // データの存在しないセンサーを除外
  const existsCustomMeasurementItems = useMemo(() => {
    return customMeasurementItems.filter((customMeasurementItem) => {
      return chartItemsMap[customMeasurementItem.id];
    });
  }, [customMeasurementItems, chartItemsMap]);

  // テーブル用のデータを作成
  const tableItems: DataTableItem[] = useMemo(() => {
    if (existsCustomMeasurementItems && chartItemsMap) {
      return convertTableDatas(existsCustomMeasurementItems, chartItemsMap);
    }
    return [];
  }, [existsCustomMeasurementItems, chartItemsMap]);

  const onTermChanged = function (event: any) {
    const termKey = event.target.value;
    setCurrentTermKey(termKey);
  };

  const onTimeChanged = function (date: any) {
    let time;
    switch (currentTermKey) {
      case "daily":
      case "dailySummary":
        time = dayjs(date).endOf("day");
        break;
      case "monthlySummary":
        time = dayjs(date).endOf("month");
        break;
      case "yearlySummary":
        time = dayjs(date).endOf("year");
        break;
    }
    if (time) {
      setCurrentTime(time.unix());
    }
  };

  const onSpotChanged = function (event: any) {
    const spotId = Number(event.target.value);
    setCurrentSpotId(spotId);
  };

  const onClickCsvDL = function () {
    makeCsv(
      tableItems,
      startTime,
      endTime,
      currentTerm.tableDateFormat,
      currentTerm.chartTimeUnit
    );
  };

  const isLoading =
    getSensorSummaryListQuery.loading ||
    getSpotsListQuery.loading ||
    getCustomMeasurementItemListQuery.loading ||
    sensorValuesFromStorageStatus;

  const isEnableRender =
    currentSpotId &&
    chartItemsMap &&
    existsCustomMeasurementItems &&
    tableItems;

  const isNoData =
    !existsCustomMeasurementItems.length ||
    !Object.keys(chartItemsMap).length ||
    !tableItems.length;

  const changeOptionValue = (e: any, i: number, cmiId: number) => {
    const newOptionValues = {...defaultOptionValues}
    newOptionValues[cmiId] = Number(e.target.value)
    setDefaultOptionValues(newOptionValues)
  }

  return (
    <>
      <CRow className="mt-4">
        <CCol xs="12" sm="4">
          <CFormGroup>
            <CLabel htmlFor="name">期間</CLabel>
            <CSelect name="term" onChange={(e) => onTermChanged(e)}>
              {Object.keys(termOptions).map((termKey) => {
                return (
                  <option value={termKey} key={termKey}>
                    {termOptions[termKey as TermKey].name}
                  </option>
                );
              })}
            </CSelect>
          </CFormGroup>
        </CCol>
        <CCol xs="12" sm="4">
          <CFormGroup>
            <CLabel htmlFor="name">対象</CLabel>
            <div className="react-datepicker-has-icon d-inline-block">
              <ReactDatePicker
                locale="ja"
                // minDate={dayjs().subtract(30, "day").toDate()}
                maxDate={dayjs().toDate()}
                selected={dayjs(currentTime * 1000).toDate()}
                onChange={(date) => onTimeChanged(date)}
                dateFormat={currentTerm.calenderDateFormat}
                popperPlacement="top-start"
                className="form-control"
                ref={(el: any) => {
                  if (el && el.input) {
                    el.input.readOnly = true;
                  }
                }}
              />
              <i className="react-datepicker-icon">
                <CIcon name={"cilCalendar"} />
              </i>
            </div>
          </CFormGroup>
        </CCol>
        <CCol xs="12" sm="4">
          <CFormGroup>
            <CLabel htmlFor="name">設置場所</CLabel>
            <CSelect
              name="spot"
              onChange={(e) => onSpotChanged(e)}
              defaultValue={Number(currentSpotId)}
            >
              {spots &&
                spots.map((spot) => {
                  return (
                    <option value={spot.id} key={spot.id}>
                      {spot.name_jp}
                    </option>
                  );
                })}
            </CSelect>
          </CFormGroup>
        </CCol>
      </CRow>
      {isLoading ? (
        <LoadingSpinner className="py-4" />
      ) : (
        <>
          {isNoData && (
            <div className="text-sm">該当の期間のデータが存在しません</div>
          )}
          {!isNoData && isEnableRender && (
            <>
              <CRow className="my-4">
                <CCol className="text-center col-auto">
                  <div
                    onClick={() => onClickCsvDL()}
                    className="btn btn-primary"
                  >
                    <CIcon
                      width={36}
                      name="cil-cloud-download"
                      className="mr-2"
                    />
                    CSVダウンロード
                  </div>
                </CCol>
              </CRow>
              <CRow className="-mt-8 mb-8">
                {existsCustomMeasurementItems.map((customMeasurementItem, i) => {
                  const items = chartItemsMap[customMeasurementItem.id];
                  return (
                    <CCol
                      xs="12"
                      sm="6"
                      className="mt-20"
                      key={customMeasurementItem.id}
                    >
                      <div style={chartHeaderStyle}>
                        <h5>{cmiName(customMeasurementItem)}</h5>
                        {currentTermKey !== "daily" &&
                          <CSelect
                            onChange={(e) => {changeOptionValue(e, i, customMeasurementItem.id)}}
                            defaultValue={defaultOptionValues[customMeasurementItem.id]}
                            style={chartHeaderSelectStyle}
                          >
                            {defaultOptions.map(option => (
                              <option value={option.value} key={option.value}>{option.label}</option>
                            ))}
                          </CSelect>
                        }
                      </div>
                      {currentSpotId && (
                        <SensorChart
                          startTime={startTime}
                          endTime={endTime}
                          customMeasurementItem={customMeasurementItem}
                          items={items}
                          spotId={currentSpotId}
                          dateFormat={currentTerm.chartDateFormat}
                          threshold={threshold}
                          timeUnit={currentTerm.chartTimeUnit}
                          xAxesUnit={currentTerm.chartXAxesUnit}
                          unit={currentTerm.unit}
                        />
                      )}
                    </CCol>
                  );
                })}
              </CRow>
              <SensorTable
                items={tableItems}
                startTime={startTime}
                endTime={endTime}
                dateFormat={currentTerm.tableDateFormat}
                timeUnit={currentTerm.chartTimeUnit}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

// 独自クエリhook
type SensorFromStorageRequest = { plantId: string; timestamp: number };

const useGetAllSensorValuesFromStorageQuery = async (
  params: SensorFromStorageRequest,
  userSession: { userSession: UserSession | null; sessionLoading: boolean }
) => {
  let allData: any[] = [];
  // https://www.apollographql.com/docs/react/get-started で複数リクエスト
  let res = {
    data: {
      data: {
        getSensorValueListFromStorage: {
          records: [],
          hasNextPage: true,
          errors: false,
        },
      },
    },
  };
  let page = 1;
  while (res?.data?.data?.getSensorValueListFromStorage?.hasNextPage) {
    res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graphql`,
      {
        query: `
query getSensorValueListFromStorage($options: SensorValueListFromStorageOptions) {
  getSensorValueListFromStorage(options: $options) {
    records {
      time
      spot_id
      plant_id
      value
      custom_measurement_id
    }
    hasNextPage
  }
}`,
        variables: {
          options: {
            plantId: Number(params["plantId"]),
            timestamp: params["timestamp"],
            page: page,
          },
        },
        operationName: "getSensorValueListFromStorage",
      },
      {
        headers: {
          "content-type": "application/json",
          accept: "*/*",
          authorization: `Bearer ${userSession.userSession?.token}`,
        },
      }
    );
    if (!res?.data?.data?.getSensorValueListFromStorage?.records) {
      break;
    }
    allData = allData.concat(
      res?.data?.data?.getSensorValueListFromStorage?.records
    );
    page++;
  }

  return { allData };
};

const convertChartItems = (
  sensorValues: any[],
  customMeasurementKey: string,
  defaultOptionValues: any
) => {
  let _chartItemsMap: { [key: string]: SensorChartItem[] } = {};
  sensorValues.forEach((sensorValue: any) => {
    if (_chartItemsMap[sensorValue[customMeasurementKey]] === undefined) {
      _chartItemsMap[sensorValue[customMeasurementKey]] = [];
    }
    _chartItemsMap[sensorValue[customMeasurementKey]].push({
      time: new Date(sensorValue.time * 1000),
      // サマリー値のうち "max"
      // 実地の場合は "value" を利用
      value:
        sensorValue[optionMaps[defaultOptionValues[sensorValue[customMeasurementKey]]]] === undefined
          ? sensorValue?.value === undefined
            ? null
            : sensorValue?.value
          : sensorValue[optionMaps[defaultOptionValues[sensorValue[customMeasurementKey]]]],
    });
  });

  return _chartItemsMap;
};

const convertTableDatas = (
  existsCustomMeasurementItems: CustomMeasurementItemModel[],
  chartItemsMap: { [key: string]: SensorChartItem[] }
) => {
  const rows: DataTableItem[] = [];
  existsCustomMeasurementItems.forEach((customMeasurementItem) => {
    const items = chartItemsMap[customMeasurementItem.id];
    const name = cmiName(customMeasurementItem);
    items.forEach((item, i) => {
      rows.push({
        label: name,
        time: item.time.getTime() / 1000,
        value: item.value,
      });
    });
  });
  return rows;
};

export default PlantDetailReport;
