import React, { useEffect, useState } from "react"
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Redirect, useParams, useHistory, Link } from "react-router-dom"

import {
  CCard, CCardHeader, CCardBody,
  CForm, CFormGroup, CLabel
} from '@coreui/react'
import Select from 'react-select'

import { useUpdateCustomMeasurementItemMutation, useDeleteCustomMeasurementItemMutation, useGetCustomMeasurementItemDataOptionsQuery } from '../../../api'
import { RootState } from '../../../store'
import { useForm, Controller } from "react-hook-form"
import { useToasts } from "react-toast-notifications"
import { UpdateCustomMeasurementItemDto } from "../../../models/customMeasurementItem"
import { UpdateCustomMeasurementItemSchema } from '../../../formSchemas/manage/customMeasurementItem'
import LoadingButton from "../../common/LoadingButton"
import ConfirmModal from '../../common/modals/ConfirmModal'
import CIcon from "@coreui/icons-react"
import MultipleTagsInput from "../../common/forms/MultipleTagsInput";

const CustomMeasurementItemDetail: React.FC = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const { custom_measurement_item_id } = useParams<{ custom_measurement_item_id: string }>()
  const { register, handleSubmit, reset, errors, control, getValues } = useForm<UpdateCustomMeasurementItemDto>({
    validationSchema: UpdateCustomMeasurementItemSchema,
  })
  const { addToast } = useToasts()
  const [updateCustomMeasurementItem] = useUpdateCustomMeasurementItemMutation()
  const [deleteCustomMeasurementItem] = useDeleteCustomMeasurementItemMutation()

  let customMeasurementItem = useSelector((state: RootState) => state.customMeasurementItemsState.customMeasurementItems?.find(x => x.id === Number(custom_measurement_item_id)))

  const getCustomMeasurementItemDataOptionsQuery = useGetCustomMeasurementItemDataOptionsQuery({
    fetchPolicy: 'no-cache',
    skip: !customMeasurementItem
  })
  const customMeasurementItemDataOptions = getCustomMeasurementItemDataOptionsQuery.data?.getCustomMeasurementItemDataOptions

  useEffect(() => {
    if (customMeasurementItem) {
      const plant_id = customMeasurementItem.plant_id ? String(customMeasurementItem.plant_id) : ''
      const name_en = customMeasurementItem.name_en ? customMeasurementItem.name_en : ''
      const name_jp = customMeasurementItem.name_jp ? customMeasurementItem.name_jp : ''
      const unit = customMeasurementItem.unit ? customMeasurementItem.unit : ''
      const round_pos = customMeasurementItem.round_pos ? customMeasurementItem.round_pos : 0
      const tags = customMeasurementItem.tags || {}
      reset({
        plant_id,
        name_en,
        name_jp,
        unit,
        round_pos,
        tags,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  if (!customMeasurementItem) {
    return <Redirect to='/manage/customMeasurementItems' />
  }

  const onSubmit = async (data: UpdateCustomMeasurementItemDto) => {
    setIsLoading(true)
    try {
      await updateCustomMeasurementItem({
        variables: {
          id: custom_measurement_item_id,
          input: data
        }
      })
      addToast('更新しました。', { appearance: 'success' })
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '更新できませんでした', { appearance: 'error' })
    }
    setIsLoading(false)
  }

  const onSubmitDelete = async () => {
    setShowModal(false)
    setIsLoading(true)
    try {
      await deleteCustomMeasurementItem({
        variables: {
          id: custom_measurement_item_id
        }
      })
      addToast('削除しました。', { appearance: 'success' })
      history.push('/manage/customMeasurementItems')
    } catch (e: any) {
      addToast('削除できませんでした', { appearance: 'error' })
      setIsLoading(false)
    }
  }

  return (
    <>
      <Link to="/manage/customMeasurementItems"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>センサー詳細</CCardHeader>

        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="plant_id">プラント</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={customMeasurementItemDataOptions?.plant_id}
                    defaultValue={customMeasurementItemDataOptions?.plant_id.find(option => option.value === getValues().plant_id)}
                    onChange={(option: any) => props.onChange(option.value)}
                    className={classNames("select-form", { 'is-invalid': errors.plant_id, })}
                  />
                )}
                name="plant_id"
                control={control}
              />
              {
                errors.plant_id?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は選択必須です
                </div>
              }
            </CFormGroup>
            
            <CFormGroup>
              <CLabel htmlFor="name_en">センサー（英語表記）</CLabel>
              <input name="name_en" ref={register} autoComplete="name_en" placeholder="センサー（英語表記）"
                className={classNames("form-control", { 'is-invalid': errors.name_en, })}
              />
              {
                errors.name_en?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_en?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            
            <CFormGroup>
              <CLabel htmlFor="name_jp">センサー（日本語表記）</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="センサー（日本語表記）"
                className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>

            <CFormGroup>
              <CLabel htmlFor="unit">単位</CLabel>
              <input name="unit" ref={register} autoComplete="unit" placeholder="単位"
                className={classNames("form-control", { 'is-invalid': errors.unit, })}
              />
              {
                errors.unit?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>

            <CFormGroup>
              <CLabel htmlFor="round_pos">小数点桁数</CLabel>
              <input type="number" name="round_pos" ref={register} autoComplete="round_pos" placeholder="0" defaultValue="0"
                className={classNames("form-control", { 'is-invalid': errors.round_pos, })}
              />
            </CFormGroup>

            <CFormGroup>
              <CLabel htmlFor="tags">タグ</CLabel>
              <Controller
                  as={(props: any) => (
                      <MultipleTagsInput type="text" max={5} tags={getValues({nest: true}).tags} onChange={props.onChange} />
                  )}
                  name="tags"
                  control={control}
              />
            </CFormGroup>

            <CFormGroup className="mt-4">
              <LoadingButton type="submit" color="primary" loading={isLoading}>更新</LoadingButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>

      <CCard className="mt-4">
        <CCardHeader>その他の操作</CCardHeader>
        <CCardBody>
          <CForm>
            <CFormGroup>
              <CLabel className="block">センサーを削除する</CLabel>
              <div className="mt-2">
                <LoadingButton type="button" color="danger" loading={isLoading} onClick={() => setShowModal(true)}>削除</LoadingButton>
                <ConfirmModal 
                  title={`本当に削除しますか？`}
                  isOpen={showModal}
                  handleCloseModal={() => setShowModal(false)}
                  handleSubmit={() => onSubmitDelete()}
                />
              </div>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default CustomMeasurementItemDetail
