import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { FiEdit } from 'react-icons/fi';

import {
  CCard, CCardHeader, CCardBody, CButton, CRow, CCol, CFormGroup, CInputGroup, CInput, CInputGroupAppend, CInputGroupText, CLink
} from '@coreui/react'
import Select from 'react-select'

import {Link, useHistory} from 'react-router-dom'

import CIcon from '@coreui/icons-react'
import { useGetCustomMeasurementItemListQuery, useGetCustomMeasurementItemDataOptionsQuery } from '../../../api'
import { RootState } from '../../../store'
import DataTable from '../../parts/DataTable'
import LoadingSpinner from "../../common/LoadingSpinner"
import TagLabel from "../../common/TagLabel";

const limit = 60

const useCustomMeasurementItemList = () => {
  const dispatch = useDispatch()
  const { customMeasurementItems } = useSelector((state: RootState) => state.customMeasurementItemsState)
  const [displayLimit, setDisplayLimit] = useState(limit)

  const { loading, data } = useGetCustomMeasurementItemListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      options: {
        sortBy: 'desc'
      }
    }
  })
  const hasNext = !!customMeasurementItems && displayLimit < customMeasurementItems?.length
  
  useEffect(() => {
    if (data) {
      const customMeasurementItemList = data.getCustomMeasurementItemList
      dispatch({ type: 'set', customMeasurementItemsState: {
        customMeasurementItems: customMeasurementItemList.records,
        lastEvaluatedKey: customMeasurementItemList.lastEvaluatedKey
      }})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getNextCompanies = () => {
    if (hasNext) {
      setDisplayLimit(displayLimit + limit)
    }
  }

  return {
    loading,
    customMeasurementItems,
    displayLimit,
    getNextCompanies,
  }
}

const CustomMeasurementItemList: React.FC = () => {
  const history = useHistory()
  const { loading, customMeasurementItems, displayLimit, getNextCompanies } = useCustomMeasurementItemList()
  const [searchID, setSearchID] = useState('')
  const [searchPlantIDs, setSearchPlantIDs] = useState('')
  const [searchName, setSearchName] = useState('')
  const fields = [
    {key: 'id', label: 'センサーID'},
    {key: 'plant_name', label: 'プラント'},
    {key: 'name_en', label: '英語名'},
    {key: 'name_jp', label: '日本語名'},
    {key: 'unit', label: '単位'},
    {key: 'round_pos', label: '小数点単位'},
    {key: 'tags', label: 'タグ'},
    {key: 'actions', label: ''},
  ];

  const displayCustomMeasurementItems = customMeasurementItems?.
    filter(customMeasurementItems => !searchID || customMeasurementItems.id === Number(searchID)).
    filter(customMeasurementItems => !searchPlantIDs || searchPlantIDs.split(',').includes(String(customMeasurementItems.plant_id))).
    filter(customMeasurementItems => !searchName || customMeasurementItems.name_jp?.match(new RegExp(searchName)) || customMeasurementItems.name_en?.match(new RegExp(searchName)))

  const getCustomMeasurementItemDataOptionsQuery = useGetCustomMeasurementItemDataOptionsQuery({
    fetchPolicy: 'no-cache'
  })
  const customMeasurementItemDataOptions = getCustomMeasurementItemDataOptionsQuery.data?.getCustomMeasurementItemDataOptions

  const tableItems = displayCustomMeasurementItems?.slice(0, displayLimit).map(customMeasurementItem => {
    const plant_option = customMeasurementItemDataOptions?.plant_id.find(plant => plant.value === String(customMeasurementItem.plant_id))
    return {
      id: customMeasurementItem.id,
      plant_name: plant_option?.label || '',
      name_en: customMeasurementItem.name_en || '',
      name_jp: customMeasurementItem.name_jp || '',
      unit: customMeasurementItem.unit || '',
      round_pos: customMeasurementItem.round_pos || '',
      tags: customMeasurementItem.tags || '',
    }
  })

  return (
    <>
      <CCard>
        <CCardHeader>
          <div className="flex items-center justify-content-between">
            <span>センサー一覧</span>
            <CButton type="button" color="primary" onClick={() => {
              history.push(`/manage/customMeasurementItems/new`);
            }}>
              <i className="mr-1">
                <CIcon size={'sm'} name={'cil-plus'} />
              </i>
              追加
            </CButton>
          </div>

        </CCardHeader>
        <CCardBody>
          {
            tableItems &&
            <>
              <CRow>
                <CCol sm="3">
                  <CFormGroup>
                    <CInputGroup>
                      <CInput type="text" name="search_id" placeholder="IDで検索"  onChange={(e: any) => setSearchID(e.target.value)}/>
                      <CInputGroupAppend>
                        <CInputGroupText>
                          <CIcon name="cil-magnifying-glass" />
                        </CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                </CCol>
                <CCol sm="3">
                  <CFormGroup>
                    <Select name='search_plants' options={customMeasurementItemDataOptions?.plant_id}
                      isMulti
                      onChange={(options: any) => {
                        const value = options ? options.map((option: any) => option.value).join(',') : ''
                        setSearchPlantIDs(value)
                      }}
                      placeholder='プラント名で検索'
                    />
                  </CFormGroup>
                </CCol>
                <CCol sm="3">
                  <CFormGroup>
                    <CInputGroup>
                      <CInput type="text" name="search_name" placeholder="センサー名で検索"  onChange={(e: any) => setSearchName(e.target.value)}/>
                      <CInputGroupAppend>
                        <CInputGroupText>
                          <CIcon name="cil-magnifying-glass" />
                        </CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                </CCol>
              </CRow>
              <DataTable
                fields={fields}
                items={tableItems}
                scopedSlots={{
                  plant_name: (item: any) => <td><CLink to={`/manage/plants/${item.plant_id}`}>{item.plant_name}</CLink></td>,
                  tags: (item: any) => {
                    return <TagLabel item={item} />
                  },
                  actions: (item: any) => {
                    return (
                      <td>
                        <CLink
                          to={`/manage/customMeasurementItems/${item.id}`}
                        >
                          <FiEdit className="text-lg" />
                        </CLink>
                      </td>
                    )
                  }
                }}
                noItemsView={{'noItems': '該当のプラントはありません'}}
              />
            </>
          }
          {
            !!displayCustomMeasurementItems && displayLimit < displayCustomMeasurementItems?.length &&
            <CButton type="button" color="secondary" onClick={() => {
              getNextCompanies()
            }}>次を読み込む</CButton>
          }
          {loading && <LoadingSpinner className="py-4" />}
        </CCardBody>
      </CCard>
    </>
  )
}

export default CustomMeasurementItemList;
