import { UpdateUserEmailDto, UpdateUserPasswordDto } from "./../../models/user";
import * as yup from "yup";
import { CreateUserDto, UpdateUserDto } from "../../models/user";

export const CreateUserSchema = yup.object().shape<CreateUserDto>({
  full_name: yup.string().required().max(256),
  email: yup
    .string()
    .required()
    .email()
    .matches(/^[ -~｡-ﾟ]+@[\w_-]+\.[\w._-]+$/),
  plant_ids: yup.string().matches(/^(\d+(,\d+)*)?$/),
  company_id: yup
    .string()
    .required()
    .matches(/^(\d*)?$/),
  role: yup.string(),
});

export const UpdateUserSchema = yup.object().shape<UpdateUserDto>({
  full_name: yup.string().required().max(256),
  plant_ids: yup.string().matches(/^(\d+(,\d+)*)?$/),
  company_id: yup
    .string()
    .required()
    .matches(/^(\d*)?$/),
  role: yup.string(),
});

export const UpdateUserEmailSchema = yup.object().shape<UpdateUserEmailDto>({
  email: yup.string().email(),
  password: yup.string(),
  emailConfirm: yup.string(),
});

export const UpdateUserPasswordSchema = yup
  .object()
  .shape<UpdateUserPasswordDto>({
    password: yup.string(),
    oldPassword: yup.string(),
  });
