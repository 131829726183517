import React from 'react'

import Sidebar from './Sidebar';
import HeaderNav from './HeaderNav';
import {CContainer} from "@coreui/react";

const DefaultLayout: React.FC = ({ children }) => {

  return (
    <div className="c-app c-default-layout">
      <Sidebar/>
      <div className="c-wrapper">
        <HeaderNav />
        <div className="c-body">
          <main className="c-main mb-32">
            <CContainer fluid>
              {children}
            </CContainer>
          </main>
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout
