import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import {
  ThresholdModel,
  useGetSpotsListQuery,
  SensorSummaryModel,
  useGetCustomMeasurementItemListQuery,
  SensorValueResponseListItem,
} from "../../api";

import { CCol, CRow, CFormGroup, CLabel, CSelect } from "@coreui/react";
import PlantDetailChartRealtime from "./PlantDetailChartRealtime";
import PlantDetailChartSummary from "./PlantDetailChartSummary";

interface Props {
  threshold: ThresholdModel;
}

// 1ヶ月サマリー不具合のため非表示
export type TermKey = TermKeySummary | TermKeyRealtime;
// export type TermKeySummary = "summary24h" | "summary1week" | "summary1month";
export type TermKeySummary = "summary24h" | "summary1week";
export type TermKeyRealtime = "realtime12h" | "realtime24h";

type TermType = "summary" | "realtime";

type TermOption = {
  name: string;
  type: TermType;
};

const termOptions: { [key in TermKey]: TermOption } = {
  summary24h: {
    name: "24時間 - サマリー",
    type: "summary",
  },
  summary1week: {
    name: "1週間 - サマリー",
    type: "summary",
  },
  // 1ヶ月サマリー不具合のため非表示
  // summary1month: {
  //   name: "1ヶ月 - サマリー",
  //   type: "summary",
  // },
  realtime12h: {
    name: "12時間 - 実値",
    type: "realtime",
  },
  realtime24h: {
    name: "24時間 - 実値",
    type: "realtime",
  },
};

export type CustomDashboardListModel = {
  round_pos?: number;
  unit?: string;
  name_jp?: string;
  name_en?: string;
  records: SensorSummaryModel[];
  customMeasurementId: number;
  sensorType?: number | null;
};

const PlantDetailChart: React.FC<Props> = (props) => {
  const { plantId } = useParams<{ plantId: string }>();
  const [currentSpotId, setCurrentSpotId] = useState<number | null>(null);
  const defaultTermKey: TermKey = "realtime24h";
  const [currentTermKey, setCurrentTermKey] = useState<TermKey>(defaultTermKey);
  const currentTermOption = useMemo(() => {
    return termOptions[currentTermKey];
  }, [currentTermKey]);

  // 設置場所（spots）取得
  const getSpotsListQuery = useGetSpotsListQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        plant_id: Number(plantId),
      },
    },
  });

  const spots = getSpotsListQuery.data?.getSpotList.records;

  useEffect(() => {
    if (!spots) {
      return;
    }
    setCurrentSpotId(spots[0].id);
  }, [getSpotsListQuery.loading]);

  // センサーマスタ取得
  const getCustomMeasurementItemListQuery =
    useGetCustomMeasurementItemListQuery({
      fetchPolicy: "no-cache",
      variables: {
        options: {
          plant_id: Number(plantId),
          sortField: "id",
          sortBy: "asc",
        },
      },
    });

  const customMeasurementItems =
    getCustomMeasurementItemListQuery.data?.getCustomMeasurementItemList
      .records || [];

  const onSpotChanged = function (event: any) {
    setCurrentSpotId(Number(event.target.value));
  };

  const onTermChanged = function (event: any) {
    setCurrentTermKey(event.target.value);
  };

  if (getSpotsListQuery.loading || getCustomMeasurementItemListQuery.loading) {
    return null;
  }

  if (!spots || !customMeasurementItems || !currentSpotId) {
    return null;
  }

  return (
    <>
      <CRow className="mt-4">
        <CCol xs="12" sm="6" md="4">
          <CFormGroup>
            <CLabel htmlFor="name">期間</CLabel>
            <CSelect
              name="term"
              onChange={(e) => onTermChanged(e)}
              defaultValue={defaultTermKey}
            >
              {Object.keys(termOptions).map((termKey) => {
                return (
                  <option value={termKey} key={termKey}>
                    {termOptions[termKey as TermKey].name}
                  </option>
                );
              })}
            </CSelect>
          </CFormGroup>
        </CCol>
        {!getSpotsListQuery.loading && (
          <CCol xs="12" sm="6" md="4">
            <CFormGroup>
              <CLabel htmlFor="name">設置場所</CLabel>
              <CSelect
                name="spot"
                onChange={(e) => onSpotChanged(e)}
                defaultValue={Number(currentSpotId)}
              >
                {spots &&
                  _.sortBy(spots, "id").map((spot, i) => {
                    return (
                      <option value={spot.id} key={i}>
                        {spot.name_jp}
                      </option>
                    );
                  })}
              </CSelect>
            </CFormGroup>
          </CCol>
        )}
      </CRow>
      {/* サマリー値のグラフ一覧 */}
      <>
        {currentTermOption.type === "summary" && (
          <PlantDetailChartSummary
            plantId={Number(plantId)}
            termKey={currentTermKey}
            spotId={currentSpotId}
            threshold={props.threshold}
            customMeasurementItems={customMeasurementItems}
          />
        )}
      </>
      {/* 実値のグラフ一覧 */}
      <>
        {currentTermOption.type === "realtime" && (
          <PlantDetailChartRealtime
            plantId={Number(plantId)}
            termKey={currentTermKey}
            spotId={currentSpotId}
            threshold={props.threshold}
            customMeasurementItems={customMeasurementItems}
          />
        )}
      </>
    </>
  );
};
export default PlantDetailChart;
