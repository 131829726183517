import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import SensorChart, { logger, SensorChartItem } from "./SensorChart";

import {
  ThresholdModel,
  CustomMeasurementItemModel,
  useGetSensorValueListQuery,
} from "../../api";

import { CCol, CRow } from "@coreui/react";
import { cmiName } from "../../utils/CMIData";

import dayjs from "dayjs";

import { TermKey } from "./PlantDetailChart";
import LoadingSpinner from "../common/LoadingSpinner";

interface Props {
  plantId: number;
  termKey: TermKey;
  threshold: ThresholdModel;
  customMeasurementItems: CustomMeasurementItemModel[];
  spotId: number;
}

const PlantDetailChartRealtime: React.FC<Props> = (props) => {
  const timestamp = useMemo(() => {
    return dayjs().unix();
  }, []);

  // クエリで取得する始端時間
  const [startTime, setStartTime] = useState<number>(
    dayjs().subtract(24, "hour").unix()
  );

  // クエリで取得する終端時間
  const endTime = timestamp;

  // グラフの範囲時間
  const [durationHours, setDurationHours] = useState<number>(12);

  useEffect(() => {
    let _startTime;
    let _durationHours;
    switch (props.termKey) {
      case "realtime24h":
        _startTime = dayjs().subtract(24, "hour").unix();
        _durationHours = 24;
        break;
      case "realtime12h":
      default:
        _startTime = dayjs().subtract(12, "hour").unix();
        _durationHours = 12;
    }

    setStartTime(_startTime);
    setDurationHours(_durationHours);
  }, [props.termKey]);

  // 実測値を取得
  const sensorValueListQuery = useGetSensorValueListQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        plantId: Number(props.plantId),
        startTime,
        endTime,
        isAll: true,
      },
    },
  });

  const sensorValues =
    sensorValueListQuery.data?.getSensorValueList?.records || [];

  // センサーごとにデータを分ける
  const sensorValuesMap: { [key: string]: SensorChartItem[] } = useMemo(() => {
    if (!sensorValues.length) {
      return {};
    }
    logger("start make value map");
    let _sensorValuesMap: { [key: string]: SensorChartItem[] } = {};
    sensorValues.forEach((sensorValue) => {
      if (_sensorValuesMap[sensorValue.custom_measurement_id] === undefined) {
        _sensorValuesMap[sensorValue.custom_measurement_id] = [];
      }
      _sensorValuesMap[sensorValue.custom_measurement_id].push({
        time: new Date(sensorValue.time * 1000),
        value: sensorValue.value,
      });
    });
    logger("end make value map");

    return _sensorValuesMap;
  }, [sensorValues]);

  // データの存在しないセンサーを除外
  const existsCustomMeasurementItems = useMemo(() => {
    return props.customMeasurementItems.filter((customMeasurementItem) => {
      return sensorValuesMap[customMeasurementItem.id];
    });
  }, [props.customMeasurementItems, sensorValuesMap]);

  if (sensorValueListQuery.loading) {
    return <LoadingSpinner />;
  }

  if (!sensorValues) {
    return null;
  }

  return (
    <>
      {!sensorValueListQuery.loading &&
        !sensorValueListQuery.error &&
        sensorValues && (
          <CRow className="-mt-8 mb-8">
            {existsCustomMeasurementItems.map((customMeasurementItem) => {
              const items = sensorValuesMap[customMeasurementItem.id];
              return (
                <CCol
                  xs="12"
                  sm="6"
                  className="mt-20"
                  key={customMeasurementItem.id}
                >
                  <h5>{cmiName(customMeasurementItem)}</h5>
                  <SensorChart
                    startTime={startTime}
                    endTime={endTime}
                    customMeasurementItem={customMeasurementItem}
                    items={items}
                    spotId={props.spotId}
                    threshold={props.threshold}
                    dateFormat={"H"}
                    timeUnit="minute"
                    xAxesUnit="hour"
                    unit="day"
                  />
                </CCol>
              );
            })}
          </CRow>
        )}
    </>
  );
};
export default PlantDetailChartRealtime;
