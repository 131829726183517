import * as yup from 'yup'
import { CreatePlantAlertDto, UpdatePlantAlertDto } from "../models/plant_alert";

export const CreatePlantAlertSchema = yup.object().shape<CreatePlantAlertDto>({
  time: yup.string().required(),
  alert_status: yup.string().required(),
  deal_status: yup.string().required(),
  reason: yup.string(),
  response_time: yup.string(),
  response_person: yup.string(),
  response_detail: yup.string(),
  improvement: yup.string()
})

export const UpdatePlantAlertSchema = yup.object().shape<UpdatePlantAlertDto>({
  alert_status: yup.string().required(),
  deal_status: yup.string().required(),
  reason: yup.string(),
  response_time: yup.string(),
  response_person: yup.string(),
  response_detail: yup.string(),
  improvement: yup.string()
})