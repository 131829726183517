import dayjs from "dayjs";
import { SensorSummaryModel } from "../api";
import { CustomDashboardListModel } from "../components/plants/PlantDetailChart";

const updateLabelsAndData = function (
  labels: string[],
  data: (number | null)[],
  dashboard: CustomDashboardListModel,
  time: string,
  maxValue: number
): number {
  // console.log("#############")
  const sensorLog = dashboard.records.find((record: SensorSummaryModel) => {
    // console.log(dayjs.unix(record.time).format('YYYY-MM-DD HH:00'), dayjs(time).format('YYYY-MM-DD HH:00'))
    return (
      dayjs.unix(record.time).format("YYYY-MM-DD HH:00") ===
      dayjs(time).format("YYYY-MM-DD HH:00")
    );
  });
  labels.push(time);

  function setData(
    data: any[],
    value: number | null | undefined,
    dashboard: CustomDashboardListModel
  ): number {
    // round_posがある場合は四捨五入する
    if (value && dashboard.round_pos) {
      const digitVal = Math.pow(10, dashboard.round_pos);
      value = Math.round(value * digitVal) / digitVal;
    }
    data.push(value);
    return Math.max(maxValue, Number(value));
  }

  if (sensorLog) {
    return setData(data, sensorLog.max, dashboard);
  } else {
    return setData(data, null, dashboard);
  }
};

export function makeGraphBase(
  dashboard: CustomDashboardListModel,
  durationHours: number,
  timestamp: number | null,
  currentTermInterval: string,
  time?: string
) {
  let labels: string[] = [],
    data: (number | null)[] = [],
    maxValue: number = 0;

  // 運転記録画面
  if (time) {
    if (currentTermInterval === "01h") {
      // 日報用チャートラベル
      const start = dayjs(time).startOf("day").format("YYYY-MM-DD HH:00");
      for (let hour = 0; hour <= 24; hour++) {
        const time = dayjs(start)
          .add(hour, "hour")
          .format("YYYY-MM-DD HH:00");
        maxValue = updateLabelsAndData(labels, data, dashboard, time, maxValue);
      }
    } else if (currentTermInterval === "24h") {
      // 月報用チャートラベル
      const start = dayjs(time).startOf("month").format("YYYY-MM-DD");
      for (let day = 0; day <= Math.floor(durationHours / 24); day++) {
        const time = dayjs(start).add(day, "day").format("YYYY-MM-DD");
        if (dayjs(time).format("M") !== dayjs(start).format("M")) break;
        maxValue = updateLabelsAndData(labels, data, dashboard, time, maxValue);
      }
    } else if (currentTermInterval === "01mo") {
      // 年報用チャートラベル
      const start = dayjs(time).startOf("year").format("YYYY-MM");
      for (let month = 0; month < 12; month++) {
        const time = dayjs(start).add(month, "month").format("YYYY-MM");
        maxValue = updateLabelsAndData(labels, data, dashboard, time, maxValue);
      }
    }
    // 推移画面
  } else {
    // 24時間
    if (currentTermInterval === "15m") {
      for (let hour = -durationHours; hour <= 0; hour++) {
        for (let minute = 0; minute <= 45; minute += 15) {
          if (timestamp) {
            if (
              dayjs().isBefore(
                dayjs.unix(timestamp).add(hour, "hour").minute(minute)
              )
            )
              break;
            const time = dayjs
              .unix(timestamp)
              .add(hour, "hour")
              .minute(minute)
              .format("YYYY-MM-DD HH:mm");
            maxValue = updateLabelsAndData(
              labels,
              data,
              dashboard,
              time,
              maxValue
            );
          } else {
            if (dayjs().isBefore(dayjs().add(hour, "hour").minute(minute)))
              break;
            const time = dayjs()
              .add(hour, "hour")
              .minute(minute)
              .format("YYYY-MM-DD HH:mm");
            maxValue = updateLabelsAndData(
              labels,
              data,
              dashboard,
              time,
              maxValue
            );
          }
        }
      }
      // 1週間
    } else if (currentTermInterval === "04h") {
      for (let day = -Math.floor(durationHours / 24); day <= 0; day++) {
        const tick = 4;
        for (let hour = 0; hour <= 24 - tick; hour += tick) {
          if (timestamp) {
            if (
              dayjs().isBefore(
                dayjs.unix(timestamp).add(day, "day").hour(hour)
              )
            )
              break;
            const time = dayjs
              .unix(timestamp)
              .add(day, "day")
              .hour(hour)
              .format("YYYY-MM-DD HH:00");
            maxValue = updateLabelsAndData(
              labels,
              data,
              dashboard,
              time,
              maxValue
            );
          } else {
            if (dayjs().isBefore(dayjs().add(day, "day").hour(hour))) break;
            const time = dayjs()
              .add(day, "day")
              .hour(hour)
              .format("YYYY-MM-DD HH:00");
            maxValue = updateLabelsAndData(
              labels,
              data,
              dashboard,
              time,
              maxValue
            );
          }
        }
      }
      // 1ヶ月
    } else if (currentTermInterval === "24h") {
      for (let day = -30; day <= 0; day++) {
        if (timestamp) {
          if (dayjs().isBefore(dayjs.unix(timestamp).add(day, "day").day(1)))
            break;
          const time = dayjs
            .unix(timestamp)
            .add(day, "day")
            .format("YYYY-MM-DD");
          maxValue = updateLabelsAndData(
            labels,
            data,
            dashboard,
            time,
            maxValue
          );
        } else {
          if (dayjs().isBefore(dayjs().add(day, "day").day(1))) break;
          const time = dayjs().add(day, "day").format("YYYY-MM-DD hh:mm");
          maxValue = updateLabelsAndData(
            labels,
            data,
            dashboard,
            time,
            maxValue
          );
        }
      }
    }
  }

  return { data, labels, maxValue };
}
