import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { useDropzone, FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { CCol, CRow, CSpinner } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useAppContext } from '../../contexts/app'
import ConfirmModal from '../common/modals/ConfirmModal'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const fileLimit = 5

const PlantDetailSystemDialog: React.FC = (props) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { loadSystemDiagramUrls, uploadSystemDiagram, deleteSystemDiagram } = useAppContext()
  const [systemDialogUrls, setSystemDialogUrls] = useState<string[]>([])
  const { plantId } = useParams<{plantId?: string}>()
  const [showModals, setShowModals] = React.useState<boolean[]>([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    loadSystemDiagramUrls({plantId: Number(plantId)}).then(res => {
      setSystemDialogUrls(res.urls)
      setShowModals(res.urls.map(() => false))
    })
  }, [])
  const onFileChanged = async (files: File[], fileRejections: FileRejection[]) => {
    if (0 < fileRejections.length || fileLimit <= systemDialogUrls.length) {
      let code = ''
      if (fileLimit <= systemDialogUrls.length) {
        code = 'over-upload-limit'
      } else if (0 < fileRejections[0].errors.length) {
        code = fileRejections[0].errors[0].code
      }
      const errorMessage = t(`dropzone.error.${code}`, { defaultValue: t(`dropzone.error.default`) })
      addToast(errorMessage, { appearance: 'error' })
    } else {
      const params = new FormData()
      params.append('systemDiagram', files[0])
      const res = await uploadSystemDiagram({form: params, plantId: Number(plantId)})
      setSystemDialogUrls(res.urls)
      setShowModals(res.urls.map(() => false))
    }
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFileChanged, accept: ['image/png', 'image/jpeg', 'application/pdf'], maxSize: 100 * 1000 * 1000 })

  const onDeleteSystemDiagram = async ({plantId, diagramId}: {plantId: Number, diagramId: string}) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const res = await deleteSystemDiagram({plantId: Number(plantId), diagramId})
      setSystemDialogUrls(res.urls)
      setShowModals(res.urls.map(() => false))
    } catch (e: any) {
      addToast('削除に失敗しました', { appearance: 'error' })
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className="my-4">
        <CRow>
          {systemDialogUrls.map((url, i) =>
            {
              const isImage = url.match(/\.(jpg|jpeg|png)/)
              const diagramIdMatch = url.match(new RegExp('system-diagram-plant-' + plantId + '-(.*)\\?AWS'))
              const diagramId = diagramIdMatch ? diagramIdMatch[1] : ''
              return (
                <CCol sm={4} key={i} className="system-diagram">
                  <button type="button" className="close" onClick={() => setShowModals(showModals.map((isShow, j) => j === i))} disabled={isLoading}>
                    {!isLoading && <span aria-hidden="true">&times;</span>}
                    {isLoading && <CSpinner color='black' size="sm" />}
                  </button>
                  {!!isImage &&
                    <img src={url} width="100%" onClick={() => window.open(url, '_blank')} className="cursor-pointer" />
                  }
                  {!isImage &&
                    <div onClick={() => window.open(url, '_blank')} className="cursor-pointer">
                      <Document file={url}>
                        <Page pageNumber={1}/>
                      </Document>
                    </div>
                  }
                  <ConfirmModal 
                    title={`この系統図を削除しますか？`}
                    isOpen={showModals[i]}
                    handleCloseModal={() => setShowModals(showModals.map(() => false))}
                    handleSubmit={() => onDeleteSystemDiagram({plantId: Number(plantId), diagramId})}
                  />
                </CCol>
              )
            }
          )}
        </CRow>
        {systemDialogUrls.length === 0 && <span>系統図がアップロードされていません</span>}
      </div>
      <div className="my-4 text-center">
        <div {...getRootProps()} className='btn btn-primary'>
          <input {...getInputProps()} />
          <div><CIcon width={36} name='cil-cloud-upload' className='mr-2'/>系統図をアップロード</div>
        </div>
      </div>
    </>
  )
}

export default PlantDetailSystemDialog
