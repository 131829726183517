import React, { useCallback, useEffect, useState } from 'react'
import { Table, Column, Cell } from "fixed-data-table-2";
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { CPagination } from '@coreui/react'

// 27文字を超える場合、カラムのfont-sizeを小さくして対応する
const COLUMN_MAX_LENGTH = 27;

interface Props {
  items: { [key: string]: number }[];
  itemsPerPage?: number;
  pagination?: boolean;
}

const FixedDataTable: React.FC<Props> = (props) => {
  const [rootDivElement, setRootDivElement] = useState<HTMLDivElement | null>(null);
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [tableWidth, setTableWidth] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const setRootDiv = useCallback((ele: HTMLDivElement) => {
    setRootDivElement(ele)
  }, [])
  const { items, itemsPerPage, pagination } = props;

  const updateWidth = () => {
    if (!rootDivElement) return;
    setTableWidth(rootDivElement.clientWidth)
  };

  useEffect(() => {
    if (!rootDivElement) return;
    setTableWidth(rootDivElement.clientWidth)
    setIsTableVisible(true)
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [rootDivElement])

  if (!items.length) {
    return <></>
  }

  let pageItems = items
  let allPageItems = [items]
  if (pagination && itemsPerPage) {
    const length = Math.ceil(items.length / itemsPerPage);
    allPageItems = new Array(length).fill(0).map((_, i) => items.slice(i * itemsPerPage, (i + 1) * itemsPerPage));
    pageItems = allPageItems[currentPage - 1]
  }

  const cols = [];
  cols.push(
    <Column
      key="日時　　　　　　　　"
      header={<Cell className="tableHeader">日時　　　　　　　　</Cell>}
      columnKey="日時　　　　　　　　"
      cell={({ rowIndex, columnKey, ...props }) => {
        return (
          <Cell {...props}>
            {pageItems[rowIndex]['日時　　　　　　　　']}
          </Cell>
        )
      }}
      width={180}
      fixed={true}
    />
  )

  const obj = pageItems[0]
  for (const key of Object.keys(obj)) {
    if (key === '日時　　　　　　　　') continue;
    let className = '';
    if (key.length > COLUMN_MAX_LENGTH) {
      className = 'column-fontsize-s';
    }

    cols.push(
      <Column
        cellClassName={className}
        key={key}
        header={<Cell className="tableHeader">{key}</Cell>}
        columnKey={key}
        cell={({ rowIndex, columnKey, ...props }) => {
          return (
            <Cell {...props}>
              {pageItems[rowIndex][key]}
            </Cell>
          )
        }}
        width={180}
      />)
  }

  return (
    <div ref={setRootDiv}>
      {isTableVisible &&
        <>
          <Table
            rowHeight={30}
            headerHeight={60}
            rowsCount={pageItems.length}
            scrollToRow={0}
            isColumnResizing={false}
            width={tableWidth}
            height={400}>
            {cols}
          </Table>
          {pagination && (
            <div className={'mt-2'} >
              <CPagination
                activePage={currentPage}
                pages={allPageItems.length}
                onActivePageChange={(i: number) => setCurrentPage(i)}
              ></CPagination>
            </div>
          )}
        </>
      }
    </div>
  )
}

export default FixedDataTable
