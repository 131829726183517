import * as yup from 'yup'
import { CreatePlantDto, UpdatePlantDto } from "../../models/plant";

export const CreatePlantSchema = yup.object().shape<CreatePlantDto>({
  name_jp: yup.string().required().max(256),
  emails: yup.array().of(yup.string().email()),
  custom_measurement_item_ids : yup.string().nullable(),
})

export const UpdatePlantSchema = yup.object().shape<UpdatePlantDto>({
  name_jp: yup.string().required().max(256),
  emails: yup.array().of(yup.string().email()),
  custom_measurement_item_ids : yup.string().nullable(),
})