import React, { useEffect } from "react";
import { useHistory } from "react-router";

type Props = {
  children: React.ReactNode;
};
export const TrackingView: React.FC<Props> = ({ children }) => {
  const { listen } = useHistory();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const unlisten = listen((location: any) => {
      if (!window.gtag) return;
      window.gtag("config", "G-B5WYEX3NJY", {
        page_path: location.pathname,
      });
    });
    return unlisten;
  }, [listen]);
  return <>{children}</>;
};
