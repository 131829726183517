import React from "react"
import classNames from 'classnames'
import { useForm, Controller } from "react-hook-form"

import {
  CCard, CCardHeader, CCardBody,
  CLabel, CFormGroup, CButton, CForm
} from '@coreui/react'
import Select from 'react-select'

import { useCreateCompanyMutation, useGetCompanyDataOptionsQuery } from '../../../api'
import { useToasts } from "react-toast-notifications"
import { useHistory, Link } from "react-router-dom"
import { CreateCompanyDto } from "../../../models/company"
import { CreateCompanySchema } from '../../../formSchemas/manage/company'
import CIcon from "@coreui/icons-react"

const CompanyCreate: React.FC = () => {
  const { register, handleSubmit, errors, control } = useForm<CreateCompanyDto>({
    validationSchema: CreateCompanySchema,
  })
  const { addToast } = useToasts()
  const [createCompany] = useCreateCompanyMutation()

  const history = useHistory()

  const getCompanyDataOptionsQuery = useGetCompanyDataOptionsQuery({
    fetchPolicy: 'no-cache'
  })
  const companyDataOptions = getCompanyDataOptionsQuery.data?.getCompanyDataOptions

  const onSubmit = async (data: CreateCompanyDto) => {
    try {
      await createCompany({
        variables: { input: data }
      })
      addToast('追加しました。', { appearance: 'success' })
      history.push('/companies')
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '追加できませんでした', { appearance: 'error' })
    }
  }

  return (
    <>
      <Link to="/companies"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>組織新規登録</CCardHeader>
        
        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="name_jp">会社名</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="会社名"
                className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="plant_ids">所属プラントID</CLabel>
              <Controller
                as={(props: any) => (
                  <Select name={props.name} options={companyDataOptions?.plant_ids}
                    isMulti
                    onChange={(options: any) => {
                      const value = options ? options.map((option: any) => option.value).join(',') : ''
                      props.onChange(value)
                    }}
                  />
                )}
                name="plant_ids"
                control={control}
              />
            </CFormGroup>
            <CFormGroup className="mt-4">
              <CButton type="submit" color="primary">追加</CButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default CompanyCreate
