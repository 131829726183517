import React, { FC, useState } from 'react'
import {HashLink} from 'react-router-hash-link'
import _ from 'lodash'
import { useToasts } from 'react-toast-notifications'
import { Prompt } from 'react-router';

import {
  CInput,
  CCollapse, CTooltip
} from '@coreui/react'

// TODO センサーのマスタデータ取得（global_measurement_items）
import { ThresholdInput, useUpdateThresholdMutation, ThresholdCustomMeasurementItemInput, SpotModel, ThresholdModel } from '../../api'
import { getThresholdValue, getThresholdSpot, ThresholdType1, ThresholdType2 } from '../../utils/threshold'
import DataTable from '../parts/DataTable'
import LoadingButton from "../common/LoadingButton";
import LoadingSpinner from "../common/LoadingSpinner";
import {cmiName} from "../../utils/CMIData";
import { cilInfo } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

interface Props {
  threshold?: ThresholdModel
  onThresholdUpdated: Function
}

const TooltipLabel: FC<{ content: string }> = (props) => <CTooltip content={props.content}><div><span>{props.children}</span><CIcon content={cilInfo} style={{ marginLeft: '10px' }}/></div></CTooltip>
const stopContentText = '停止(下限)または停止(上限)に値を設定した場合、要観察と警告で設定した値はすべて無視されます';

const fields = [
  { key: 'name', label: '設置場所' },
  { key: 'minNotice', label: '要観察(下限)' },
  { key: 'maxNotice', label: '要観察(上限)' },
  { key: 'minWarning', label: '警告(下限)' },
  { key: 'maxWarning', label: '警告(上限)'},
  { key: 'minStop', label: <TooltipLabel content={stopContentText}>停止(下限)</TooltipLabel>},
  { key: 'maxStop', label: <TooltipLabel content={stopContentText}>停止(上限)</TooltipLabel>},
]

const PlantDetailSetting: React.FC<Props> = ({threshold, onThresholdUpdated}) => {
  const [isCollapses, setIsCollapses] = useState([] as boolean[])
  const [isUpdating, setIsUpdating] = useState(false)
  const [tempThreshold, setTempThreshold] = useState<null|ThresholdInput>(null)
  const { addToast } = useToasts()
  const [hasInput, setHasInput] = useState(false);

  const [updateMutation] = useUpdateThresholdMutation()

  const customMeasurementItems = threshold?.customMeasurementItems!
  const spots = threshold?.spots!

  if (!tempThreshold && threshold) {
    setTempThreshold({plantId: threshold.plantId, thresholdCustomMeasurementItems: _.cloneDeep(threshold.thresholdCustomMeasurementItems)})
  }

  if (customMeasurementItems) {
    if (isCollapses.length < customMeasurementItems.length) {
      customMeasurementItems.forEach(() => isCollapses.push(false))
    }
  }

  function toggle(index: number) {
    if (isCollapses.length < index) return
    setIsCollapses(isCollapses.map((prev, i) => {
      if (index === i && !prev) return true
      return false
    }))
    const targetForm = document.getElementById('collapseForm'+index)
    if (targetForm) {
      setTimeout(() => {
        targetForm.click()
      }, 300)
    }
  }

  function onChangeThreshold(event: any, customMeasurementItemId: number, spotId: number, type1: ThresholdType1, type2: ThresholdType2) {
    setHasInput(true);
    const thresholdSpot = getThresholdSpot(tempThreshold, customMeasurementItemId, spotId)
    if (!thresholdSpot) return
    if (!thresholdSpot[type1]) {
      thresholdSpot[type1] = {
        min: null,
        max: null,
      }
    }
    thresholdSpot[type1]![type2] = event.target.value === "" ? null : Number(event.target.value)
    setTempThreshold(tempThreshold)
  }

  async function updateThreshold(e: any, customMeasurementItemId: number) {
    e.preventDefault()
    setIsUpdating(true)
    let hasLimitError = false
    const newThreshold: ThresholdInput = {
      plantId: tempThreshold?.plantId!,
      thresholdCustomMeasurementItems: tempThreshold?.thresholdCustomMeasurementItems.map((thresholdCustomMeasurementItem) => {
        if (hasLimitError) return
        if (thresholdCustomMeasurementItem.customMeasurementItemId === customMeasurementItemId) {
          const newThresholdCustomMeasurementItem = tempThreshold?.thresholdCustomMeasurementItems.find(tST => tST.customMeasurementItemId === customMeasurementItemId)

          for (const thresholdSpot of newThresholdCustomMeasurementItem?.thresholdSpots || []) {
            for (const type of ['notice', 'warning', 'stop'] as ThresholdType1[]) {
              if (!thresholdSpot[type]) continue
              if (!thresholdSpot[type]!.min || !thresholdSpot[type]!.max) continue

              hasLimitError = Number(thresholdSpot[type]!.min) >= Number(thresholdSpot[type]!.max)
              if (hasLimitError) break
            }
            if (hasLimitError) break
          }
          return newThresholdCustomMeasurementItem
        } else {
          return thresholdCustomMeasurementItem
        }
      }) as ThresholdCustomMeasurementItemInput[]
    }
    if (hasLimitError) {
      addToast('上限は下限より大きい数値で設定してください。', {
        appearance: 'error'
      })
      setIsUpdating(false)
      return
    }

    await updateMutation({
      variables: { input: newThreshold! }
    }).catch((err) => {
      addToast('更新が失敗しました。 '+(err ? 'err: ' + err.message : ''), {
        appearance: 'error'
      })
      setIsUpdating(false)
    })

    await onThresholdUpdated()
    addToast('更新されました。', {
      appearance: 'success'
    })
    setIsUpdating(false)
    setHasInput(false);
  }

  function makeThresholdTableData(customMeasurementItemId: number, item: SpotModel, type1: ThresholdType1, type2: ThresholdType2, roundPos: number) {
    return <td style={{ minWidth: '100px' }}>
      <CInput type="number" name={type1+type2+customMeasurementItemId} step={1/10**roundPos}
        defaultValue={getThresholdValue(tempThreshold, customMeasurementItemId, item.id, {type1, type2})}
        onChange={(e) => onChangeThreshold(e, customMeasurementItemId, item.id, type1, type2)}
      />
    </td>
  }

  return (
    <>
      <div>
        {!customMeasurementItems && <LoadingSpinner className="py-4" />}
        {customMeasurementItems && customMeasurementItems.map((customMeasurementItem, i) => {
          const unit = customMeasurementItems.find(sT => sT.id === customMeasurementItem.id)!.unit
          let label = cmiName(customMeasurementItem)
          if (unit) label += ` [${unit}]`
          const roundPos = customMeasurementItems.find(sT => sT.id === customMeasurementItem.id)!.round_pos!
          return <form className="mt-4" key={i} onSubmit={(e) => updateThreshold(e, customMeasurementItem.id)}>
            <HashLink to={'#collapseForm'+i} className="position-absolute -mt-20" id={'collapseForm'+i}></HashLink>
            <h5 onClick={() => toggle(i)} className="mb-3 pb-2 border-bottom cursor-pointer">{label}<span className={'caret' + (isCollapses[i] ? ' caret-up' : '')}>▼</span></h5>
            <CCollapse show={isCollapses[i]}>
              <DataTable
                fields={fields}
                items={spots}
                scopedSlots={{
                  name: (item: any) => <td style={{ minWidth: '100px' }}>{item.name_jp}</td>,
                  minNotice: (item: any) => makeThresholdTableData(customMeasurementItem.id, item, 'notice', 'min', roundPos),
                  maxNotice: (item: any) => makeThresholdTableData(customMeasurementItem.id, item, 'notice', 'max', roundPos),
                  minWarning: (item: any) => makeThresholdTableData(customMeasurementItem.id, item, 'warning', 'min', roundPos),
                  maxWarning: (item: any) => makeThresholdTableData(customMeasurementItem.id, item, 'warning', 'max', roundPos),
                  minStop: (item: any) => makeThresholdTableData(customMeasurementItem.id, item, 'stop', 'min', roundPos),
                  maxStop: (item: any) => makeThresholdTableData(customMeasurementItem.id, item, 'stop', 'max', roundPos),
                }}
              />
              <div className="text-right px-3">
                <LoadingButton type="submit" color="primary" loading={isUpdating}>更新</LoadingButton>
              </div>
            </CCollapse>
          </form>
        })}
      </div>
      {/* 画面遷移を警告する */}
      <Prompt
        when={hasInput}
        message={location => {
          return '保存されていない入力値がありますが、別の画面に移動してもよろしいですか？'
        }}
      />
    </>
  )
}

export default PlantDetailSetting;
