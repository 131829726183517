import React from 'react';
import Sidebar from './common/Sidebar'
import HeaderNav from './common/HeaderNav'


const LoginPage = () => {
  return pug`
    Sidebar
    main.main-content
      .main-container
        HeaderNav
        section.section
          div login
  `
}

export default LoginPage;
