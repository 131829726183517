import React from "react"
import classNames from 'classnames'
import { useForm, Controller } from "react-hook-form"

import {
  CCard, CCardHeader, CCardBody,
  CLabel, CFormGroup, CButton, CForm
} from '@coreui/react'
import Select from 'react-select'

import { useCreateSpotMutation, useGetSpotDataOptionsQuery } from '../../../api'
import { useToasts } from "react-toast-notifications"
import { useHistory, Link } from "react-router-dom"
import { CreateSpotDto } from "../../../models/spot"
import { CreateSpotSchema } from '../../../formSchemas/manage/spot'
import CIcon from "@coreui/icons-react"
import MultipleTagsInput from "../../common/forms/MultipleTagsInput";

const SpotCreate: React.FC = () => {
  const { register, handleSubmit, errors, control } = useForm<CreateSpotDto>({
    validationSchema: CreateSpotSchema,
  })
  const { addToast } = useToasts()
  const [createSpot] = useCreateSpotMutation()

  const history = useHistory()

  const onSubmit = async (data: CreateSpotDto) => {
    try {
      await createSpot({
        variables: { input: data }
      })
      addToast('追加しました。', { appearance: 'success' })
      history.push('/manage/spot')
    } catch (e: any) {
      addToast((e && e.response && e.response.data) ? e.response.data.message : '追加できませんでした', { appearance: 'error' })
    }
  }
  const getSpotDataOptionsQuery = useGetSpotDataOptionsQuery({
    fetchPolicy: 'no-cache'
  })
  const spotDataOptions = getSpotDataOptionsQuery.data?.getSpotDataOptions

  return (
    <>
      <Link to="/manage/spot"><div className="mb-2"><CIcon name={'cilChevronLeft'}/>戻る</div></Link>
      <CCard>
        <CCardHeader>スポット新規登録</CCardHeader>

        <CCardBody>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormGroup>
              <CLabel htmlFor="plant_id">プラント</CLabel>
              <Controller
                  as={(props: any) => (
                      <Select name={props.name} options={spotDataOptions?.plant_ids}
                              onChange={(option: any) => props.onChange(option.value)}
                              className={classNames("select-form", { 'is-invalid': errors.plant_id, })}
                      />
                  )}
                  name="plant_id"
                  control={control}
              />
              {
                  errors.plant_id?.type === 'required' &&
                  <div className="invalid-feedback">
                    この項目は選択必須です
                  </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="name_jp">名前（日本）</CLabel>
              <input name="name_jp" ref={register} autoComplete="name_jp" placeholder="名前（日本）"
                     className={classNames("form-control", { 'is-invalid': errors.name_jp, })}
              />
              {
                errors.name_jp?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_jp?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="name_en">名前（英語）</CLabel>
              <input name="name_en" ref={register} autoComplete="name_en" placeholder="名前（英語）"
                     className={classNames("form-control", { 'is-invalid': errors.name_en, })}
              />
              {
                errors.name_en?.type === 'required' &&
                <div className="invalid-feedback">
                  この項目は入力必須です
                </div>
              }
              {
                errors.name_en?.type === 'max' &&
                <div className="invalid-feedback">
                  入力可能な文字数を超えています
                </div>
              }
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="tags">タグ</CLabel>
              <Controller
                  as={(props: any) => (
                      <MultipleTagsInput type="text" max={5} onChange={props.onChange} />
                  )}
                  name="tags"
                  control={control}
              />
            </CFormGroup>
            <CFormGroup className="mt-4">
              <CButton type="submit" color="primary">追加</CButton>
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  )
}

export default SpotCreate
