import React from 'react';
import Sidebar from './common/Sidebar'
import HeaderNav from './common/HeaderNav'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow
} from '@coreui/react'

const HomePage = () => {
  return pug`
    Sidebar
    main.main-content
      .main-container
        HeaderNav
        section.section
          div home
          CCard
            CCardBody
              CRow.align-items-center
                CCol.mb-3.mb-xl-0(sm="4" md="2")
                  CButton(block color="primary") Primary
  `
}

export default HomePage;
