import React, { useEffect, useState } from 'react'

import AppService, { UserSession } from '../services/app'
import { CreateUserDto, UpdateUserDto } from '../models/user'

export const StateContext = React.createContext<{userSession: UserSession | null, sessionLoading: boolean}>({userSession: null, sessionLoading: false})
export const AppServiceContext = React.createContext<AppService | undefined>(undefined)
export const DispatchContext = React.createContext<((session: UserSession | null) => void) | undefined>(
  undefined
)

export const renderWithAppContext = (props: {
  userSession: UserSession | null;
  setUserSession: (userSession: UserSession | null) => void;
  sessionLoading: boolean;
  appService: AppService;
}, children: React.ReactNode) => {
  return (
    <StateContext.Provider value={{userSession: props.userSession, sessionLoading: props.sessionLoading}}>
      <DispatchContext.Provider value={props.setUserSession}>
        <AppServiceContext.Provider value={props.appService}>
          { children }
        </AppServiceContext.Provider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const AppContextProvider: React.FC = ({ children }) => {
  const appService = new AppService()

  const [userSession, setUserSession] = useState<UserSession|null>(null)
  const [sessionLoading, setSessionLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!userSession) {
      appService.loadSession().then(result => {
        setUserSession(result)
        setSessionLoading(false)
      })
    }
  }, [appService, userSession])

  return renderWithAppContext({
    userSession,
    setUserSession,
    sessionLoading,
    appService,
  }, children)
}

export const useUserSession = () => {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useUserSession must be used within a AppContextProvider')
  }
  return context
}

export const useSetUserSession = () => {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useSetUserSession must be used within a AppContextProvider')
  }
  return context
}

export const useAppService = () => {
  const context = React.useContext(AppServiceContext)
  if (context === undefined) {
    throw new Error('useAppService must be used within a AppContextProvider')
  }
  return context
}

export const useAppContext = () => {
  const UserSession = useUserSession()
  const sessionLoading = UserSession.sessionLoading
  const userSession = UserSession.userSession
  const setUserSession = useSetUserSession()
  const appService = useAppService()

  const login = async (email: string, password: string) => {
    const result = await appService.authenticate(email, password)
    setUserSession(result)
  }

  const forgotPassword = async (email: string) => {
    await appService.forgotPassword(email)
  }

  const confirmPassword = async (email: string, code: string, password: string) => {
    await appService.confirmPassword(email, code, password)
  }

  const updateUserEmail = async (newEmail: string, password: string) => {
    const email = userSession?.user.email;
    if (!email) {
      throw new Error("認証エラー");
    }
    await appService.updateUserEmail(email, password, newEmail);
    const result = await appService.loadSession({ needRefresh: true });
    setUserSession(result);
  }

  const updateUserPassword = async (password: string, oldPassword: string) => {
    const email = userSession?.user.email;
    if (!email) {
      throw new Error("認証エラー");
    }
    await appService.updateUserPassword(email, password, oldPassword);
  }

  const logout = () => {
    window.location.href = ''
    appService.unauthenticate()
    setUserSession(null)
  }

  const getUsers = async (params: { lastEvaluatedKey?: {id: string}; }) => {
    if (!userSession) {
      throw new Error();
    }
    const response = await appService.getUsers(userSession.token, params)
    return response;
  }

  const getUser = async (id: string) => {
    if (!userSession) {
      throw new Error();
    }
    const response = await appService.getUser(userSession.token, id)
    return response;
  }

  const createUser = async (params: CreateUserDto) => {
    if (!userSession) {
      throw new Error();
    }
    const response = await appService.createUser(userSession.token, params)
    return response;
  }

  const updateUser = async (id: string, params: UpdateUserDto) => {
    if (!userSession) {
      throw new Error();
    }
    const response = await appService.updateUser(userSession.token, id, params)
    const result = await appService.loadSession({needRefresh: true})
    setUserSession(result)
    return response;
  }

  const deleteUser = async (id: string) => {
    if (!userSession) {
      throw new Error()
    }
    if (userSession.user.id === id) {
      throw new Error('cannot_self_excecution')
    }
    const response = await appService.deleteUser(userSession.token, id)
    return response;
  }

  const loadSystemDiagramUrls = async (params: {plantId: number}) => {
    if (!userSession) {
      throw new Error();
    }
    const response = await appService.getSystemDiagramUrls(userSession.token, params)
    return response;
  }

  const uploadSystemDiagram = async (params: {form: FormData, plantId: number}) => {
    if (!userSession) {
      throw new Error();
    }
    const response = await appService.postSystemDiagram(userSession.token, params)
    return response;
  }

  const deleteSystemDiagram = async (params: {plantId: number, diagramId: string}) => {
    if (!userSession) {
      throw new Error();
    }
    const response = await appService.deleteSystemDiagram(userSession.token, params)
    return response;
  }

  return {
    userSession,
    setUserSession,
    sessionLoading,
    login,
    logout,
    forgotPassword,
    confirmPassword,
    updateUserEmail,
    updateUserPassword,
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    loadSystemDiagramUrls,
    uploadSystemDiagram,
    deleteSystemDiagram,
  }
}
