export default [
  {
    _tag: "CSidebarNavItem",
    name: "ダッシュボード",
    to: "/",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavTitle",
    organization_admin: true,
    _children: ["管理機能"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "ユーザー管理",
    to: "/users",
    organization_admin: true,
    icon: "cil-user",
  },
  {
    _tag: "CSidebarNavItem",
    name: "組織管理",
    to: "/companies",
    admin: true,
    icon: "cil-building",
  },
  {
    _tag: "CSidebarNavItem",
    name: "プラント管理",
    to: "/manage/plants",
    organization_admin: true,
    icon: "cil-factory",
  },
  {
    _tag: "CSidebarNavItem",
    name: "スポット一覧",
    to: "/manage/spot",
    admin: true,
    icon: "cil-location-pin",
  },
  {
    _tag: "CSidebarNavItem",
    name: "センサー管理",
    to: "/manage/customMeasurementItems",
    admin: true,
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["サポート"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Q&A",
    href: "https://wota.notion.site/WOTAPLANT-47b1f4b07ee94f6090cdd83a8abb88b6",
    target: "_blank",
    icon: "cil-user",
  },
];

