import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { FiEdit } from 'react-icons/fi';

import {
  CCard, CCardHeader, CCardBody, CButton, CLink
} from '@coreui/react'

import {Link, useHistory} from 'react-router-dom'

import CIcon from '@coreui/icons-react'
import { useGetCompanyListQuery } from '../../../api'
import { RootState } from '../../../store'
import DataTable from '../../parts/DataTable'
import LoadingSpinner from '../../common/LoadingSpinner'

const limit = 60

const useCompanyList = () => {
  const dispatch = useDispatch()
  const { companies } = useSelector((state: RootState) => state.companiesState)
  const [displayLimit, setDisplayLimit] = useState(limit)

  const { loading, data } = useGetCompanyListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      options: {
      }
    }
  })
  const hasNext = !!companies && displayLimit < companies?.length
  
  useEffect(() => {
    if (data) {
      const companyList = data.getCompanyList
      dispatch({ type: 'set', companiesState: {
        companies: companyList.records,
        lastEvaluatedKey: companyList.lastEvaluatedKey
      }})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getNextCompanies = () => {
    if (hasNext) {
      setDisplayLimit(displayLimit + limit)
    }
  }

  return {
    loading,
    companies,
    displayLimit,
    getNextCompanies,
  }
}

const CompanyList: React.FC = () => {
  const history = useHistory()
  const { loading, companies, displayLimit, getNextCompanies } = useCompanyList()
  const fields = [
    {key: 'id', label: 'ID'},
    {key: 'name_jp', label: '会社名'},
    {key: 'actions', label: ''},
  ];

  const tableItems = companies?.slice(0, displayLimit).map(company => {
    return {
      id: company.id,
      name_jp: company.name_jp || '',
    }
  })

  return (
    <>
      <CCard>
        <CCardHeader>
          <div className="flex items-center justify-content-between">
            <span>組織一覧</span>
            <CButton type="button" color="primary" onClick={() => {
              history.push(`/companies/new`);
            }}>
              <i className="mr-1">
                <CIcon size={'sm'} name={'cil-plus'} />
              </i>
              追加
            </CButton>
          </div>

        </CCardHeader>
        <CCardBody>
          {
            tableItems &&
            <DataTable
              fields={fields}
              items={tableItems}
              scopedSlots={{
                name_jp: (item: any) => <td><Link to={`/companies/${item.id}`}>{item.name_jp}</Link></td>,
                actions: (item: any) => {
                  return (
                    <td>
                      <CLink
                        to={`/companies/${item.id}`}
                      >
                        <FiEdit className="text-lg" />
                      </CLink>
                    </td>
                  )
                }
              }}
              noItemsView={{'noItems': '該当の組織はありません'}}
            />
          }
          {
            !!companies && displayLimit < companies?.length &&
            <CButton type="button" color="secondary" onClick={() => {
              getNextCompanies()
            }}>次を読み込む</CButton>
          }
          {loading && <LoadingSpinner className="py-4" />}
        </CCardBody>
      </CCard>
    </>
  )
}

export default CompanyList;
