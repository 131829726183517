import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import { CCol, CFormGroup, CLabel, CRow, CSelect } from "@coreui/react";

import {
  CustomMeasurementItemModel,
  SensorValueResponseListItem,
  ThresholdModel,
  useGetCustomMeasurementItemListQuery,
  useGetSensorValueListQuery,
} from "../../api";

import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import LoadingSpinner from "../common/LoadingSpinner";
import { cmiName, getRoundedValue } from "../../utils/CMIData";
import FixedDataTable from "../parts/FixedDataTable";
registerLocale("ja", ja);

interface Props {
  threshold?: ThresholdModel;
}

const makeTableData = (
  sensorValues: SensorValueResponseListItem[],
  customMeasurementItems: CustomMeasurementItemModel[]
) => {
  if (!sensorValues) {
    return {
      fields: [],
      items: [],
    };
  }
  const fields: any[] = [];
  fields.push({
    key: "time",
    label: "日時　　　　　　　　",
  });

  // timeをキーにしてグルーピング
  const aggregatedItems: any = {};

  sensorValues.forEach((value) => {
    if (!aggregatedItems[value.time]) {
      aggregatedItems[value.time] = {
        time: dayjs(value.time * 1000).format("YYYY-MM-DD HH:mm:ss"),
      };
    }

    const customMeasurementItem = customMeasurementItems.find(
      (c) => c.id === value.custom_measurement_id
    );

    if (!fields.find((field) => field.key === value.custom_measurement_id)) {
      fields.push({
        key: value.custom_measurement_id,
        label: cmiName(customMeasurementItem),
      });
    }
    aggregatedItems[value.time][value.custom_measurement_id] = getRoundedValue(
      value.value,
      customMeasurementItem?.round_pos
    );
    aggregatedItems[value.time]["spot_id"] = value.spot_id;
  });

  const items = Object.values(aggregatedItems).map((item: any) => {
    let value: {[key: string]: number} = {}
    for (const obj of fields) {
      value[obj.label] = item[obj.key]
    }
    return value
  })

  return items;
};

interface TableCellProps {
  iconComponent?: React.ReactElement;
  value: string | number;
  withIcon: boolean;
}
const TableCell: React.FC<TableCellProps> = ({
  iconComponent,
  value,
  withIcon,
}) => {
  return (
    <td style={{ minWidth: "100px" }}>
      <div className="flex justify-content-between">
        {withIcon && (
          <span className="w-4 inline-block mr-2">{iconComponent}</span>
        )}
        {/* 数値は右寄せ */}
        <span
          className={typeof value === "number" ? "text-right" : "text-left"}
        >
          {value}
        </span>
      </div>
    </td>
  );
};

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const PlantDetailValue: React.FC<Props> = ({ threshold }) => {
  const { plantId } = useParams<{ plantId: string }>();
  const [startDate, setStartDate] = useState(dayjs().subtract(7, "days"));
  const [endDate, setEndDate] = useState(dayjs());
  const nowTime = useMemo(() => dayjs().unix(), []);
  const terms = useMemo(() => {
    return [
      {
        key: "1h",
        label: "1時間",
        startTime: dayjs().subtract(1, "hours").unix(),
        endTime: nowTime,
      },
      {
        key: "3h",
        label: "3時間",
        startTime: dayjs().subtract(3, "hours").unix(),
        endTime: nowTime,
      },
      {
        key: "6h",
        label: "6時間",
        startTime: dayjs().subtract(6, "hours").unix(),
        endTime: nowTime,
      },
      {
        key: "12h",
        label: "12時間",
        startTime: dayjs().subtract(12, "hours").unix(),
        endTime: nowTime,
      },
      {
        key: "24h",
        label: "24時間",
        startTime: dayjs().subtract(1, "days").unix(),
        endTime: nowTime,
      },
    ];
  }, [nowTime]);

  const [currentTerm, setCurrentTerm] = useState(terms[0]);

  const customMeasurementItemListQuery = useGetCustomMeasurementItemListQuery({
    variables: {
      options: {
        plant_id: Number(plantId),
      },
    },
  });
  const customMeasurementItems =
    customMeasurementItemListQuery.data?.getCustomMeasurementItemList.records ||
    [];

  const sensorValueListQuery = useGetSensorValueListQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        plantId: Number(plantId),
        startTime: currentTerm.startTime,
        endTime: currentTerm.endTime,
        isAll: true
      },
    },
  });

  const sensorValues =
    (sensorValueListQuery.data?.getSensorValueList
      .records as SensorValueResponseListItem[]) || [];

  useEffect(() => {
    sensorValueListQuery.refetch();
  }, [currentTerm, sensorValueListQuery]);

  const [sensorValueTable, setSensorValueTable] = useState<any>([]);

  const onChangeTerm = (e: any) => {
    const key = e.target.value;
    const term = terms.find((t) => t.key === key) || terms[0];
    setCurrentTerm(term);
  };

  useEffect(() => {
    if (sensorValues && customMeasurementItems) {
      const res = makeTableData(sensorValues, customMeasurementItems);
      setSensorValueTable(res);
    }
  }, [sensorValueListQuery.loading]);

  if (customMeasurementItemListQuery.loading) {
    return <LoadingSpinner />;
  }

  if (!customMeasurementItems.length) {
    return null;
  }

  return (
    <div className="mt-10">
      <CRow className="mt-4">
        <CCol xs="12" sm="4">
          <CFormGroup>
            <CLabel htmlFor="name">期間</CLabel>
            <CSelect name="term" onChange={(e) => onChangeTerm(e)}>
              {terms.map((term) => (
                <option value={term.key} key={term.key}>
                  {term.label}
                </option>
              ))}
            </CSelect>
          </CFormGroup>
        </CCol>
      </CRow>
      {sensorValueListQuery.loading && <LoadingSpinner />}
      {!sensorValueListQuery.loading && sensorValues && (
        <FixedDataTable
          items={sensorValueTable}
          itemsPerPage={60}
          pagination
        />
      )}
    </div>
  );
};

export default PlantDetailValue;
