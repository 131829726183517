import {CButton, CSpinner} from "@coreui/react";
import React from "react";

interface Props {
  loading?: boolean
}

const LoadingButton: React.FC<Props | any> = (props) => {
  const disabled = props.disabled || props.loading || false
  return (
    <CButton
      {...props}
      loading={props.loading.toString()}
      disabled={disabled}
    >
      {props.loading ? (
        <CSpinner
          color={props.outline ? props.color : 'white'}
          size="sm"
        />
      ) : props.children}
    </CButton>
  )
}

export default LoadingButton
