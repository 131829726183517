import React from 'react';

const HeaderNav = () => {
  return pug`
    .navbar-header.navbar
      div header nav
  `
}

export default HeaderNav;
