import React, { useState } from "react";
import { RouteProps } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppContext } from '../../contexts/app'
import { useHistory } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CLabel,
  CRow
} from '@coreui/react'
import queryString from 'query-string'
import logoDark from '../../assets/images/logo_dark.svg';

const ForgotPasswordPage : React.FC<RouteProps>  = (props: RouteProps) => {
  const history = useHistory()

  const { forgotPassword, } = useAppContext()
  const { register, handleSubmit, } = useForm();

  const [error, setError] = useState<boolean>(false)

  const onSubmit = async (data: any) => {
    try {
      await forgotPassword(data.username)
      const query = queryString.stringify({
        email: data.username,
      });
      history.push({
        pathname: '/confirm_password',
        search: `?${query}`,
      })
    } catch (e: any) {
      setError(true)
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg="6" md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <h1 className="mb-5 text-center">
                    <img src={logoDark} alt="WOTA PLANT" height="24" width="233" style={{maxWidth: '100%'}} />
                  </h1>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="mb-5 text-center text-primary">パスワードの<span className="d-inline-block">再設定</span></h4>
                    {
                      error &&
                      <div className="alert alert-danger">不正なリクエストです</div>
                    }

                    <CFormGroup>
                      <CLabel htmlFor="name">Email</CLabel>
                      <input name="username" ref={register({ required: true })} className="form-control" autoComplete="username" />
                    </CFormGroup>

                    <CRow>
                      <CCol xs="12">
                        <CButton type="submit" color="primary" className="p-2 mb-3 btn-block">パスワードを再設定する</CButton>
                      </CCol>
                      <CCol xs="12" className="text-right">
                        <CButton color="link" className="px-0 btn-block" onClick={() => {
                          history.push('/login')
                        }}>ログインへ戻る</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPasswordPage
