import React, { useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useParams, useHistory } from "react-router-dom";
import ReactDatePicker from "react-datepicker";

import { CCardHeader, CForm, CFormGroup, CLabel } from "@coreui/react";

import { useCreateAlertMutation } from "../../../api";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { CreatePlantAlertDto } from "../../../models/plant_alert";
import { CreatePlantAlertSchema } from "../../../formSchemas/plant_alert";
import LoadingButton from "../../common/LoadingButton";
import CIcon from "@coreui/icons-react";

const PlantDetailAlertLogCreate: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { plantId } = useParams<{ plantId: string }>();
  const { register, handleSubmit, errors, control } =
    useForm<CreatePlantAlertDto>({
      validationSchema: CreatePlantAlertSchema,
    });
  const { addToast } = useToasts();
  const [createAlert] = useCreateAlertMutation();

  const onSubmit = async (data: CreatePlantAlertDto) => {
    setIsLoading(true);
    try {
      await createAlert({
        variables: {
          plant_id: plantId,
          input: {
            ...data,
            time: dayjs(data.time).unix(),
            response_time: data.response_time
              ? dayjs(data.response_time).unix()
              : undefined,
          },
        },
      });
      addToast("追加しました。", { appearance: "success" });
      history.push(`/plants/${plantId}/alerts`);
    } catch (e: any) {
      addToast(
        e && e.response && e.response.data
          ? e.response.data.message
          : "追加できませんでした",
        { appearance: "error" }
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <CCardHeader>新規警報発令対応報告書作成</CCardHeader>

      <CForm onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <CFormGroup>
          <CLabel htmlFor="time">警報日時</CLabel>
          <div className={classNames({ "is-invalid": errors.time })}>
            <div className="react-datepicker-has-icon d-inline-block">
              <Controller
                as={ReactDatePicker}
                control={control}
                valueName="selected"
                onChange={([selected]) => selected}
                name="time"
                locale="ja"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                dateFormat="yyyy/MM/dd HH:mm"
                popperPlacement="top-start"
                className={classNames("form-control", {
                  "is-invalid": errors.time,
                })}
              />
              <i className="react-datepicker-icon">
                <CIcon name={"cilCalendar"} />
              </i>
            </div>
          </div>
          {errors.time?.type === "required" && (
            <div className="invalid-feedback">この項目は入力必須です</div>
          )}
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="alert_status">警報種別</CLabel>
          <select
            name="alert_status"
            defaultValue="notice"
            ref={register({ required: true })}
            className="form-control"
          >
            <option value="notice">要観察</option>
            <option value="warning">警告</option>
            <option value="stop">通信不通</option>
          </select>
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="deal_status">対応状況</CLabel>
          <select
            name="deal_status"
            defaultValue="未対応"
            ref={register({ required: true })}
            className="form-control"
          >
            <option value="未対応">未対応</option>
            <option value="対応済み">対応済み</option>
          </select>
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="reason">原因（想定込み）</CLabel>
          <textarea
            rows={3}
            name="reason"
            ref={register}
            autoComplete="reason"
            placeholder="原因（想定込み）"
            className={classNames("form-control", {
              "is-invalid": errors.reason,
            })}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="response_person">対応者</CLabel>
          <input
            name="response_person"
            ref={register}
            autoComplete="response_person"
            placeholder="対応者"
            className={classNames("form-control", {
              "is-invalid": errors.response_person,
            })}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="response_detail">対処内容</CLabel>
          <textarea
            rows={3}
            name="response_detail"
            ref={register}
            autoComplete="response_detail"
            placeholder="対処内容"
            className={classNames("form-control", {
              "is-invalid": errors.response_detail,
            })}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel htmlFor="improvement">今後の改善策</CLabel>
          <textarea
            rows={3}
            name="improvement"
            ref={register}
            autoComplete="improvement"
            placeholder="今後の改善策"
            className={classNames("form-control", {
              "is-invalid": errors.improvement,
            })}
          />
        </CFormGroup>

        <CFormGroup className="mt-4">
          <LoadingButton type="submit" color="primary" loading={isLoading}>
            追加
          </LoadingButton>
        </CFormGroup>
      </CForm>
    </>
  );
};

export default PlantDetailAlertLogCreate;
