import React from 'react'
import { CSpinner } from '@coreui/react'

const LoadingSpinner: React.FC<any> = (props) => {
  return (
    <div {...props}>
      <div className="w-full h-full flex justify-content-center align-items-center">
        <CSpinner
          color="primary"
          className="w-4 h-4"
        />
      </div>
    </div>
  )
}

export default LoadingSpinner
