import React from 'react'

import { CDataTable } from '@coreui/react'

const DataTable: React.FC<CDataTable> = (props: any) => {
  return (
    <CDataTable
      {...props}
      striped={true}
    >
      {props.children}
    </CDataTable>
  )
}

export default DataTable
