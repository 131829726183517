import * as yup from 'yup'
import { CreateSpotDto, UpdateSpotDto } from "../../models/spot";

export const CreateSpotSchema = yup.object().shape<CreateSpotDto>({
  plant_id: yup.number().required(),
  name_jp: yup.string().required().max(256),
  name_en: yup.string().max(256),
})

export const UpdateSpotSchema = yup.object().shape<UpdateSpotDto>({
  plant_id: yup.number().required(),
  name_jp: yup.string().required().max(256),
  name_en: yup.string().max(256),
})